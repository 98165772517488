import PropTypes from 'prop-types';
import React from 'react';
import useAuth from 'src/contexts/auth/useAuth';
import CodeVerification from 'src/pages/auth/code-verification';
import Login from 'src/pages/auth/login';
import PassOTPChallenge from 'src/pages/PassOTPChallenge';

function AuthGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) return <Login />;

  if (!user.isUserPhoneVerified) {
    return <CodeVerification />;
  }

  if (user.isOtpVerificationRequired && user.isUserPhoneVerified) {
    return <PassOTPChallenge />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
