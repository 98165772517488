import React, { createContext, useContext, useMemo } from 'react';
import { GET_MY_PRE_ELIGIBILITY } from 'src/graphql/user/queries';
import { useSafeLazyQuery } from 'src/services/apollo-client/wrappers';

const UserContext = createContext();

const useUser = () => useContext(UserContext);

function UserProvider({ children }) {
  const [getMyPreEligibility, getMyPreEligibilityState] = useSafeLazyQuery(GET_MY_PRE_ELIGIBILITY);

  const memoizedContextValue = useMemo(
    () => ({
      getMyPreEligibility,
      getMyPreEligibilityState,
    }),
    [getMyPreEligibility, getMyPreEligibilityState]
  );

  return <UserContext.Provider value={memoizedContextValue}>{children}</UserContext.Provider>;
}

export { UserProvider, useUser };
