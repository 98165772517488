import { useTheme } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import React from 'react';
import config from 'src/config/index';

import mapStyles from './mapStyles.json';

const { apiKey } = config.google;

function calculateCurvedPath(pointA, pointB, curvature = 0.1, steps = 200) {
  const path = [];
  const latLngA = { lat: pointA.lat, lng: pointA.lng };
  const latLngB = { lat: pointB.lat, lng: pointB.lng };

  const midPointLat = (pointA.lat + pointB.lat) / 2;
  const midPointLng = (pointA.lng + pointB.lng) / 2;

  const dx = pointA.lng - pointB.lng;
  const dy = pointA.lat - pointB.lat;

  const distance = Math.sqrt(dx * dx + dy * dy);

  const offset = distance * curvature;

  const controlPoint = {
    lat: midPointLat + offset,
    lng: midPointLng,
  };

  for (let t = 0; t <= 1; t += 1 / steps) {
    const x =
      (1 - t) * (1 - t) * latLngA.lat + 2 * (1 - t) * t * controlPoint.lat + t * t * latLngB.lat;
    const y =
      (1 - t) * (1 - t) * latLngA.lng + 2 * (1 - t) * t * controlPoint.lng + t * t * latLngB.lng;
    path.push({ lat: x, lng: y });
  }

  return path;
}

function GoogleMap({ center, decoration, endAddress, startAddress }) {
  const theme = useTheme();
  const handleApiLoaded = ({ map, maps }) => {
    map.setOptions({ styles: mapStyles });

    if (decoration) return;

    const bounds = new maps.LatLngBounds();
    bounds.extend(new maps.LatLng(startAddress.lat, startAddress.lng));
    bounds.extend(new maps.LatLng(endAddress.lat, endAddress.lng));

    map.fitBounds(bounds);

    const curvedPath = calculateCurvedPath(startAddress, endAddress, 0.15);

    const polyline = new maps.Polyline({
      geodesic: true,
      path: curvedPath,
      strokeColor: theme.palette.primary.main,
      strokeOpacity: 0.5,
      strokeWeight: 2,
    });

    polyline.setMap(map);

    const circleSymbol = {
      fillColor: theme.palette.primary.main,
      fillOpacity: 0.5,
      path: maps.SymbolPath.CIRCLE,
      scale: 4,
      strokeColor: theme.palette.primary.main,
      strokeOpacity: 0.5,
      strokeWeight: 3,
    };

    // eslint-disable-next-line no-new
    new maps.Marker({
      icon: circleSymbol,
      map,
      position: startAddress,
    });
    // eslint-disable-next-line no-new
    new maps.Marker({
      icon: circleSymbol,
      map,
      position: endAddress,
    });
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: apiKey }}
      center={center}
      defaultCenter={{
        lat: startAddress.lat,
        lng: startAddress.lng,
      }}
      defaultZoom={12}
      onGoogleApiLoaded={handleApiLoaded}
      options={{
        disableDoubleClickZoom: true,
        draggable: false,
        fullscreenControl: false,
        gestureHandling: 'none',
        scrollwheel: false,
        zoomControl: false,
      }}
      style={{ backgroundColor: theme.palette.background.default }}
      yesIWantToUseGoogleMapApiInternals
    />
  );
}

export default GoogleMap;
