// assets
import { Box, List, Typography } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import useResponsive from 'src/hooks/useResponsive';

import { ThemeMode } from '../../../../../config';
import { dispatch } from '../../../../../store';
import { activeID } from '../../../../../store/reducers/menu';
import NavCollapse from './NavCollapse';
// project import
import NavItem from './NavItem';

function NavGroup({
  item,
  lastItem,
  lastItemId,
  remItems,
  selectedItems,
  selectedLevel,
  setSelectedItems,
  setSelectedLevel,
}) {
  const theme = useTheme();
  const { pathname } = useLocation();

  const isMobile = useResponsive('down', 'md');

  const [currentItem, setCurrentItem] = useState(item);

  useEffect(() => {
    if (lastItem) {
      if (item.id === lastItemId) {
        const localItem = { ...item };
        const elements = remItems.map((ele) => ele.elements);
        localItem.children = elements.flat(1);
        setCurrentItem(localItem);
      } else {
        setCurrentItem(item);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, lastItem, isMobile]);

  const checkOpenForParent = useCallback(
    (child, id) => {
      child.forEach((ele) => {
        if (ele.children?.length) {
          checkOpenForParent(ele.children, currentItem.id);
        }
        if (ele.url === pathname) {
          dispatch(activeID(id));
        }
      });
    },
    [currentItem.id, pathname]
  );

  const checkSelectedOnload = useCallback(
    (data) => {
      const childrens = data.children ? data.children : [];
      dispatch(activeID(undefined));
      childrens.forEach((itemCheck) => {
        if (itemCheck.children?.length) {
          checkOpenForParent(itemCheck.children, currentItem.id);
        }
        if (itemCheck.url === pathname) {
          dispatch(activeID(currentItem.id));
        }
      });
    },
    [checkOpenForParent, currentItem.id, pathname]
  );

  useEffect(() => {
    checkSelectedOnload(currentItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, currentItem]);

  // eslint-disable-next-line array-callback-return, consistent-return
  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case 'collapse':
        return (
          <NavCollapse
            key={menuItem.id}
            level={1}
            menu={menuItem}
            parentId={currentItem.id}
            selectedItems={selectedItems}
            selectedLevel={selectedLevel}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
          />
        );
      case 'item':
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
    }
  });

  // menu list collapse & items
  // eslint-disable-next-line no-shadow

  return (
    <List
      subheader={
        item.title && (
          <Box sx={{ mb: 1.5, pl: 3 }}>
            <Typography
              color={theme.palette.mode === ThemeMode.DARK ? 'textSecondary' : 'text.secondary'}
              variant="subtitle2"
            >
              {item.title}
            </Typography>
            {item.caption && (
              <Typography color="secondary" variant="caption">
                {item.caption}
              </Typography>
            )}
          </Box>
        )
      }
      sx={{ mt: item.title ? 1.5 : 0, py: 0, zIndex: 0 }}
    >
      {navCollapse}
    </List>
  );
}

NavGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  lastItem: PropTypes.number,
  lastItemId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  remItems: PropTypes.array,
  selectedItems: PropTypes.string,
  selectedLevel: PropTypes.number,
  setSelectedItems: PropTypes.func,
  setSelectedLevel: PropTypes.func,
};

export default NavGroup;
