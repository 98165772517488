import getSafeArray from '../utils/getSafeArray';

const USER_ROLES = {
  ADMIN: 'ADMIN',
  BASIC: 'BASIC',
  PARTNER: 'PARTNER',
  SUPER_ADMIN: 'SUPER_ADMIN',
  TESTER: 'TESTER',
};

const roleHierarchy = {
  [USER_ROLES.BASIC]: [USER_ROLES.BASIC, USER_ROLES.TESTER],
  [USER_ROLES.SUPER_ADMIN]: [USER_ROLES.SUPER_ADMIN],
  [USER_ROLES.ADMIN]: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN],
  [USER_ROLES.TESTER]: [USER_ROLES.TESTER],
  [USER_ROLES.PARTNER]: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.PARTNER],
};

export const isGranted = (userRole, roles, excludeHierarchy = false) => {
  const safeRoles = getSafeArray(roles);
  if (excludeHierarchy) return safeRoles.includes(userRole);

  return safeRoles.some((requiredRole) => roleHierarchy[requiredRole].includes(userRole));
};

export default USER_ROLES;
