import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

export const SUB_TYPES = {
  PASSENGER_CONFIRMED_DEPOSIT: 'PASSENGER_CONFIRMED_DEPOSIT',
  PASSENGER_CONFIRMED_TAKING_CHARGE: 'PASSENGER_CONFIRMED_TAKING_CHARGE',
  PASSENGER_DECLINED_TAKING_CHARGE: 'PASSENGER_DECLINED_TAKING_CHARGE',
};

function SnackbarsHandler({ isConductor, type }) {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isConductor) {
      switch (type) {
        case SUB_TYPES.PASSENGER_CONFIRMED_TAKING_CHARGE: {
          enqueueSnackbar('Un passager à confirmé la prise en charge!', { variant: 'success' });
          break;
        }
        case SUB_TYPES.PASSENGER_DECLINED_TAKING_CHARGE: {
          enqueueSnackbar('Un passager à décliné la prise en charge!', { variant: 'error' });
          break;
        }
        case SUB_TYPES.PASSENGER_CONFIRMED_DEPOSIT: {
          enqueueSnackbar('Un passager à confirmé son dépôt!', { variant: 'success' });
          break;
        }
        default:
          break;
      }
    }
  }, [type, isConductor, enqueueSnackbar]);

  return null;
}

export default SnackbarsHandler;
