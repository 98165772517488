import {
  Chip,
  FormControl,
  InputLabel,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

function RHFSelect({ label, name, options, renderOption, ...other }) {
  const { control } = useFormContext();

  const finalRenderOption = renderOption || (({ label: optionLabel }) => optionLabel);
  const finalRenderOptionSecondary = renderOption || (({ secondary }) => secondary);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, ...field }, fieldState: { error } }) => (
        <FormControl variant="standard" {...other}>
          <InputLabel>{label}</InputLabel>
          <TextField
            error={!!error}
            helperText={error?.message}
            select
            SelectProps={{
              disablePortal: true,
              native: false,
            }}
            size="small"
            {...field}
            value={value}
          >
            {options.map(
              ({
                badge,
                disabled,
                label: optionLabel,
                secondary,
                value: selectValue,
                ...others
              }) => (
                <ListItemButton key={selectValue} disabled={disabled} value={selectValue}>
                  <ListItemText
                    primary={
                      <Stack
                        alignItems="center"
                        direction="row"
                        // justifyContent="space-between"
                        spacing={0.25}
                      >
                        <Typography>
                          {finalRenderOption({
                            label: optionLabel,
                            value: selectValue,
                            ...others,
                          })}
                        </Typography>
                        {badge && (
                          <Chip
                            color="success"
                            label={badge}
                            size="small"
                            sx={{ transform: 'scale(0.7)' }}
                            variant="combined"
                          />
                        )}
                      </Stack>
                    }
                    secondary={finalRenderOptionSecondary({ secondary })}
                  />
                </ListItemButton>
              )
            )}
          </TextField>
        </FormControl>
      )}
    />
  );
}

RHFSelect.defaultProps = {
  native: true,
  options: [],
};

export default RHFSelect;
