import config from 'src/config/index';
import USER_ROLES, { isGranted } from 'src/constants/userRoles';
import useAuth from 'src/contexts/auth/useAuth';

const { showBonuses } = config;

function WithBonuses({ children, returning }) {
  const { user } = useAuth();

  if (isGranted(user.role, USER_ROLES.TESTER)) return null;

  if (!showBonuses) return returning || null;

  return children;
}

const useShowBonuses = () => {
  const { user } = useAuth();

  if (isGranted(user.role, USER_ROLES.TESTER)) return false;

  return showBonuses;
};

export { useShowBonuses };
export default WithBonuses;
