// ==============================|| OVERRIDES - AUTOCOMPLETE ||============================== //

export default function Autocomplete() {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        clearIndicator: {
          height: 'auto',
          width: 'auto',
        },
        paper: {
          marginBottom: 8,
          marginTop: 8,
        },
        popupIndicator: {
          height: 'auto',
          width: 'auto',
        },
        root: {
          '& .MuiOutlinedInput-root': {
            padding: '3px 9px',
          },
        },
      },
    },
  };
}
