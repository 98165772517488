import { Box, Container, Toolbar } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Outlet } from 'react-router';
import NotificationsHandler from 'src/components/NotificationsHandler';
import PullToRefresh from 'src/components/pull-to-refresh';
import WithRoles from 'src/components/WithRoles';
import { DRAWER_WIDTH, MAIN_ID } from 'src/config';
import USER_ROLES from 'src/constants/userRoles';
import useConfig from 'src/hooks/useConfig';
import useResponsive from 'src/hooks/useResponsive';
import WelcomeOnboarding from 'src/sections/onboardings/welcome-onboarding/WelcomeOnboarding';
import { dispatch, useSelector } from 'src/store';
import { openDrawer } from 'src/store/reducers/menu';

import Drawer from './Drawer';
import Header from './Header';

function MainLayout() {
  const isMobile = useResponsive('down', 'md');
  const layoutRef = useRef();

  const { container } = useConfig();
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  useEffect(() => {
    dispatch(openDrawer(!isMobile));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <Box ref={layoutRef} sx={{ display: 'flex', overflowX: 'hidden' }}>
      <PullToRefresh target={layoutRef?.current} />
      <Header />
      <Drawer />
      <Box
        component="main"
        id={MAIN_ID}
        sx={{
          alignItems: 'flex-start',
          flexGrow: 1,
          maxWidth: '100%',
          p: { sm: 3, xs: 2 },
          pb: { sm: 3, xs: 4 },
          ...(!isMobile &&
            drawerOpen && {
              marginLeft: `${DRAWER_WIDTH}px`,
              width: `calc(100% - ${DRAWER_WIDTH}px)`,
            }),
        }}
      >
        <Toolbar />
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            ...(container && { px: { md: 0, sm: 2, xs: 0 } }),
            display: 'flex',
            flexDirection: 'column',
            minHeight: 'calc(100vh - 126px)',
            position: 'relative',
          }}
        >
          <Outlet />
          <WithRoles accessibleRoles={USER_ROLES.BASIC}>
            <WelcomeOnboarding />
          </WithRoles>
        </Container>
      </Box>
      <NotificationsHandler />
    </Box>
  );
}

export default MainLayout;
