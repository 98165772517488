import React from 'react';
import SimpleBar from 'src/components/third-party/SimpleBar';
import UserKPIs from 'src/sections/auth/users/me/kpis/UserKPIs';

import CreateTripAction from './CreateTripAction';
import NavCard from './NavCard';
import Navigation from './Navigation';

function DrawerContent() {
  return (
    <SimpleBar
      sx={{
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <UserKPIs />
      <CreateTripAction />
      <Navigation />
      <NavCard />
    </SimpleBar>
  );
}

export default DrawerContent;
