import React from 'react';

import GenericDialog from './GenericDialog';
import MODAL_TYPES from './types';

function ConfirmDialog({
  confirmLabel,
  discardLabel,
  message,
  onConfirm,
  onDiscard,
  open,
  title,
  ...props
}) {
  return (
    <GenericDialog
      confirmLabel={confirmLabel}
      dialogType={MODAL_TYPES.CONFIRM}
      discardLabel={discardLabel}
      message={message}
      onConfirm={() => onConfirm(true)}
      onDiscard={() => onDiscard(false)}
      open={open}
      title={title}
      {...props}
    />
  );
}

export default ConfirmDialog;
