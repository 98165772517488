import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { decode } from 'jsonwebtoken';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from 'src/components/Loader';
import USER_ROLES, { isGranted } from 'src/constants/userRoles';
import {
  LOGIN,
  PASS_EMAIL_VERIFICATION,
  PASS_OTP_VERIFICATION,
  REGISTER,
  RESEND_EMAIL_VERIFICATION_CODE,
  RESEND_VERIFICATION_CODE,
} from 'src/graphql/auth/mutations';
import { CURRENT_USER, ME_AS_ADMIN } from 'src/graphql/auth/queries';
import { ATTACH_PUSH_TOKEN, CONTACT_US, DELETE_ME } from 'src/graphql/user/mutations';
import { GET_MY_PRE_ELIGIBILITY } from 'src/graphql/user/queries';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { APP_ROOT_PATH, PATH_USER } from 'src/routes/paths';
import cleanPhoneNumber from 'src/utils/cleanPhoneNumber';

import { useLogger } from '../LoggerContext';

const formatUser = (user) => {
  if (!user) return null;

  return {
    ...user,
    phone: cleanPhoneNumber(user?.phone),
  };
};

const computeCountEligibility = (rewardsInformation) => {
  const { maxTripsPeriod, remainingTripForLastPrime, rollEndsAt } = rewardsInformation;
  if (remainingTripForLastPrime < maxTripsPeriod) {
    if (remainingTripForLastPrime > 0 && moment().isBefore(moment(rollEndsAt))) return 1;
    return 0;
  }
  return 2;
};

const computePrimeEligibility = (preEligibilityResult, rewardsInformation) => {
  if (!preEligibilityResult)
    return {
      countEligibility: 0,
      long: {
        countEligibility: 0,
      },
      short: {
        countEligibility: 0,
      },
    };

  const isShortEligible = preEligibilityResult.isRpcPreCheckValidForShortDistance;
  // const isLongEligible = preEligibilityResult.isRpcPreCheckValidForLongDistance;

  const countShortEligibility = isShortEligible
    ? computeCountEligibility(rewardsInformation.short)
    : 0;
  // const countLongEligibility = isLongEligible
  //   ? computeCountEligibility(rewardsInformation.long)
  //   : 0;
  const countLongEligibility = 0;

  return {
    countEligibility: countShortEligibility + countLongEligibility,
    long: {
      countEligibility: countLongEligibility,
    },
    short: {
      countEligibility: countShortEligibility,
    },
  };
};

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const { setGlobalContext } = useLogger();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [currentUser, setUser] = useState(null);
  const [isAdminBaseRole, setIsAdminBaseRole] = useState(false);
  const location = useLocation();

  const [hasBeenActiveAtLeastOnce, setHasBeenActiveAtLeastOnce] = useLocalStorage(
    'hasBeenActiveAtLeastOnce',
    true
  );

  const [deleteMe, deleteMeState] = useMutation(DELETE_ME);
  const [contactUs, contactUsState] = useMutation(CONTACT_US);
  const [attachPushToken, attachPushTokenState] = useMutation(ATTACH_PUSH_TOKEN, {
    displaySnackbar: false,
    refetchQueries: ['me'],
  });
  //
  const [passEmailVerification, passEmailVerificationState] = useMutation(PASS_EMAIL_VERIFICATION);
  const [resendEmailVerificationCode, resendEmailVerificationCodeState] = useMutation(
    RESEND_EMAIL_VERIFICATION_CODE
  );

  const [getMyPreEligibility, getMyPreEligibilityState] = useLazyQuery(GET_MY_PRE_ELIGIBILITY, {
    fetchPolicy: 'network-only',
  });

  const setSession = (token) => {
    window.asyncStorage.storeToken(token);
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  };

  const logout = useCallback(async () => {
    setIsAuthenticated(false);
    setSession(null);
    await apolloClient.clearStore();
  }, [apolloClient]);

  const [getUser, { data: basicUser, error: basicUserError, refetch: refetchBasicUser }] =
    useLazyQuery(CURRENT_USER, {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        setIsAuthenticated(true);
        setIsInitialized(true);
      },
      onError: () => {
        logout();
        setIsInitialized(true);
        setUser(null);
      },
      refetchWritePolicy: 'overwrite',
    });

  const [getAdminUser, { data: admin, error: adminError, refetch: refetchAdmin }] = useLazyQuery(
    ME_AS_ADMIN,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        setIsAuthenticated(true);
        setIsInitialized(true);
      },
      onError: () => {
        logout();
        setIsInitialized(true);
        setUser(null);
      },
      refetchWritePolicy: 'overwrite',
    }
  );

  const getAuthenticatedCurrentUser = useCallback(
    async (token) => {
      const decodedToken = decode(token);
      const isAdminBaseRoleLocal = isGranted(decodedToken.role, USER_ROLES.PARTNER);
      setIsAdminBaseRole(isAdminBaseRoleLocal);

      if (isAdminBaseRoleLocal) return getAdminUser();

      return getUser();
    },
    [getAdminUser, getUser]
  );

  const getCurrentUser = useCallback(() => {
    if (isAdminBaseRole) return admin?.meAsAdmin;

    return basicUser?.me;
  }, [admin, basicUser, isAdminBaseRole]);

  const getCurrentUserError = useCallback(() => {
    if (isAdminBaseRole) return adminError;

    return basicUserError;
  }, [adminError, basicUserError, isAdminBaseRole]);

  const refetchUser = useCallback(async () => {
    if (isAdminBaseRole) return refetchAdmin();

    return refetchBasicUser();
  }, [isAdminBaseRole, refetchAdmin, refetchBasicUser]);

  const onLoginSuccess = useCallback(
    async (token, withRedirection) => {
      try {
        setSession(token);
        // Check token for role
        await getAuthenticatedCurrentUser(token);

        if (withRedirection) {
          const decodedToken = decode(token);

          if (isGranted(decodedToken.role, USER_ROLES.ADMIN)) return;

          if (
            !hasBeenActiveAtLeastOnce &&
            location.pathname.indexOf(`${APP_ROOT_PATH}trips`) <= -1
          ) {
            navigate(PATH_USER.welcome, { replace: true });
          }
        }
      } catch {
        // Nothing to do
        setIsAuthenticated(false);
      }
    },
    [getAuthenticatedCurrentUser, hasBeenActiveAtLeastOnce, location.pathname, navigate]
  );

  const [register, registerState] = useMutation(REGISTER, {
    onCompleted: ({ register: result }) => {
      onLoginSuccess(result.token);
    },
  });

  const [login, loginState] = useMutation(LOGIN, {
    onCompleted: async ({ login: result }) => {
      onLoginSuccess(result.token);
    },
    onError: async () => {
      await logout();
      setIsInitialized(true);
    },
  });

  const [passOtpVerification, passOtpVerificationState] = useMutation(PASS_OTP_VERIFICATION, {
    onCompleted: ({ passOtpVerification: result }) => onLoginSuccess(result.token, true),
  });

  const [resendVerificationCode, resendVerificationCodeState] =
    useMutation(RESEND_VERIFICATION_CODE);

  useEffect(() => {
    const init = async () => {
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const paramsToken = urlParams.get('token');

        if (paramsToken) {
          // Store it into the current local storage for query usage
          window.localStorage.setItem('token', paramsToken);
        }

        const token = window.localStorage.getItem('token');
        if (token) {
          // Check token for role
          await getAuthenticatedCurrentUser(token);
        } else {
          logout();
          setIsAuthenticated(false);
          setIsInitialized(true);
        }
      } catch (err) {
        setIsAuthenticated(false);
        setIsInitialized(true);
      }
    };

    init();
  }, [getAuthenticatedCurrentUser, logout]);

  useEffect(() => {
    if (getCurrentUserError()) {
      // We received data and error from API
      logout();
      enqueueSnackbar("Une erreur s'est produite. Essaie à nouveau", { variant: 'error' });
    }

    if (getCurrentUser() && !getCurrentUserError()) {
      setUser(getCurrentUser());
      setGlobalContext({ user: getCurrentUser() });
    }
  }, [setGlobalContext, logout, getCurrentUserError, getCurrentUser]);

  useEffect(() => {
    if (currentUser) {
      setHasBeenActiveAtLeastOnce(currentUser.hasBeenActiveAtLeastOnce);
    }
  }, [setHasBeenActiveAtLeastOnce, currentUser]);

  useEffect(() => {
    if (isAuthenticated && currentUser && !currentUser?.isOtpVerificationRequired) {
      getMyPreEligibility();
    }
  }, [isAuthenticated, getMyPreEligibility, currentUser]);

  const memoizedState = useMemo(
    () => ({
      attachPushTokenState,
      contactUs,
      contactUsState,
      deleteMeState,
      eligibility: computePrimeEligibility(
        getMyPreEligibilityState?.data?.getMyPreEligibility,
        currentUser?.initialRewardsInformations
      ),
      hasBeenActiveAtLeastOnce,
      isAuthenticated,
      isInitialized,
      loginState,
      passOtpVerificationState,
      registerState,
      resendVerificationCodeState,
      user: formatUser(currentUser),
    }),
    [
      attachPushTokenState,
      contactUs,
      contactUsState,
      deleteMeState,
      getMyPreEligibilityState?.data?.getMyPreEligibility,
      currentUser,
      hasBeenActiveAtLeastOnce,
      isAuthenticated,
      isInitialized,
      loginState,
      passOtpVerificationState,
      registerState,
      resendVerificationCodeState,
    ]
  );
  const memoizedMethods = useMemo(
    () => ({
      attachPushToken,
      deleteMe,
      login,
      logout,
      passEmailVerification,
      passEmailVerificationState,
      passOtpVerification,
      refetchUser,
      register,
      resendEmailVerificationCode,
      resendEmailVerificationCodeState,
      resendVerificationCode,
    }),
    [
      attachPushToken,
      deleteMe,
      login,
      logout,
      passEmailVerification,
      passEmailVerificationState,
      passOtpVerification,
      refetchUser,
      register,
      resendEmailVerificationCode,
      resendEmailVerificationCodeState,
      resendVerificationCode,
    ]
  );

  const memoizedContextValue = useMemo(
    () => ({
      ...memoizedState,
      ...memoizedMethods,
    }),
    [memoizedState, memoizedMethods]
  );

  if (isInitialized !== undefined && !isInitialized) {
    return <Loader />;
  }

  return <AuthContext.Provider value={memoizedContextValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthContext;
