import { gql } from '@apollo/client';

const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      token
    }
  }
`;

const REGISTER = gql`
  mutation register($input: RegisterInput!) {
    register(input: $input) {
      token
    }
  }
`;

const PASS_OTP_VERIFICATION = gql`
  mutation passOtpVerification($input: OtpInput!) {
    passOtpVerification(input: $input) {
      token
    }
  }
`;

const RESEND_VERIFICATION_CODE = gql`
  mutation resendVerificationCode {
    resendVerificationCode {
      token
    }
  }
`;

const PASS_EMAIL_VERIFICATION = gql`
  mutation passEmailVerification($input: OtpInput!) {
    passEmailVerification(input: $input)
  }
`;

const RESEND_EMAIL_VERIFICATION_CODE = gql`
  mutation resendEmailVerificationCode {
    resendEmailVerificationCode
  }
`;

export {
  LOGIN,
  REGISTER,
  PASS_OTP_VERIFICATION,
  PASS_EMAIL_VERIFICATION,
  RESEND_EMAIL_VERIFICATION_CODE,
  RESEND_VERIFICATION_CODE,
};
