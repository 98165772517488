import { FormControl, InputAdornment, InputLabel, TextField, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

function RHFPhoneFRTextField({ label, name, withoutPhoneValidation, ...other }) {
  const theme = useTheme();
  const { control, setValue, watch } = useFormContext();

  const values = watch();

  useEffect(() => {
    if (withoutPhoneValidation) return;

    if (values[name]) {
      const num = values[name];
      let formattedNum = num;
      let shouldUpdate = false;
      if (num.charAt(0) === '0') {
        formattedNum = formattedNum.replace(/^0+/, '');
        shouldUpdate = true;
      }

      if (num.startsWith('+33')) {
        formattedNum = formattedNum.replace(/^\+33/, '');
        shouldUpdate = true;
      }
      let index = 0;

      const finalFormattedNum = formattedNum
        .replace(/\s|\D/g, '')
        .replace(/\d/g, (match) => {
          index += 1;
          return index === 1 || index % 2 === 1 ? `${match} ` : match;
        })
        .trim();
      if (finalFormattedNum !== formattedNum) {
        shouldUpdate = true;
        formattedNum = finalFormattedNum;
      }

      if (shouldUpdate) setValue(name, `${formattedNum}`);
    }
  }, [values, name, setValue, withoutPhoneValidation]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth variant="standard">
          <InputLabel shrink>{label}</InputLabel>
          <TextField
            error={!!error}
            helperText={error?.message}
            InputProps={{
              ...(!withoutPhoneValidation && {
                startAdornment: <InputAdornment position="start">+33</InputAdornment>,
                sx: {
                  '& .MuiInputBase-inputAdornedStart': {
                    backgroundColor: theme.palette.background.default,
                    borderBottomRightRadius: theme.shape.borderRadius,
                    borderLeftColor: theme.palette.divider,
                    borderLeftStyle: 'solid',
                    borderLeftWidth: 1,
                    borderTopRightRadius: theme.shape.borderRadius,
                    paddingLeft: theme.spacing(1),
                  },
                  '&.MuiInputBase-adornedStart': {
                    backgroundColor: theme.palette.grey[200],
                  },
                },
              }),
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              inputMode: 'numeric',
            }}
            {...field}
            {...other}
          />
        </FormControl>
      )}
    />
  );
}

RHFPhoneFRTextField.defaultProps = {
  variant: 'outlined',
};

export default RHFPhoneFRTextField;
