import { gql } from '@apollo/client';

import { addressFragment, journeyFragment, tripFragment } from '../fragments';

const GET_MY_PENDING_JOURNEYS_AS_CONDUCTOR = gql`
  query getMyPendingJourneysAsConductor {
    getMyPendingJourneysAsConductor {
      ...Trip

      startAddress {
        ...Address
      }

      endAddress {
        ...Address
      }

      journeys {
        ...Journey
        passenger {
          id
          avatar
          firstname
        }
      }
    }
  }
  ${addressFragment}
  ${tripFragment}
  ${journeyFragment}
`;

const CONFIRM_TAKING_CHARGE = gql`
  mutation confirmTakingCharge($input: ConfirmTakingChargeInput!) {
    confirmTakingCharge(input: $input) {
      ...Journey
      passenger {
        id
        avatar
        firstname
      }
    }
  }
  ${journeyFragment}
`;

const DECLINE_TAKING_CHARGE = gql`
  mutation declineTakingCharge($input: DeclineTakingChargeInput!) {
    declineTakingCharge(input: $input) {
      ...Journey
      passenger {
        id
        avatar
        firstname
      }
    }
  }
  ${journeyFragment}
`;

const CONFIRM_DEPOSIT = gql`
  mutation confirmDeposit($input: ConfirmDepositInput!) {
    confirmDeposit(input: $input) {
      ...Journey
      passenger {
        id
        avatar
        firstname
      }
    }
  }
  ${journeyFragment}
`;

export {
  GET_MY_PENDING_JOURNEYS_AS_CONDUCTOR,
  CONFIRM_TAKING_CHARGE,
  DECLINE_TAKING_CHARGE,
  CONFIRM_DEPOSIT,
};
