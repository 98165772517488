import React, { lazy } from 'react';

import Loadable from '../../components/Loadable';
import MainLayout from '../../layout/MainLayout';
import AuthGuard from '../../utils/route-guard/AuthGuard';

const CreateTripPage = Loadable(lazy(() => import('../../pages/users/trips/CreateTrip')));
const TripPage = Loadable(lazy(() => import('../../pages/users/trips/Trip')));
const ConversationsTripPage = Loadable(
  lazy(() => import('../../pages/users/trips/ConversationsTrip')),
  false
);
const TripHistoricPage = Loadable(lazy(() => import('../../pages/users/trips/TripHistoric')));
const SearchResultsPage = Loadable(lazy(() => import('../../pages/SearchResults')));

const TripsRoutes = {
  children: [
    {
      element: <CreateTripPage />,
      path: 'trips/create',
    },
    {
      element: <TripHistoricPage />,
      path: 'trips/historic',
    },
    {
      children: [
        {
          element: <TripPage />,
          path: '',
        },
        {
          children: [
            {
              element: <ConversationsTripPage />,
              path: '',
            },
            {
              element: <ConversationsTripPage />,

              path: ':conversationId',
            },
          ],
          path: 'conversations',
        },
      ],
      path: 'trips/:id',
    },
    {
      element: <SearchResultsPage />,
      path: 'search/results',
    },
  ],
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  path: '',
};

export default TripsRoutes;
