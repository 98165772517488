import { gql } from '@apollo/client';

const finalizeCardRegistrationDataFragment = gql`
  fragment FinalizeCardRegistrationData on FinalizeCardRegistrationData {
    Id
    Tag
    CreationDate
    UserId
    AccessKey
    PreregistrationData
    RegistrationData
    CardId
    CardType
    CardRegistrationURL
    ResultCode
    ResultMessage
    Currency
    Status
  }
`;

export default finalizeCardRegistrationDataFragment;
