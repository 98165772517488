import PropTypes from 'prop-types';
import React, { createContext, useCallback, useMemo } from 'react';

// project import
import config from '../config';
import useLocalStorage from '../hooks/useLocalStorage';

// initial state
const initialState = {
  ...config,
  onChangeContainer: () => {},
  onChangeDirection: () => {},
  onChangeFontFamily: () => {},
  onChangeLocalization: () => {},
  onChangeMenuOrientation: () => {},
  onChangeMiniDrawer: () => {},
  onChangeMode: () => {},
  onChangePresetColor: () => {},
};

const ConfigContext = createContext(initialState);

function ConfigProvider({ children }) {
  // eslint-disable-next-line no-shadow
  const [config, setConfig] = useLocalStorage('config', initialState);

  const onChangeContainer = useCallback(() => {
    setConfig({
      ...config,
      container: !config.container,
    });
  }, [config, setConfig]);

  const onChangeLocalization = useCallback(
    (lang) => {
      setConfig({
        ...config,
        i18n: lang,
      });
    },
    [config, setConfig]
  );

  const onChangeMode = useCallback(
    (mode) => {
      setConfig({
        ...config,
        mode,
      });
    },
    [config, setConfig]
  );

  const onChangePresetColor = useCallback(
    (theme) => {
      setConfig({
        ...config,
        presetColor: theme,
      });
    },
    [config, setConfig]
  );

  const onChangeDirection = useCallback(
    (direction) => {
      setConfig({
        ...config,
        themeDirection: direction,
      });
    },
    [config, setConfig]
  );

  const onChangeMiniDrawer = useCallback(
    (miniDrawer) => {
      setConfig({
        ...config,
        miniDrawer,
      });
    },
    [config, setConfig]
  );

  const onChangeMenuOrientation = useCallback(
    (layout) => {
      setConfig({
        ...config,
        menuOrientation: layout,
      });
    },
    [config, setConfig]
  );

  const onChangeFontFamily = useCallback(
    (fontFamily) => {
      setConfig({
        ...config,
        fontFamily,
      });
    },
    [config, setConfig]
  );

  const methods = useMemo(
    () => ({
      onChangeContainer,
      onChangeDirection,
      onChangeFontFamily,
      onChangeLocalization,
      onChangeMenuOrientation,
      onChangeMiniDrawer,
      onChangeMode,
      onChangePresetColor,
    }),
    [
      onChangeContainer,
      onChangeDirection,
      onChangeFontFamily,
      onChangeLocalization,
      onChangeMenuOrientation,
      onChangeMiniDrawer,
      onChangeMode,
      onChangePresetColor,
    ]
  );

  const memoizedContextValue = useMemo(
    () => ({
      ...config,
      ...methods,
    }),
    [config, methods]
  );

  return <ConfigContext.Provider value={memoizedContextValue}>{children}</ConfigContext.Provider>;
}

ConfigProvider.propTypes = {
  children: PropTypes.node,
};

export { ConfigProvider, ConfigContext };
