import React, { lazy } from 'react';
import { Navigate } from 'react-router';
import LandingLayout from 'src/layout/LandingLayout';

import Loadable from '../../components/Loadable';
import { PATH_LANDING } from '../paths';

const RootPage = Loadable(lazy(() => import('../../pages/landing/Root')));
const SponsoringPage = Loadable(lazy(() => import('../../pages/landing/Sponsoring')));
const ContactPage = Loadable(lazy(() => import('../../pages/landing/Contact')));
const AboutPage = Loadable(lazy(() => import('../../pages/landing/About')));
const BonusesPage = Loadable(lazy(() => import('../../pages/landing/Bonuses')));
const PrivacyPolicyPage = Loadable(lazy(() => import('../../pages/landing/PrivacyPolicy')));
const CGUPage = Loadable(lazy(() => import('../../pages/landing/CGU')));
const DownloadAppPage = Loadable(lazy(() => import('../../pages/landing/DownloadApp')));

const BlogRootPage = Loadable(lazy(() => import('../../pages/landing/blog/BlogRoot')));
const BlogArticlePage = Loadable(lazy(() => import('../../pages/landing/blog/BlogArticle')));

const LandingRoutes = {
  children: [
    {
      element: <RootPage />,
      path: '',
    },
    {
      element: <SponsoringPage />,
      path: 'parrainage',
    },
    {
      element: <ContactPage />,
      path: 'nous-contacter',
    },
    {
      element: <AboutPage />,
      path: 'a-propos',
    },
    {
      element: <PrivacyPolicyPage />,
      path: 'politique-de-confidentialite',
    },
    {
      element: <CGUPage />,
      path: 'conditions-generales-d-utilisation',
    },
    {
      element: <BonusesPage />,
      path: 'la-prime-coup-de-pouce',
    },
    {
      element: <DownloadAppPage />,
      path: 'telecharger-coccinelle',
    },
    {
      children: [
        {
          element: <BlogRootPage />,
          path: '',
        },
        {
          element: <BlogArticlePage />,
          path: ':slug',
        },
      ],
      path: 'blog',
    },
    {
      element: <Navigate replace to={PATH_LANDING.root} />,
      path: '*',
    },
  ],
  element: <LandingLayout />,
  path: '',
};

const LandingPageRoutes = [LandingRoutes];

export default LandingPageRoutes;
