import { AddOutlined } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import USER_ROLES, { isGranted } from 'src/constants/userRoles';
import useAuth from 'src/contexts/auth/useAuth';
import useResponsive from 'src/hooks/useResponsive';
import { PATH_USER } from 'src/routes/paths';
import { openDrawer } from 'src/store/reducers/menu';

function CreateTripAction() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'md');

  const handleClick = () => {
    if (isMobile) dispatch(openDrawer(false));
  };

  if (!isGranted(user.role, USER_ROLES.BASIC)) return null;

  return (
    <Stack pt={2} px={2}>
      <Button
        fullWidth
        LinkComponent={Link}
        onClick={handleClick}
        size="medium"
        startIcon={<AddOutlined />}
        to={PATH_USER.trips.create}
        variant="outlined"
      >
        Proposer un trajet
      </Button>
    </Stack>
  );
}

export default CreateTripAction;
