import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';

function UnderlinedText({ animated, children, color, ...props }) {
  let finalColor = color;

  if (color.indexOf('.') <= -1) {
    finalColor = `${color}.main`;
  }

  if (typeof children === 'string')
    return (
      <Typography
        color={finalColor}
        component="span"
        fontWeight="bold"
        label={children}
        sx={{
          '&::after': {
            backgroundColor: finalColor,
            content: `''`,
            height: 'calc(100% + 2px)',
            left: -2,
            padding: '2px 2px',
            position: 'absolute',
            top: 0,
            transform: 'rotate(-2deg)',
            width: 'calc(100% + 4px)',
            zIndex: -1,
          },
          color: 'secondary.contrastText',
          position: 'relative',
          transition: 'all 0.25s ease',
          zIndex: 1,
        }}
        variant
        {...props}
      >
        <span>{children}</span>
      </Typography>
    );

  return (
    <Typography sx={{ mx: 0.5, position: 'relative' }} variant {...props}>
      <Box
        {...(animated && {
          animate: { width: 'calc(100% + 8px)' },
          component: motion.div,
          initial: { width: '0%' },
          transition: { duration: 0.25, ease: 'easeInOut' },
        })}
        sx={{
          backgroundColor: finalColor,
          bottom: -4,
          height: 'calc(100% + 8px)',
          left: -4,
          position: 'absolute',
          transform: 'rotate(-2deg)',
          width: 'calc(100% + 8px)',
          zIndex: 0,
        }}
      />
      <Typography
        component="span"
        fontWeight="bold"
        sx={{ color: 'secondary.contrastText', position: 'relative', zIndex: 1 }}
        variant
      >
        {children}
      </Typography>
    </Typography>
  );
}

UnderlinedText.defaultProps = {
  color: 'primary',
};

export default UnderlinedText;
