import PropTypes from 'prop-types';
import React, { createContext, useCallback, useMemo, useState } from 'react';
import { AlertDialog, ConfirmDialog } from 'src/components/dialogs';
import PromptDialog from 'src/components/dialogs/PromptDialog';
import MODAL_TYPES from 'src/components/dialogs/types';

const DIALOGS = {
  [MODAL_TYPES.ALERT]: AlertDialog,
  [MODAL_TYPES.CONFIRM]: ConfirmDialog,
  [MODAL_TYPES.PROMPT]: PromptDialog,
};

const DialogsContext = createContext();

function DialogsProvider({ children }) {
  const [currentDialog, setCurrentDialog] = useState(null);

  const triggerDialog = ({ confirmLabel, discardLabel, message, title, type, ...others }) =>
    new Promise((resolve) => {
      setCurrentDialog({
        confirmLabel,
        discardLabel,
        message,
        resolve,
        title,
        type,
        ...others,
      });
    });

  const handleDialogResolve = (result) => {
    if (currentDialog && currentDialog.resolve) {
      currentDialog.resolve(result);
    }
    setCurrentDialog(null);
  };

  const alert = useCallback(
    ({ confirmLabel, message = '', title = '', ...props } = {}) =>
      triggerDialog({ confirmLabel, message, props, title, type: MODAL_TYPES.ALERT }),
    []
  );

  const confirm = useCallback(
    ({ confirmLabel, discardLabel, message = '', title = '', ...props } = {}) =>
      triggerDialog({
        confirmLabel,
        discardLabel,
        message,
        props,
        title,
        type: MODAL_TYPES.CONFIRM,
      }),
    []
  );

  const prompt = useCallback(
    ({
      confirmLabel,
      defaultPromptValue = '',
      discardLabel,
      message = '',
      title = '',
      ...props
    } = {}) =>
      triggerDialog({
        confirmLabel,
        defaultPromptValue,
        discardLabel,
        message,
        props,
        title,
        type: MODAL_TYPES.PROMPT,
      }),
    []
  );

  const DialogComponent = currentDialog ? DIALOGS[currentDialog.type] : null;

  const memoizedContextValue = useMemo(
    () => ({
      alert,
      confirm,
      prompt,
    }),
    [alert, confirm, prompt]
  );

  return (
    <DialogsContext.Provider value={memoizedContextValue}>
      {children}
      {DialogComponent && (
        <DialogComponent
          confirmLabel={currentDialog.confirmLabel}
          dialogType={currentDialog.type}
          discardLabel={currentDialog.discardLabel}
          message={currentDialog.message}
          onConfirm={handleDialogResolve}
          onDiscard={handleDialogResolve}
          open
          title={currentDialog.title}
          {...(currentDialog?.props && currentDialog.props)}
          {...(currentDialog.type === MODAL_TYPES.PROMPT && {
            defaultPromptValue: currentDialog.defaultPromptValue,
          })}
        />
      )}
    </DialogsContext.Provider>
  );
}

DialogsProvider.propTypes = {
  children: PropTypes.node,
};

export { DialogsContext, DialogsProvider, MODAL_TYPES };
