// material-ui
import { alpha } from '@mui/material/styles';

// project import
import getColors from '../../utils/getColors';
import getShadow from '../../utils/getShadow';

// ==============================|| BUTTON - COLORS ||============================== //

function getColorStyle({ color, theme, variant }) {
  const colors = getColors(theme, color);
  const { contrastText, dark, lighter, main } = colors;

  const buttonShadow = `${color}Button`;
  const shadows = getShadow(theme, buttonShadow);

  const commonShadow = {
    '&::after': {
      boxShadow: `0 0 5px 5px ${alpha(main, 0.9)}`,
    },
    '&:active::after': {
      boxShadow: `0 0 0 0 ${alpha(main, 0.9)}`,
    },
    '&:focus-visible': {
      outline: `2px solid ${dark}`,
      outlineOffset: 2,
    },
  };

  switch (variant) {
    case 'contained':
      return {
        '&:hover': {
          backgroundColor: dark,
        },
        // border: `1px solid ${theme.palette.common.black}`,
        ...commonShadow,
      };
    case 'shadow':
      return {
        '&:hover': {
          backgroundColor: dark,
          boxShadow: 'none',
        },
        backgroundColor: main,
        boxShadow: shadows,
        color: contrastText,
        ...commonShadow,
      };
    case 'outlined':
      return {
        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: dark,
          color: dark,
        },
        borderColor: main,
        ...commonShadow,
      };
    case 'dashed':
      return {
        '&:hover': {
          borderColor: dark,
          color: dark,
        },
        backgroundColor: lighter,
        borderColor: main,
        color: main,
        ...commonShadow,
      };
    case 'text':
    default:
      return {
        '&:hover': {
          backgroundColor: lighter,
          color: dark,
        },
        ...commonShadow,
      };
  }
}

// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button(theme) {
  const primaryDashed = getColorStyle({ color: 'primary', theme, variant: 'dashed' });
  const primaryShadow = getColorStyle({ color: 'primary', theme, variant: 'shadow' });

  const disabledStyle = {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
    },
  };
  const iconStyle = {
    '&>*:nth-of-type(1)': {
      fontSize: 'inherit',
    },
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: 'large',
      },
      styleOverrides: {
        contained: {
          ...disabledStyle,
        },
        containedError: getColorStyle({ color: 'error', theme, variant: 'contained' }),
        containedInfo: getColorStyle({ color: 'info', theme, variant: 'contained' }),
        containedPrimary: getColorStyle({ color: 'primary', theme, variant: 'contained' }),
        containedSecondary: getColorStyle({ color: 'secondary', theme, variant: 'contained' }),
        containedSuccess: getColorStyle({ color: 'success', theme, variant: 'contained' }),
        containedWarning: getColorStyle({ color: 'warning', theme, variant: 'contained' }),
        dashed: {
          border: '1px dashed',
          ...primaryDashed,
          '&.Mui-disabled': {
            backgroundColor: `${theme.palette.grey[200]} !important`,
            borderColor: `${theme.palette.grey[400]} !important`,
            color: `${theme.palette.grey[300]} !important`,
          },
          '&.MuiButton-dashedError': getColorStyle({ color: 'error', theme, variant: 'dashed' }),
          '&.MuiButton-dashedInfo': getColorStyle({ color: 'info', theme, variant: 'dashed' }),
          '&.MuiButton-dashedPrimary': getColorStyle({
            color: 'primary',
            theme,
            variant: 'dashed',
          }),
          '&.MuiButton-dashedSecondary': getColorStyle({
            color: 'secondary',
            theme,
            variant: 'dashed',
          }),
          '&.MuiButton-dashedSuccess': getColorStyle({
            color: 'success',
            theme,
            variant: 'dashed',
          }),
          '&.MuiButton-dashedWarning': getColorStyle({
            color: 'warning',
            theme,
            variant: 'dashed',
          }),
        },
        endIcon: {
          ...iconStyle,
        },
        outlined: {
          ...disabledStyle,
        },
        outlinedError: getColorStyle({ color: 'error', theme, variant: 'outlined' }),
        outlinedInfo: getColorStyle({ color: 'info', theme, variant: 'outlined' }),
        outlinedPrimary: getColorStyle({ color: 'primary', theme, variant: 'outlined' }),
        outlinedSecondary: getColorStyle({ color: 'secondary', theme, variant: 'outlined' }),
        outlinedSuccess: getColorStyle({ color: 'success', theme, variant: 'outlined' }),
        outlinedWarning: getColorStyle({ color: 'warning', theme, variant: 'outlined' }),
        root: {
          '&::after': {
            borderRadius: `${theme.shape.borderRadius}px`,
            content: '""',
            display: 'block',
            height: '100%',
            left: 0,
            opacity: 0,
            position: 'absolute',
            top: 0,
            transition: 'all 0.5s',
            width: '100%',
          },
          '&:active::after': {
            borderRadius: `${theme.shape.borderRadius}px`,
            left: 0,
            opacity: 1,
            position: 'absolute',
            top: 0,
            transition: '0s',
          },
          fontWeight: 400,
          textTransform: 'none',
        },
        shadow: {
          ...primaryShadow,
          '&.Mui-disabled': {
            backgroundColor: `${theme.palette.grey[200]} !important`,
            borderColor: `${theme.palette.grey[400]} !important`,
            color: `${theme.palette.grey[300]} !important`,
          },
          '&.MuiButton-shadowError': getColorStyle({ color: 'error', theme, variant: 'shadow' }),
          '&.MuiButton-shadowInfo': getColorStyle({ color: 'info', theme, variant: 'shadow' }),
          '&.MuiButton-shadowPrimary': getColorStyle({
            color: 'primary',
            theme,
            variant: 'shadow',
          }),
          '&.MuiButton-shadowSecondary': getColorStyle({
            color: 'secondary',
            theme,
            variant: 'shadow',
          }),
          '&.MuiButton-shadowSuccess': getColorStyle({
            color: 'success',
            theme,
            variant: 'shadow',
          }),
          '&.MuiButton-shadowWarning': getColorStyle({
            color: 'warning',
            theme,
            variant: 'shadow',
          }),
        },
        sizeExtraSmall: {
          fontSize: '0.625rem',
          minWidth: 56,
          padding: '2px 8px',
        },
        startIcon: {
          ...iconStyle,
        },
        text: {
          '&:hover': {
            boxShadow: 'none',
          },
          boxShadow: 'none',
        },
        textError: getColorStyle({ color: 'error', theme, variant: 'text' }),
        textInfo: getColorStyle({ color: 'info', theme, variant: 'text' }),
        textPrimary: getColorStyle({ color: 'primary', theme, variant: 'text' }),
        textSecondary: getColorStyle({ color: 'secondary', theme, variant: 'text' }),
        textSuccess: getColorStyle({ color: 'success', theme, variant: 'text' }),
        textWarning: getColorStyle({ color: 'warning', theme, variant: 'text' }),
      },
    },
  };
}
