// ==============================|| OVERRIDES - TABLE PAGINATION ||============================== //

export default function TablePagination() {
  return {
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          fontSize: '0.875rem',
        },
        selectLabel: {
          fontSize: '0.875rem',
        },
      },
    },
  };
}
