import { InputBase } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

function RHFTextField({ name, required, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <InputBase
          error={!!error}
          fullWidth
          helperText={error?.message}
          onWheel={(e) => e.preventDefault()}
          required={required}
          variant="outlined"
          {...field}
          {...other}
        />
      )}
    />
  );
}

export default RHFTextField;
