import { Stack, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';

import FakePhone from './FakePhone';

function OnboardingContent({ children, content, imageSrc, setNextDisabled, setOnClick, videoSrc }) {
  const contentRef = useRef();

  useEffect(() => {
    setNextDisabled(false);
  }, [setNextDisabled]);

  return (
    <Stack ref={contentRef} alignItems="center" flexGrow={1} justifyContent="center" spacing={1}>
      {(videoSrc || imageSrc) && (
        <Stack alignItems="center" flexGrow={1} justifyContent="center">
          <FakePhone imageSrc={imageSrc} videoSrc={videoSrc} />
        </Stack>
      )}
      {children &&
        (typeof children === 'function' ? children({ setNextDisabled, setOnClick }) : children)}
      {content && (
        <Stack alignItems="center" pt={2} px={3}>
          <Typography align="center" component="div" variant="caption">
            {content}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

export default OnboardingContent;
