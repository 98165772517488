import { Stack, Typography } from '@mui/material';
import React from 'react';
import HelpLink from 'src/components/HelpLink';
import USER_ROLES, { isGranted } from 'src/constants/userRoles';
import useAuth from 'src/contexts/auth/useAuth';

import AnimateButton from '../../../../components/@extended/AnimateButton';
import MainCard from '../../../../components/MainCard';

function NavCard() {
  const { user } = useAuth();

  if (!isGranted(user.role, USER_ROLES.BASIC)) return null;

  return (
    <MainCard sx={{ bgcolor: 'grey.50', m: 3 }}>
      <Stack alignItems="center" spacing={2.5}>
        <Stack alignItems="center">
          <Typography variant="h5">Besoin d&apos;aide?</Typography>
          <Typography color="secondary" variant="h6">
            Nos équipes sont là
          </Typography>
        </Stack>
        <AnimateButton>
          <HelpLink isButton label="Contacte-nous" />
        </AnimateButton>
      </Stack>
    </MainCard>
  );
}

export default NavCard;
