const expectedErrors = {
  DRIVING_LICENSE_REQUIRED: {
    code: 'DRIVING_LICENSE_REQUIRED',
    message: 'Un permis de conduire est nécessaire pour créer un trajet',
  },
  TRIP_ALREADY_CREATED_WITH_SAME_START_DATE_TIME: {
    code: 'TRIP_ALREADY_CREATED_WITH_SAME_START_DATE_TIME',
    message: 'Tu as déjà un trajet sur ce créneau',
  },
  TRIP_ALREADY_ENDED: {
    code: 'TRIP_ALREADY_ENDED',
    message: 'Ce trajet a déjà pris fin',
  },
  TRIP_ALREADY_FINISHED: {
    code: 'TRIP_ALREADY_FINISHED',
    message: 'Ce trajet a déjà pris fin',
  },
  TRIP_ALREADY_STARTED: {
    code: 'TRIP_ALREADY_STARTED',
    message: 'Ce trajet a déjà démarré',
  },
  TRIP_NOT_STARTED: {
    code: 'TRIP_NOT_STARTED',
    message: "Le trajet n'a pas encore démarré",
  },
  TRIP_OUTDATED: {
    code: 'TRIP_OUTDATED',
    message: 'Le trajet est trop vieux',
  },
};

export default expectedErrors;
