import { Box, Stack, styled } from '@mui/material';
import React from 'react';

const DOT_SIZE = 8;

const Dot = styled(Box)(({ active, theme }) => ({
  backgroundColor: active ? theme.palette.primary.dark : theme.palette.grey[500],
  borderRadius: DOT_SIZE,
  height: DOT_SIZE,
  transition: 'all 0.25s ease',
  width: active ? DOT_SIZE * 2 : DOT_SIZE,
}));

function OnboardingIndicator({ activeStep, count, onIndicatorClick }) {
  if (count <= 1) return null;

  return (
    <Stack alignItems="center" direction="row" justifyContent="center" spacing={0.5}>
      {Array(count)
        .fill(0)
        .map((_, index) => (
          <Dot
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            active={index === activeStep}
            {...(!onIndicatorClick && {
              onClick: () => onIndicatorClick(index),
            })}
          />
        ))}
    </Stack>
  );
}

export default OnboardingIndicator;
