import { gql } from '@apollo/client';

const conversationFragment = gql`
  fragment Conversation on Conversation {
    id
    tripId
    passengerId
    conductorId
    createdAt
  }
`;

export default conversationFragment;
