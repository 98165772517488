import { ArrowBackOutlined } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { LANDING_ROOT_PATH } from 'src/routes/paths';
import AdminLogin from 'src/sections/administration/AdminLogin';
import AuthWrapper from 'src/sections/auth/AuthWrapper';

function LoginPage() {
  return (
    <AuthWrapper isAdmin pageTitle="Administration">
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <AdminLogin />
        </Grid>
        <Grid item xs="auto">
          <Button
            color="primary"
            LinkComponent={RouterLink}
            replace
            size="small"
            startIcon={<ArrowBackOutlined />}
            to={LANDING_ROOT_PATH}
          >
            Retourner au site web
          </Button>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
}

export default LoginPage;
