import { CircularProgress, Stack } from '@mui/material';
import React from 'react';

function CircularLoader() {
  return (
    <Stack alignItems="center" p={3}>
      <CircularProgress size={16} />
    </Stack>
  );
}

export default CircularLoader;
