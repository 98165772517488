import MuiAvatar from '@mui/material/Avatar';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

// project import
import getColors from '../../utils/getColors';

function getColorStyle({ color, theme, type }) {
  const colors = getColors(theme, color);
  const { contrastText, light, lighter, main } = colors;

  switch (type) {
    case 'filled':
      return {
        backgroundColor: main,
        color: contrastText,
      };
    case 'outlined':
      return {
        backgroundColor: 'transparent',
        border: '1px solid',
        borderColor: main,
        color: main,
      };
    case 'combined':
      return {
        backgroundColor: lighter,
        border: '1px solid',
        borderColor: light,
        color: main,
      };
    default:
      return {
        backgroundColor: lighter,
        color: main,
      };
  }
}

function getSizeStyle(size) {
  switch (size) {
    case 'badge':
      return {
        border: '2px solid',
        fontSize: '0.675rem',
        height: 20,
        width: 20,
      };
    case 'xs':
      return {
        fontSize: '0.75rem',
        height: 24,
        width: 24,
      };
    case 'sm':
      return {
        fontSize: '0.875rem',
        height: 32,
        width: 32,
      };
    case 'lg':
      return {
        fontSize: '1.2rem',
        height: 52,
        width: 52,
      };
    case 'xl':
      return {
        fontSize: '1.5rem',
        height: 64,
        width: 64,
      };
    case 'md':
    default:
      return {
        fontSize: '1rem',
        height: 40,
        width: 40,
      };
  }
}

const AvatarStyle = styled(MuiAvatar, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'type' && prop !== 'size',
})(({ color, size, theme, type, variant }) => ({
  ...getSizeStyle(size),
  ...getColorStyle({ color, theme, type, variant }),
  ...(size === 'badge' && {
    borderColor: theme.palette.background.default,
  }),
}));

export default function Avatar({
  children,
  color = 'primary',
  size = 'md',
  type,
  variant = 'circular',
  ...others
}) {
  const theme = useTheme();

  return (
    <AvatarStyle color={color} size={size} theme={theme} type={type} variant={variant} {...others}>
      {children}
    </AvatarStyle>
  );
}

Avatar.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
};
