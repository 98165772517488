import { yupResolver } from '@hookform/resolvers/yup';
import { SendOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Grid, InputLabel, Stack } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import FormErrors from 'src/components/FormErrors';
import { FormProvider } from 'src/components/hook-form';
import RHFPhoneFRTextField from 'src/components/hook-form/RHFPhoneFRTextField';
import { PHONE_REGEX } from 'src/constants/regex';
import useAuth from 'src/contexts/auth/useAuth';
import expectedErrors from 'src/graphql/auth/expectedErrors';
import getDeviceIdAsync from 'src/utils/getDeviceIdAsync';
import * as Yup from 'yup';

function AuthLogin() {
  const { login, loginState, user } = useAuth();

  const LoginSchema = Yup.object().shape({
    identifier: Yup.string()
      .transform((value, originalValue) => `+33${originalValue.replace(/\s/g, '')}`)
      .matches(PHONE_REGEX, "Le numéro n'est pas valide")
      .required('Le numéro de téléphone est requis'),
  });

  const methods = useForm({
    defaultValues: {
      identifier: '',
    },
    resolver: yupResolver(LoginSchema),
  });

  const onSubmit = async ({ identifier }) => {
    try {
      const device = await getDeviceIdAsync(user);
      await login({
        variables: { input: { device, identifier } },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = methods;

  return (
    <FormProvider methods={methods} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <InputLabel>Téléphone</InputLabel>
            <RHFPhoneFRTextField
              autoFocus
              fullWidth
              name="identifier"
              placeholder="6 12 34 56 78"
              type="phone"
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            color="primary"
            disabled={isSubmitting}
            disableElevation
            endIcon={<SendOutlined />}
            fullWidth
            loading={loginState.loading || isSubmitting}
            size="large"
            type="submit"
            variant="contained"
          >
            Connexion
          </LoadingButton>
        </Grid>
        {loginState.error && (
          <Grid item xs={12}>
            <FormErrors
              error={loginState.error}
              expectedErrors={[
                expectedErrors.USER_NOT_FOUND,
                expectedErrors.INVALID_CREDENTIALS,
                expectedErrors.ACCOUNT_SUSPENDED,
                expectedErrors.USER_NOT_FOUND,
              ]}
              withSnackbar
            />
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
}

export default AuthLogin;
