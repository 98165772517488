// scroll bar
import 'simplebar/dist/simplebar.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// apex-chart
import './assets/third-party/apex-chart.css';
import './assets/third-party/react-table.css';
import './locales/i18n';

import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { onUpdate } from 'src/utils/sw';

import App from './App';
import GoogleAutocompleteScript from './components/GoogleAutocompleteScript';
import { ConfigProvider } from './contexts/ConfigContext';
import { LoggerProvider } from './contexts/LoggerContext';
import reportWebVitals from './reportWebVitals';
import apolloClient from './services/apollo-client';
import serviceWorkerRegistration from './serviceWorkerRegistration';
import { store } from './store';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <LoggerProvider>
    <ReduxProvider store={store}>
      <GoogleAutocompleteScript />
      <ConfigProvider>
        <ApolloProvider client={apolloClient}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ApolloProvider>
      </ConfigProvider>
    </ReduxProvider>
  </LoggerProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
