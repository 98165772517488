import { gql } from '@apollo/client';

const fieldPredictionFragment = gql`
  fragment FieldPrediction on FieldPrediction {
    fieldName
    value
    accuracy
    success
  }
`;

export default fieldPredictionFragment;
