const onUpdate = (registration) => {
  const registrationWaiting = registration.waiting;
  if (registrationWaiting) {
    registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
    registrationWaiting.addEventListener('statechange', (e) => {
      if (e.target.state === 'activated') {
        window.location.href = `${window.location.pathname}${window.location.search}`;
      }
    });
  }
};

// eslint-disable-next-line import/prefer-default-export
export { onUpdate };
