import { Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import AuthCodeVerification from 'src/sections/auth/auth-forms/AuthCodeVerification';

function PassOTPChallenge() {
  return (
    <Container maxWidth="xs">
      <Grid alignItems="center" container sx={{ minHeight: '100vh' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography align="center" variant="h3">
                🤚 Holà! T&apos;as ton code ?
              </Typography>
              <Typography align="center">
                On vient de t&apos;envoyer un code de confirmation
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Container>
              <AuthCodeVerification />
            </Container>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PassOTPChallenge;
