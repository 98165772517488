import { QuestionAnswerOutlined } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Avatar from 'src/components/@extended/Avatar';
import useAuth from 'src/contexts/auth/useAuth';
import { PATH_USER } from 'src/routes/paths';

function UnreadMessageNotificationItem({ onClose, trip }) {
  const { user } = useAuth();

  const isConductor = useMemo(() => trip.conductorId === user.id, [trip, user]);

  const names = useMemo(
    () =>
      trip.unreadConversations.reduce((acc, conversation) => {
        if (isConductor) acc.push(conversation.passenger.firstname);
        else acc.push(conversation.conductor.firstname);
        return acc;
      }, []),
    [isConductor, trip.unreadConversations]
  );

  return (
    <ListItemButton
      LinkComponent={RouterLink}
      onClick={onClose}
      to={PATH_USER.trips.conversation(trip.id)}
    >
      <ListItemIcon>
        <Avatar color="success" size="sm">
          <QuestionAnswerOutlined sx={{ fontSize: 16 }} />
        </Avatar>
      </ListItemIcon>
      <ListItemText
        primary={<Typography variant="inherit">Tu as reçu un nouveau message</Typography>}
        secondary={`${names.join(', ')} ${names.length > 1 ? "t'ont écris" : "t'a écrit"}`}
      />
    </ListItemButton>
  );
}

export default UnreadMessageNotificationItem;
