import { ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';
import JOURNEY_STATUSES from 'src/constants/journeyStatuses';
import MOMENT_FORMATS from 'src/constants/momentFormats';
import { PATH_USER } from 'src/routes/paths';
import RequestedJourneysGroup from 'src/sections/journeys/RequestedJourneysGroup';

function BookJourneyNotificationItem({ onClose, trip }) {
  const navigate = useNavigate();

  const sanitizedJourneys = trip.journeys?.filter(({ status }) =>
    [JOURNEY_STATUSES.REQUESTED].includes(status)
  );

  const handleClickOnNotification = (tripId) => {
    navigate(PATH_USER.trips.detail(tripId));
    onClose();
  };

  return (
    <ListItemButton onClick={() => handleClickOnNotification(trip.id)}>
      <ListItemText
        primary={
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Typography variant="inherit">
              Trajet du{' '}
              <Typography color="primary" component="span">
                {moment(trip.startDateTime).format(MOMENT_FORMATS.DATE_FR)}
              </Typography>
              {' - '}
              <Typography component="span" variant="caption">
                {sanitizedJourneys.length} demande(s) en attente
              </Typography>
            </Typography>
            <RequestedJourneysGroup journeys={sanitizedJourneys} />
          </Stack>
        }
        secondary={`${trip.startAddress.mainText} - ${trip.endAddress.mainText}`}
      />
    </ListItemButton>
  );
}

export default BookJourneyNotificationItem;
