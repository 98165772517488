import { gql } from '@apollo/client';

const fileFragment = gql`
  fragment File on File {
    id
    fileName
    displayName
    ownerId
    parentFolderId
    downloadUrl
    isFolder
    isValid
    createdAt
    fileType
    updatedAt
  }
`;

export default fileFragment;
