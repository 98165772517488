const bindExternalLinks = () => {
  if (!window.ReactNativeWebView) return;

  const links = document.querySelectorAll('a[href][target="_blank"]:not([data-bound="true"])');

  links.forEach((link) => {
    // eslint-disable-next-line no-param-reassign
    link.onclick = function (event) {
      event.preventDefault();
      link.setAttribute('data-bound', 'true');
      window.openURL(link.getAttribute('href'));
    };
  });
};

export default bindExternalLinks;
