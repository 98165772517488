import { useCallback, useEffect } from 'react';
import useAuth from 'src/contexts/auth/useAuth';

function NotificationsHandler() {
  const useApp = Boolean(window.ReactNativeWebView);
  const { attachPushToken, user } = useAuth();

  const attachToken = useCallback(
    async (pushToken) => {
      await attachPushToken({
        variables: {
          pushToken,
        },
      });
    },
    [attachPushToken]
  );

  const registerForPushNotifications = useCallback(async () => {
    if (!useApp) return;

    try {
      const pushToken = await window.registerForPushNotifications();

      if (user.pushToken !== pushToken) {
        await attachToken(pushToken);
      }
    } catch (error) {
      await attachToken(null);
    }
  }, [attachToken, useApp, user.pushToken]);

  useEffect(() => {
    registerForPushNotifications();
  }, [registerForPushNotifications]);

  return null;
}

export default NotificationsHandler;
