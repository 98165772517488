import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { GET_MY_CURRENT_TRIP } from 'src/graphql/trip/queries';
import { TRIP_UPDATED } from 'src/graphql/trip/subscriptions';
import TripMode from 'src/sections/trips/trip-mode';
import SnackbarsHandler from 'src/sections/trips/trip-mode/SnackbarsHandler';
import { useSafeLazyQuery, useSafeSubscription } from 'src/services/apollo-client/wrappers';

import useAuth from './auth/useAuth';
import { useNotification } from './NotificationContext';

const CurrentTripContext = createContext();

const useCurrentTrip = () => useContext(CurrentTripContext);

function CurrentTripProvider({ children }) {
  const { refetchNotifications } = useNotification();
  const [getMyCurrentTrip, { data, refetch: refetchMyCurrentTrip }] =
    useSafeLazyQuery(GET_MY_CURRENT_TRIP);
  const { isAuthenticated, user } = useAuth();

  const { data: subData } = useSafeSubscription(TRIP_UPDATED);

  useEffect(() => {
    if (isAuthenticated && user && !user.isOtpVerificationRequired) getMyCurrentTrip();
  }, [getMyCurrentTrip, isAuthenticated, user]);

  useEffect(() => {
    if (subData) {
      refetchMyCurrentTrip();
      refetchNotifications();
    }
  }, [subData, refetchMyCurrentTrip, refetchNotifications]);

  const memoizedContextValue = useMemo(() => ({}), []);

  const currentTrip = data && data.getMyCurrentTrip;
  const isConductor = user?.id === currentTrip?.conductor?.id;

  return (
    <CurrentTripContext.Provider value={memoizedContextValue}>
      {children}
      {currentTrip && <TripMode trip={currentTrip} />}
      {currentTrip && subData && (
        <SnackbarsHandler isConductor={isConductor} type={subData.tripUpdated.type} />
      )}
    </CurrentTripContext.Provider>
  );
}

export { CurrentTripProvider, useCurrentTrip };
