import { gql } from '@apollo/client';

const transferDataFragment = gql`
  fragment TransferData on TransferData {
    Id
    Tag
    CreationDate
    AuthorId
    DebitedFunds {
      Currency
      Amount
    }
    CreditedFunds {
      Currency
      Amount
    }
    Fees {
      Currency
      Amount
    }
    Status
    ResultCode
    ResultMessage
    ExecutionDate
    Type
    Nature
    DebitedWalletId
    PaymentType
    BankAccountId
    BankWireRef
  }
`;

export default transferDataFragment;
