import { MenuOpenOutlined } from '@mui/icons-material';
import { IconButton, Toolbar, useScrollTrigger } from '@mui/material';
import React, { useMemo } from 'react';
import useResponsive from 'src/hooks/useResponsive';
import { dispatch, useSelector } from 'src/store';
import { openDrawer } from 'src/store/reducers/menu';

import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

function Header() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 12,
  });
  const isMobile = useResponsive('down', 'md');

  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  // common header
  const mainHeader = (
    <Toolbar>
      {isMobile && (
        <IconButton onClick={() => dispatch(openDrawer(!drawerOpen))}>
          <MenuOpenOutlined />
        </IconButton>
      )}
      {headerContent}
    </Toolbar>
  );

  const appBar = {
    color: 'inherit',
    elevation: 10,
    position: 'fixed',
    sx: {
      border: 'none',
      boxShadow: (th) => th.shadows[trigger ? 6 : 0],
      transition: 'all 0.25s ease',
      width: '100%',
      zIndex: 999,
    },
  };

  return (
    <AppBarStyled open={drawerOpen} {...appBar}>
      {mainHeader}
    </AppBarStyled>
  );
}

export default Header;
