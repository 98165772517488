import { Button, Divider, Stack, Typography, styled } from '@mui/material';
import Lottie from 'lottie-react';
import React from 'react';
import errorAnimationData from 'src/assets/animations/error.json';
import { APP_ROOT_PATH } from 'src/routes/paths';
import isDevelopmentMode from 'src/utils/isDevelopmentMode';

const ErrorWrapper = styled(Stack)({
  alignItems: 'center',
  height: '100vh',
  justifyContent: 'center',
});

function ErrorFallback({ error, resetErrorBoundary }) {
  const handleGoToHome = () => {
    window.location.href = APP_ROOT_PATH;
  };

  return (
    <ErrorWrapper px={5} spacing={3}>
      <Stack>
        <Typography align="center" variant="h3">
          Une erreur inattendue est survenue
        </Typography>
        {isDevelopmentMode() && <code>{error.toString()}</code>}
        <Stack alignItems="center" sx={{ width: '100%' }}>
          <Stack sx={{ maxWidth: 350 }}>
            <Lottie animationData={errorAnimationData} loop={false} />
          </Stack>
        </Stack>
        <Typography align="center" color="text.secondary" variant="caption">
          Nous avons été informé et appliquerons un correctif si nécessaire
        </Typography>
      </Stack>
      <Stack alignItems="center" spacing={2}>
        <Button onClick={handleGoToHome} variant="contained">
          Retour à l&apos;accueil
        </Button>
        <Stack width="100%">
          <Divider>OU</Divider>
        </Stack>
        <Button onClick={resetErrorBoundary} variant="outlined">
          Rafraîchir
        </Button>
      </Stack>
    </ErrorWrapper>
  );
}

export default ErrorFallback;
