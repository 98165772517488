import React from 'react';
import { Navigate } from 'react-router-dom';

import { APP_ROOT_PATH } from '../paths';

const DeadEndRoutes = {
  children: [
    {
      element: <Navigate replace to={APP_ROOT_PATH} />,
      path: '*',
    },
  ],
  path: '',
};

export default DeadEndRoutes;
