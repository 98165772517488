import { Stack } from '@mui/material';
import React from 'react';
import HelpLink from 'src/components/HelpLink';
import GoogleMap from 'src/components/map/GoogleMap';
import MapFadedContainer from 'src/components/map/MapFadedContainer';
import PageWrapper from 'src/components/PageWrapper';
import TripTimeline from 'src/sections/journeys/TripTimeline';

import TripProgress from '../TripProgress';
import ConfirmDepositAction from './ConfirmDepositAction';

function TakenInChargeMode({ journey, trip }) {
  return (
    <PageWrapper subtitle="C'est parti!" title="Mode trajet" withoutLogo>
      <Stack spacing={3}>
        <TripTimeline trip={trip} withoutHoursInformation />
        <MapFadedContainer>
          <GoogleMap endAddress={trip.endAddress} startAddress={trip.startAddress} />
        </MapFadedContainer>
        <TripProgress trip={trip} />
        <ConfirmDepositAction journey={journey} />
        <HelpLink additionalInfos={{ tripId: trip.id }} />
      </Stack>
    </PageWrapper>
  );
}

export default TakenInChargeMode;
