const expectedErrors = {
  BALANCE_NOT_ENOUGH: {
    code: 'BALANCE_NOT_ENOUGH',
    message: 'Ta balance est insuffisante',
  },
  JOURNEY_ALREADY_CREATED_WITH_SAME_START_DATE_TIME: {
    code: 'JOURNEY_ALREADY_CREATED_WITH_SAME_START_DATE_TIME',
    message: 'Tu as déjà une réservation sur le même créneau',
  },
  TRIP_ALREADY_CREATED_WITH_SAME_START_DATE_TIME: {
    code: 'TRIP_ALREADY_CREATED_WITH_SAME_START_DATE_TIME',
    message: 'Tu as déjà un trajet sur ce créneau',
  },
  TRIP_FULL: {
    code: 'TRIP_FULL',
    message: 'Ce trajet est complet',
  },
};

export default expectedErrors;
