import { CloseOutlined, MenuOutlined } from '@mui/icons-material';
import { Box, Container, IconButton, Stack, Toolbar, useScrollTrigger } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/logo/Logo';
import useResponsive from 'src/hooks/useResponsive';
import { LANDING_ROOT_PATH } from 'src/routes/paths';

import AppBarStyled from '../../MainLayout/Header/AppBarStyled';
import HeaderContent from './HeaderContent';
import MobileMenu from './MobileMenu';

function MainHeader({ headerContent }) {
  const isMobile = useResponsive('down', 'md');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [menuOpen, setMenuOpen] = useState(false);

  const iconVariants = {
    hidden: { opacity: 0, rotate: 0 },
    rotate: { opacity: 1, rotate: 90 },
    visible: { opacity: 1, rotate: 0 },
  };

  return (
    <Stack sx={isMobile && menuOpen && { boxShadow: (theme) => theme.shadows[6] }}>
      <Toolbar component="nav">
        <Container
          disableGutters
          maxWidth="lg"
          sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}
        >
          <Box
            component={RouterLink}
            sx={{ color: 'inherit', textDecoration: 'none' }}
            to={LANDING_ROOT_PATH}
          >
            <Logo withBrandName />
          </Box>
          {localStorage.getItem('load-in-webview') !== 'true' && (
            <Stack alignItems="center" direction="row" spacing={3}>
              {!isMobile && headerContent}
              {isMobile && (
                <IconButton onClick={() => setMenuOpen(!menuOpen)}>
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={menuOpen ? 'open' : 'closed'}
                      animate={menuOpen ? 'rotate' : 'visible'}
                      exit="hidden"
                      initial="visible"
                      transition={{ duration: 0.15 }}
                      variants={iconVariants}
                    >
                      {menuOpen ? <CloseOutlined /> : <MenuOutlined />}
                    </motion.div>
                  </AnimatePresence>
                </IconButton>
              )}
            </Stack>
          )}
        </Container>
      </Toolbar>
      <MobileMenu onClose={() => setMenuOpen(false)} open={menuOpen} />
    </Stack>
  );
}

function Header() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 12,
  });

  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  const appBar = {
    color: 'inherit',
    elevation: 10,
    position: 'fixed',
    sx: {
      border: 'none',
      boxShadow: (th) => th.shadows[trigger ? 6 : 0],
      transition: 'all 0.25s ease',
      width: '100%',
      zIndex: 999,
    },
  };

  return (
    <AppBarStyled isLanding {...appBar}>
      <MainHeader headerContent={headerContent} />
    </AppBarStyled>
  );
}

export default Header;
