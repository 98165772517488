import { LinearProgress, Stack, Typography } from '@mui/material';
import React from 'react';

function ClassicHandler({ indicatorVisible, opacity, readyToRefresh, refreshing }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      sx={{
        backgroundColor: (th) => th.palette.background.default,
        boxShadow: refreshing ? '0px 10px 10px 0px rgba(0,0,0, 0.15)' : 'none',
        left: 0,
        opacity: refreshing || indicatorVisible ? 1 : opacity,
        p: 1,
        position: 'fixed',
        pt: 0,
        top: 60,
        transition: 'all 0.5s ease',
        width: '100%',
        zIndex: (th) => (refreshing ? th.zIndex.drawer + 9 : -50),
      }}
    >
      {!refreshing && (
        <Typography variant="caption">
          {readyToRefresh ? 'Relâcher pour rafraîchir' : 'Tirer pour rafraîchir'}
        </Typography>
      )}
      {refreshing && <Typography variant="caption">Rafraîchissement</Typography>}
      <LinearProgress
        sx={{ width: 100 }}
        value={opacity * 100}
        variant={refreshing ? 'indeterminate' : 'determinate'}
      />
    </Stack>
  );
}

export default ClassicHandler;
