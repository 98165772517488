import React from 'react';
import JOURNEY_STATUSES from 'src/constants/journeyStatuses';
import useAuth from 'src/contexts/auth/useAuth';

import ConductorMode from './conductor-mode/ConductorMode';
import PassengerMode from './passenger-mode/PassengerMode';

function TripMode({ trip }) {
  const { user } = useAuth();

  const isConductor = trip.conductor.id === user.id;
  const currentConfirmedPassengerJourney = trip.journeys.find(
    ({ passenger, status }) => passenger.id === user.id && status === JOURNEY_STATUSES.APPROVED
  );

  if (isConductor) {
    return <ConductorMode trip={trip} />;
  }

  return <PassengerMode journey={currentConfirmedPassengerJourney} trip={trip} />;
}

export default TripMode;
