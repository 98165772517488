// project import
import { alpha } from '@mui/material';

import { ThemeMode } from '../../config';
import getColors from '../../utils/getColors';
import getShadow from '../../utils/getShadow';

function getColor({ theme, variant }) {
  const colors = getColors(theme, variant);
  const { light } = colors;

  const shadows = getShadow(theme, `${variant}`);

  return {
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${light}`,
      },
      boxShadow: shadows,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: light,
    },
  };
}

export default function OutlinedInput(theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        colorError: getColor({ theme, variant: 'error' }),
        colorInfo: getColor({ theme, variant: 'info' }),
        colorSecondary: getColor({ theme, variant: 'secondary' }),
        colorSuccess: getColor({ theme, variant: 'success' }),
        colorWarning: getColor({ theme, variant: 'warning' }),
        input: {
          padding: '10.5px 14px 10.5px 12px',
        },
        inputMultiline: {
          padding: 0,
        },
        inputSizeSmall: {
          padding: '7.5px 8px 7.5px 12px',
        },
        notchedOutline: {
          borderColor:
            theme.palette.mode === ThemeMode.DARK
              ? theme.palette.grey[200]
              : theme.palette.grey[400],
        },
        root: {
          background: alpha(theme.palette.background.default, 0.9),
          ...getColor({ theme, variant: 'primary' }),
          '&.Mui-error': {
            ...getColor({ theme, variant: 'error' }),
          },
        },
      },
    },
  };
}
