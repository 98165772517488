import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useResponsive from 'src/hooks/useResponsive';

import Dot from '../../../../../components/@extended/Dot';
import { MenuOrientation, ThemeMode } from '../../../../../config';
import useConfig from '../../../../../hooks/useConfig';
import { openDrawer } from '../../../../../store/reducers/menu';

function NavItem({ item, level }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { menuOrientation } = useConfig();
  const { drawerOpen, openItem } = useSelector((state) => state.menu);

  const isMobile = useResponsive('down', 'md');

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = {
    // eslint-disable-next-line react/no-unstable-nested-components, react/display-name
    component: forwardRef((props, ref) => (
      <Link {...props} ref={ref} target={itemTarget} to={item.url} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const Icon = item.icon;
  const itemIcon = item.icon ? <Icon style={{ fontSize: '1rem' }} /> : false;

  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const textColor = theme.palette.mode === ThemeMode.DARK ? 'grey.400' : 'text.primary';
  const iconSelectedColor =
    theme.palette.mode === ThemeMode.DARK && drawerOpen ? 'text.primary' : 'primary.main';

  return menuOrientation === MenuOrientation.VERTICAL || isMobile ? (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      selected={isSelected}
      sx={{
        pl: 1.5,
        py: !drawerOpen && level === 1 ? 1.25 : 1,
        ...(drawerOpen && {
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter',
              color: iconSelectedColor,
            },
            bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter',
            borderRight: `2px solid ${theme.palette.primary.main}`,
            color: iconSelectedColor,
          },
          '&:hover': {
            bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter',
          },
        }),
        ...(!drawerOpen && {
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: 'transparent',
            },
            bgcolor: 'transparent',
          },
          '&:hover': {
            bgcolor: 'transparent',
          },
        }),
      }}
      {...(isMobile && {
        onClick: () => dispatch(openDrawer(false)),
      })}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            color: isSelected ? iconSelectedColor : textColor,
            minWidth: 28,
            ...(!drawerOpen && {
              '&:hover': {
                bgcolor:
                  theme.palette.mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.lighter',
              },
              alignItems: 'center',
              borderRadius: 1.5,
              height: 36,
              justifyContent: 'center',
              width: 36,
            }),
            ...(!drawerOpen &&
              isSelected && {
                '&:hover': {
                  bgcolor:
                    theme.palette.mode === ThemeMode.DARK ? 'primary.darker' : 'primary.lighter',
                },
                bgcolor: theme.palette.mode === ThemeMode.DARK ? 'primary.900' : 'primary.lighter',
              }),
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          <Typography sx={{ color: isSelected ? iconSelectedColor : textColor }} variant="h6">
            {item.title}
          </Typography>
        }
      />
      {item.chip && (
        <Chip
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          color={item.chip.color}
          label={item.chip.label}
          size={item.chip.size}
          variant={item.chip.variant}
        />
      )}
    </ListItemButton>
  ) : (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      selected={isSelected}
      sx={{
        ...(drawerOpen && {
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: 'transparent',
              color: iconSelectedColor,
            },
            bgcolor: 'transparent',
            color: iconSelectedColor,
          },
          '&:hover': {
            bgcolor: 'transparent',
          },
        }),
        ...(!drawerOpen && {
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: 'transparent',
            },
            bgcolor: 'transparent',
          },
          '&:hover': {
            bgcolor: 'transparent',
          },
        }),
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 36,
            ...(!drawerOpen && {
              '&:hover': {
                bgcolor: 'transparent',
              },
              alignItems: 'center',
              borderRadius: 1.5,
              height: 36,
              justifyContent: 'flex-start',
              width: 36,
            }),
            ...(!drawerOpen &&
              isSelected && {
                '&:hover': {
                  bgcolor: 'transparent',
                },
                bgcolor: 'transparent',
              }),
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}

      {!itemIcon && (
        <ListItemIcon
          sx={{
            color: isSelected ? 'primary.main' : 'secondary.main',
            ...(!drawerOpen && {
              '&:hover': {
                bgcolor: 'transparent',
              },
              alignItems: 'center',
              borderRadius: 1.5,
              justifyContent: 'flex-start',
            }),
            ...(!drawerOpen &&
              isSelected && {
                '&:hover': {
                  bgcolor: 'transparent',
                },
                bgcolor: 'transparent',
              }),
          }}
        >
          <Dot color={isSelected ? 'primary' : 'secondary'} size={4} />
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          <Typography color="inherit" variant="h6">
            {item.title}
          </Typography>
        }
      />
      {item.chip && (
        <Chip
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          color={item.chip.color}
          label={item.chip.label}
          size={item.chip.size}
          variant={item.chip.variant}
        />
      )}
    </ListItemButton>
  );
}

NavItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
