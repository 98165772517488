import { gql } from '@apollo/client';

import { alertFragment } from '../fragments';

const CREATE_ALERT = gql`
  mutation createAlert($input: UpsertAlertInput!) {
    createAlert(input: $input) {
      ...Alert
    }
  }
  ${alertFragment}
`;

const UPDATE_ALERT = gql`
  mutation updateAlert($id: ID!, $input: UpsertAlertInput!) {
    updateAlert(id: $id, input: $input) {
      ...Alert
    }
  }
  ${alertFragment}
`;

const DELETE_ALERT = gql`
  mutation deleteAlert($id: ID!) {
    deleteAlert(id: $id)
  }
`;

export { CREATE_ALERT, UPDATE_ALERT, DELETE_ALERT };
