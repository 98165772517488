import { Container } from '@mui/material';
import React from 'react';

import PageTransition from './PageTransition';

function AnimatedContainer({ animate, ...props }) {
  if (!animate) return <Container disableGutters maxWidth={false} {...props} />;

  return (
    <PageTransition>
      <Container disableGutters maxWidth={false} {...props} />
    </PageTransition>
  );
}

export default AnimatedContainer;
