import { CircularProgress } from '@mui/material';
import React from 'react';

import getAvatarName from '../../utils/getAvatarName';
import Avatar from '../@extended/Avatar';

function UserAvatar({ children, loading, size, user, ...props }) {
  const avatar = user?.avatar;
  const firstname = user?.firstname;

  return (
    <Avatar color="primary" size={size} src={!loading && avatar} type="outlined" {...props}>
      {loading ? (
        <CircularProgress size={14} />
      ) : (
        <>
          {children && children}
          {!children && getAvatarName(firstname)}
        </>
      )}
    </Avatar>
  );
}

UserAvatar.defaultProps = {
  size: 'sm',
};

export default UserAvatar;
