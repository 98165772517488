import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

// project-imports
import { ThemeMode } from '../config';

// header style
const headerSX = {
  '& .MuiCardHeader-action': { alignSelf: 'center', m: '0px auto' },
  '& .MuiCardHeader-content': { minWidth: 0 },
  p: 2.5,
};

// eslint-disable-next-line react/display-name
const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentSX = {},
      darkTitle,
      divider = true,
      elevation,
      modal = false,
      secondary,
      shadow,
      subheader,
      sx = {},
      title,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();
    // eslint-disable-next-line no-param-reassign
    boxShadow = theme.palette.mode === ThemeMode.DARK ? boxShadow || true : boxShadow;

    return (
      <Card
        ref={ref}
        elevation={elevation || 0}
        {...others}
        sx={{
          ':hover': {
            boxShadow: boxShadow ? shadow || theme.customShadows.z1 : 'inherit',
          },
          border: border ? '1px solid' : 'none',
          borderColor: theme.palette.divider,
          borderRadius: 1,
          boxShadow:
            boxShadow && (!border || theme.palette.mode === ThemeMode.DARK)
              ? shadow || theme.customShadows.z1
              : 'inherit',
          position: 'relative',
          ...(modal && {
            '& .MuiCardContent-root': {
              maxHeight: `calc(100vh - 200px)`,
              minHeight: 'auto',
              overflowY: 'auto',
            },
            left: '50%',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: { sm: 'auto', xs: `calc( 100% - 50px)` },
          }),
          ...sx,
        }}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader
            action={secondary}
            subheader={subheader}
            sx={headerSX}
            title={title}
            titleTypographyProps={{ noWrap: true, variant: 'subtitle1' }}
          />
        )}
        {darkTitle && title && (
          <CardHeader
            action={secondary}
            sx={headerSX}
            title={<Typography variant="h4">{title}</Typography>}
          />
        )}

        {/* content & header divider */}
        {title && divider && <Divider />}

        {/* card content */}
        {content && <CardContent sx={contentSX}>{children}</CardContent>}
        {!content && children}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  divider: PropTypes.bool,
  elevation: PropTypes.number,
  modal: PropTypes.bool,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  shadow: PropTypes.string,
  subheader: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
};

export default MainCard;
