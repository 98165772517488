import { PATH_LANDING } from 'src/routes/paths';

const discoverItems = [
  {
    label: "L'application Coccinelle",
    name: 'download-app',
    url: PATH_LANDING.downloadApp,
  },
  {
    label: 'La Prime “Coup de Pouce“',
    name: 'bonuses',
    url: PATH_LANDING.bonuses,
  },
  {
    label: 'Blog',
    name: 'blog',
    url: PATH_LANDING.blog.root,
  },
];

export default discoverItems;
