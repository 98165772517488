// material-ui
import MuiIconButton from '@mui/material/IconButton';
import { alpha, styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

// project imports
import getColors from '../../utils/getColors';
import getShadow from '../../utils/getShadow';

// ==============================|| ICON BUTTON - COLOR STYLE ||============================== //

function getColorStyle({ color, theme, variant }) {
  const colors = getColors(theme, color);
  const { contrastText, dark, light, lighter, main } = colors;

  const buttonShadow = `${color}Button`;
  const shadows = getShadow(theme, buttonShadow);

  const commonShadow = {
    '&::after': {
      boxShadow: `0 0 6px 6px ${alpha(main, 0.9)}`,
    },
    '&:active::after': {
      boxShadow: `0 0 0 0 ${alpha(main, 0.9)}`,
    },
    '&:focus-visible': {
      outline: `2px solid ${dark}`,
      outlineOffset: 2,
    },
  };

  switch (variant) {
    case 'contained':
      return {
        '&:hover': {
          backgroundColor: dark,
        },
        backgroundColor: main,
        color: contrastText,
        ...commonShadow,
      };
    case 'light':
      return {
        '&:hover': {
          backgroundColor: light,
        },
        backgroundColor: lighter,
        color: main,
        ...commonShadow,
      };
    case 'shadow':
      return {
        '&:hover': {
          backgroundColor: dark,
          boxShadow: 'none',
        },
        backgroundColor: main,
        boxShadow: shadows,
        color: contrastText,
        ...commonShadow,
      };
    case 'outlined':
      return {
        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: dark,
          color: dark,
        },
        ...commonShadow,
      };
    case 'dashed':
      return {
        '&:hover': {
          borderColor: dark,
          color: dark,
        },
        backgroundColor: lighter,
        ...commonShadow,
      };
    case 'text':
    default:
      return {
        '&:hover': {
          backgroundColor: lighter,
          color: dark,
        },
        ...commonShadow,
      };
  }
}

// ==============================|| STYLED - ICON BUTTON ||============================== //

const IconButtonStyle = styled(MuiIconButton, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'shape',
})(({ color, shape, theme, variant }) => ({
  '::after': {
    borderRadius: shape === 'rounded' ? '50%' : 4,
    content: '""',
    display: 'block',
    height: '100%',
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    transition: 'all 0.5s',
    width: '100%',
  },
  ':active::after': {
    borderRadius: shape === 'rounded' ? '50%' : 4,
    left: 0,
    opacity: 1,
    position: 'absolute',
    top: 0,
    transition: '0s',
  },

  position: 'relative',
  ...(shape === 'rounded' && {
    borderRadius: '50%',
  }),
  ...(variant === 'outlined' && {
    border: '1px solid',
    borderColor: 'inherit',
  }),
  ...(variant === 'dashed' && {
    border: '1px dashed',
    borderColor: 'inherit',
  }),
  ...(variant !== 'text' && {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
    },
  }),
  ...getColorStyle({ color, theme, variant }),
}));

// ==============================|| EXTENDED - ICON BUTTON ||============================== //

const IconButton = forwardRef(
  ({ children, color = 'primary', shape = 'square', variant = 'text', ...others }, ref) => {
    const theme = useTheme();

    return (
      <IconButtonStyle
        ref={ref}
        color={color}
        disableRipple
        shape={shape}
        theme={theme}
        variant={variant}
        {...others}
      >
        {children}
      </IconButtonStyle>
    );
  }
);

IconButton.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  shape: PropTypes.string,
  variant: PropTypes.string,
};

IconButton.displayName = 'IconButton';

export default IconButton;
