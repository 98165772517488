import React from 'react';
import { useConnectivity } from 'src/contexts/ConnectivityContext';

import { usePullToRefresh } from './PullToRefreshContext';
import PullToRefreshHandler from './PullToRefreshHandler';

function PullToRefresh({ target = window }) {
  const { active } = usePullToRefresh();
  const { isOnline } = useConnectivity();

  // Disable pull-to-refresh for non connected devices
  if (!isOnline) return null;

  const isDisplayStandalone = () => window.matchMedia('(display-mode: standalone)').matches;
  const isInWebView = Boolean(window.ReactNativeWebView);

  if (active && (isDisplayStandalone() || isInWebView))
    return <PullToRefreshHandler target={target} />;

  return null;
}

export default PullToRefresh;
