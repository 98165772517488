// ==============================|| OVERRIDES - INPUT BASE ||============================== //

export default function InputBase() {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          webkitTextSizeAdjust: 'none',
        },
        sizeSmall: {
          fontSize: '0.75rem',
        },
      },
    },
  };
}
