import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROOT_PATH } from 'src/routes/paths';

import useAuth from '../../contexts/auth/useAuth';

function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      const redirectPath = location?.state?.from || APP_ROOT_PATH;

      navigate(redirectPath, {
        replace: true,
        state: {
          from: '',
        },
      });
    }
  }, [isAuthenticated, location, navigate, user]);

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
