import { gql } from '@apollo/client';

import { journeyFragment } from '../fragments';

const BOOK_JOURNEY = gql`
  mutation bookJourney($input: BookJourneyInput!) {
    bookJourney(input: $input) {
      ...Journey
      passenger {
        id
        avatar
        firstname
      }
    }
  }
  ${journeyFragment}
`;

const CANCEL_JOURNEY = gql`
  mutation cancelJourney($id: ID!) {
    cancelJourney(id: $id) {
      ...Journey
      passenger {
        id
        avatar
        firstname
      }
    }
  }
  ${journeyFragment}
`;

const APPROVE_PASSENGER = gql`
  mutation approvePassenger($input: ApprovePassengerInput!) {
    approvePassenger(input: $input) {
      ...Journey
      passenger {
        id
        avatar
        firstname
      }
    }
  }
  ${journeyFragment}
`;

const DECLINE_PASSENGER = gql`
  mutation declinePassenger($input: DeclinePassengerInput!) {
    declinePassenger(input: $input) {
      ...Journey
      passenger {
        id
        avatar
        firstname
      }
    }
  }
  ${journeyFragment}
`;

const DROP_JOURNEY = gql`
  mutation dropJourney($id: ID!) {
    dropJourney(id: $id) {
      ...Journey
      passenger {
        id
        avatar
        firstname
      }
    }
  }
  ${journeyFragment}
`;

export { BOOK_JOURNEY, CANCEL_JOURNEY, APPROVE_PASSENGER, DECLINE_PASSENGER, DROP_JOURNEY };
