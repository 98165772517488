import React, { lazy } from 'react';
import Loadable from 'src/components/Loadable';
import MainLayout from 'src/layout/MainLayout';
import AdminGuard from 'src/utils/route-guard/AdminGuard';

const UsersPage = Loadable(lazy(() => import('src/pages/administration/Users')));
const PartnersPage = Loadable(lazy(() => import('src/pages/administration/Partners')));
const TripsPage = Loadable(lazy(() => import('src/pages/administration/Trips')));
const RewardsPage = Loadable(lazy(() => import('src/pages/administration/Rewards')));

const AdministrationRoutes = {
  children: [
    {
      element: <div>Test</div>,
      index: true,
    },
    {
      element: <UsersPage />,
      path: 'users',
    },
    {
      element: <PartnersPage />,
      path: 'partners',
    },
    {
      children: [
        {
          element: <TripsPage />,
          path: '',
        },
        {
          element: <TripsPage />,
          path: ':id',
        },
      ],
      path: 'trips',
    },
    {
      element: <RewardsPage />,
      path: 'rewards',
    },
  ],
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  path: '',
};

export default AdministrationRoutes;
