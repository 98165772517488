import React, { useCallback } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useLogger } from 'src/contexts/LoggerContext';

import ErrorFallback from './ErrorFallback';

function ErrorBoundary({ children }) {
  const { logger } = useLogger();

  const handleError = useCallback(
    (error, errorInfo) => {
      logger.error(`[CLIENT] Uncaught error: ${error.toString()}`, {
        error: error.toString(),
        errorInfo,
      });
    },
    [logger]
  );

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      {children}
    </ReactErrorBoundary>
  );
}

export default ErrorBoundary;
