import {
  CheckOutlined,
  CloseOutlined,
  HourglassBottomOutlined,
  PhoneOutlined,
  SportsScoreOutlined,
} from '@mui/icons-material';
import {
  Avatar,
  AvatarGroup,
  Card,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import IconButton from 'src/components/@extended/IconButton';
import UserAvatar from 'src/components/users/UserAvatar';
import JOURNEY_STATUSES from 'src/constants/journeyStatuses';
import { useJourney } from 'src/contexts/JourneyContext';
import useDialogs from 'src/hooks/useDialogs';

function PassengerList({ journeys }) {
  const { dropJourney, dropJourneyState } = useJourney();
  const { confirm } = useDialogs();

  const handleDropJourney = async (journeyId, passenger) => {
    try {
      if (
        !(await confirm({
          confirmLabel: 'Confirmer',
          message: (
            <span>
              {passenger.firstname} est absent? Indique-le nous en confirmant.
              <br />
            </span>
          ),
          title: 'Un passager est absent',
        }))
      )
        return;

      await dropJourney({
        variables: {
          input: {
            id: journeyId,
          },
        },
      });
    } catch {
      //
    }
  };

  return (
    <Card>
      {!journeys.length && (
        <Stack p={2}>
          <Typography
            align="center"
            color="text.secondary"
            sx={{ width: '100%' }}
            variant="caption"
          >
            Tu voyages seul 😌
          </Typography>
        </Stack>
      )}
      {Boolean(journeys.length) && (
        <List>
          {journeys
            .filter(({ status }) => status === JOURNEY_STATUSES.APPROVED)
            .map(({ id, passenger, ...journey }) => (
              <ListItem key={id}>
                <ListItemIcon>
                  <AvatarGroup>
                    {!journey.hasConfirmedTakingCharge && !journey.hasDeclinedTakingCharge && (
                      <Avatar sx={{ bgcolor: 'warning.light', height: 24, width: 24 }}>
                        <HourglassBottomOutlined sx={{ fontSize: 16 }} />
                      </Avatar>
                    )}
                    {journey.hasDeclinedTakingCharge && (
                      <Avatar sx={{ bgcolor: 'error.dark', height: 24, width: 24 }}>
                        <CloseOutlined sx={{ fontSize: 16 }} />
                      </Avatar>
                    )}
                    {journey.hasConfirmedTakingCharge && !journey.hasDeclinedTakingCharge && (
                      <Avatar sx={{ bgcolor: 'success.light', height: 24, width: 24 }}>
                        <CheckOutlined sx={{ fontSize: 16 }} />
                      </Avatar>
                    )}
                    <UserAvatar size="xs" user={passenger} />
                  </AvatarGroup>
                </ListItemIcon>
                <ListItemText primary={passenger.firstname} />
                <ListItemSecondaryAction>
                  {!journey.hasDeclinedTakingCharge && !journey.hasConfirmedDeposit && (
                    <>
                      {!journey.hasConfirmedTakingCharge && (
                        <IconButton
                          disabled={dropJourneyState.loading}
                          onClick={() => handleDropJourney(id, passenger)}
                          size="small"
                        >
                          <CloseOutlined sx={{ fontSize: 16 }} />
                        </IconButton>
                      )}
                      <IconButton
                        disabled={dropJourneyState.loading}
                        href={`tel:${passenger.phone}`}
                        LinkComponent={Link}
                        size="small"
                      >
                        <PhoneOutlined sx={{ fontSize: 16 }} />
                      </IconButton>
                    </>
                  )}
                  {journey.hasConfirmedDeposit && (
                    <IconButton size="small">
                      <SportsScoreOutlined sx={{ color: 'success.dark', fontSize: 16 }} />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      )}
    </Card>
  );
}

export default PassengerList;
