import React, { useCallback, useMemo } from 'react';
import useAuth from 'src/contexts/auth/useAuth';
import { FileProvider } from 'src/contexts/FileContext';
import { useLocation } from 'src/contexts/LocationContext';

import Onboarding from '../onboarding';
import step1ImageSrc from './step1.gif';
import step2ImageSrc from './step2.gif';
import step3ImageSrc from './step3.gif';
import step4ImageSrc from './step4.gif';
import Step5Content from './Step5Content';
import Step6Content from './Step6Content';

function WelcomeOnboarding() {
  const { user } = useAuth();
  const { hasPermission, requestPermissions } = useLocation();

  const handleOnboardingEnd = useCallback(async () => {
    if (hasPermission) return;

    await requestPermissions();
  }, [requestPermissions, hasPermission]);

  const step6Children = useCallback(
    ({ setNextDisabled, setOnClick }) => (
      <Step6Content setNextDisabled={setNextDisabled} setOnClick={setOnClick} />
    ),
    []
  );

  const STEPS = useMemo(
    () => [
      {
        content: 'Ajoute un permis de conduire valide pour publier ton premier trajet',
        imageSrc: step1ImageSrc,
        subtitle: 'Nous validons ta pièce en quelques secondes',
        title: 'Valide ton permis',
      },
      {
        content:
          "Publie rapidement un trajet et reçois des notifications dès qu'un passager veut participer.",
        imageSrc: step2ImageSrc,
        subtitle: 'Publie un trajet en quelques clics',
        title: 'Créer un trajet',
      },
      {
        content: 'Gère facilement les réservations des passagers et choisis avec qui tu voyages',
        imageSrc: step3ImageSrc,
        subtitle: 'Accepte ou refuse tes réservations',
        title: 'Gère tes réservations',
      },
      {
        content:
          "Indique le départ en un clic. Tous tes passagers recevront une notification! Fais de même à l'arrivée",
        imageSrc: step4ImageSrc,
        subtitle: "Valide le départ et l'arrivée, c'est important!",
        title: 'Départ & Arrivée',
      },
      {
        children: step6Children,
        subtitle: (
          <span>
            Tu dois être seul et reconnaissable. Retire tes lunettes, casquette et tout autre
            accessoire!
            <br />
            1, 2, 3... Ouistiti!
          </span>
        ),
        title: 'Un petit selfie',
      },
      {
        content: <Step5Content />,
        onClick: handleOnboardingEnd,
        subtitle: 'Active la géolocalisation sur ton téléphone',
        title: 'Dernière étape',
      },
    ],
    [handleOnboardingEnd, step6Children]
  );

  return (
    <FileProvider>
      <Onboarding
        disableClickIndicator
        forceHideOnboarding={user.selfie}
        forceShowOnboarding={!user.selfie}
        fullHeight
        name="welcome-v2"
        notSkipable
        steps={STEPS}
      />
    </FileProvider>
  );
}

export default WelcomeOnboarding;
