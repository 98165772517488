import { Box, hexToRgb, useTheme } from '@mui/material';
import React from 'react';

function FadedBorder({ bottom, color, height, left, right, sx, to, top, width }) {
  const theme = useTheme();
  const fadedColor = color || theme.palette.background.default;

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to ${to}, rgba(255,255,255,0), ${hexToRgb(
          fadedColor
        )} 80%)`,
        bottom,
        height,
        left,
        position: 'absolute',
        right,
        top,
        width,
        ...(sx && sx),
      }}
    />
  );
}

export default FadedBorder;
