import { AvatarGroup, Badge } from '@mui/material';
import React from 'react';
import UserAvatar from 'src/components/users/UserAvatar';
import JOURNEY_STATUSES from 'src/constants/journeyStatuses';

function RequestedJourneysGroup({ journeys }) {
  return (
    <AvatarGroup>
      {journeys.map((journey) => (
        <Badge
          key={journey.id}
          color="primary"
          invisible={journey.status !== JOURNEY_STATUSES.REQUESTED}
          variant="dot"
        >
          <UserAvatar size="xs" user={journey.passenger} />
        </Badge>
      ))}
    </AvatarGroup>
  );
}

export default RequestedJourneysGroup;
