import { gql } from '@apollo/client';

const cardDataFragment = gql`
  fragment CardData on CardData {
    Id
    CreationDate
    UserId
    Alias
    CardType
    CardProvider
    Country
    Product
    BankCode
    Active
    Currency
    Validity
    ExpirationDate
    Fingerprint
    CardHolderName
  }
`;

export default cardDataFragment;
