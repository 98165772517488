import { CircularProgress, Stack, useTheme } from '@mui/material';
import React from 'react';

function AndroidHandler({ indicatorVisible, opacity, refreshing }) {
  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      spacing={1}
      sx={{
        left: 0,
        opacity: refreshing || indicatorVisible ? 1 : opacity,
        p: 1,
        position: 'fixed',
        pt: 0,
        top: opacity * 60,
        transition: 'all 0.5s ease',
        width: '100%',
        zIndex: (th) => (opacity > 0 || refreshing ? th.zIndex.drawer + 9 : -50),
      }}
    >
      <Stack
        p={0.5}
        sx={{
          backgroundColor: theme.palette.background.default,
          borderColor: theme.palette.divider,
          borderRadius: '100%',
          borderStyle: 'solid',
          borderWidth: 1,
          boxShadow: theme.shadows[6],
        }}
      >
        <CircularProgress
          color={opacity >= 1 ? 'primary' : 'inherit'}
          size={20}
          value={opacity * 100}
          variant={refreshing ? 'indeterminate' : 'determinate'}
        />
      </Stack>
    </Stack>
  );
}

export default AndroidHandler;
