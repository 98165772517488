import { gql } from '@apollo/client';

import { addressFragment, alertFragment } from '../fragments';

const GET_MY_ALERTS = gql`
  query getMyAlerts {
    getMyAlerts {
      ...Alert
      startAddress {
        ...Address
      }
      endAddress {
        ...Address
      }
    }
  }
  ${addressFragment}
  ${alertFragment}
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_MY_ALERTS };
