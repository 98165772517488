import React, { createContext, useContext, useMemo } from 'react';
import expectedErrors from 'src/graphql/journey/expectedErrors';
import {
  APPROVE_PASSENGER,
  BOOK_JOURNEY,
  CANCEL_JOURNEY,
  DECLINE_PASSENGER,
  DROP_JOURNEY,
} from 'src/graphql/journey/mutations';
import {
  CONFIRM_DEPOSIT,
  CONFIRM_TAKING_CHARGE,
  DECLINE_TAKING_CHARGE,
  GET_MY_PENDING_JOURNEYS_AS_CONDUCTOR,
} from 'src/graphql/journey/queries';
import expectedTripErrors from 'src/graphql/trip/expectedErrors';
import { GET_MY_COMING_TRIPS } from 'src/graphql/trip/queries';
import { useSafeMutation } from 'src/services/apollo-client/wrappers';

const JourneyContext = createContext();

const useJourney = () => useContext(JourneyContext);

const refetchQueriesAfterJourneyUpdate = [
  {
    fetchPolicy: 'network-only',
    query: GET_MY_PENDING_JOURNEYS_AS_CONDUCTOR,
  },
  {
    fetchPolicy: 'network-only',
    query: GET_MY_COMING_TRIPS,
  },
  'getTripById',
  'getTrips',
  'getNearestTripsFromMe',
  'me',
];

function JourneyProvider({ children }) {
  const [bookJourney, bookJourneyState] = useSafeMutation(
    BOOK_JOURNEY,
    {
      displaySnackbar: false,
      refetchQueries: refetchQueriesAfterJourneyUpdate,
    },
    {
      expectedErrors: [
        expectedErrors.JOURNEY_ALREADY_CREATED_WITH_SAME_START_DATE_TIME,
        expectedErrors.TRIP_ALREADY_CREATED_WITH_SAME_START_DATE_TIME,
        expectedErrors.TRIP_FULL,
      ],
    }
  );

  const [cancelJourney, cancelJourneyState] = useSafeMutation(CANCEL_JOURNEY, {
    displaySnackbar: false,
    refetchQueries: [
      ...refetchQueriesAfterJourneyUpdate,
      'getConversationsByTripId',
      'getMyTripsWithUnreadConversations',
    ],
  });

  const [approvePassenger, approvePassengerState] = useSafeMutation(
    APPROVE_PASSENGER,
    {
      displaySnackbar: false,
      refetchQueries: [...refetchQueriesAfterJourneyUpdate, 'getMyTripsWithUnreadConversations'],
    },
    {
      expectedErrors: [expectedErrors.TRIP_FULL],
    }
  );

  const [declinePassenger, declinePassengerState] = useSafeMutation(DECLINE_PASSENGER, {
    displaySnackbar: false,
    refetchQueries: refetchQueriesAfterJourneyUpdate,
  });

  const [confirmTakingCharge, confirmTakingChargeState] = useSafeMutation(
    CONFIRM_TAKING_CHARGE,
    {
      displaySnackbar: false,
    },
    {
      expectedErrors: [
        expectedTripErrors.TRIP_NOT_STARTED,
        expectedTripErrors.TRIP_OUTDATED,
        expectedTripErrors.TRIP_ALREADY_FINISHED,
      ],
    }
  );

  const [declineTakingCharge, declineTakingChargeState] = useSafeMutation(
    DECLINE_TAKING_CHARGE,
    {
      displaySnackbar: false,
    },
    {
      expectedErrors: [
        expectedTripErrors.TRIP_NOT_STARTED,
        expectedTripErrors.TRIP_ALREADY_FINISHED,
      ],
    }
  );

  const [confirmDeposit, confirmDepositState] = useSafeMutation(
    CONFIRM_DEPOSIT,
    {
      displaySnackbar: false,
      refetchQueries: ['getTripById'],
    },
    {
      expectedErrors: [
        expectedTripErrors.TRIP_ALREADY_FINISHED,
        expectedTripErrors.TRIP_NOT_STARTED,
        expectedTripErrors.TRIP_ALREADY_STARTED,
      ],
    }
  );

  const [dropJourney, dropJourneyState] = useSafeMutation(
    DROP_JOURNEY,
    {
      displaySnackbar: false,
    },
    {
      expectedErrors: [
        expectedTripErrors.TRIP_NOT_STARTED,
        expectedTripErrors.TRIP_ALREADY_FINISHED,
      ],
    }
  );

  const memoizedContextValue = useMemo(
    () => ({
      approvePassenger,
      approvePassengerState,
      bookJourney,
      bookJourneyState,
      cancelJourney,
      cancelJourneyState,
      confirmDeposit,
      confirmDepositState,
      confirmTakingCharge,
      confirmTakingChargeState,
      declinePassenger,
      declinePassengerState,
      declineTakingCharge,
      declineTakingChargeState,
      dropJourney,
      dropJourneyState,
    }),
    [
      approvePassenger,
      approvePassengerState,
      bookJourney,
      bookJourneyState,
      cancelJourney,
      cancelJourneyState,
      confirmDeposit,
      confirmDepositState,
      confirmTakingCharge,
      confirmTakingChargeState,
      declinePassenger,
      declinePassengerState,
      declineTakingCharge,
      declineTakingChargeState,
      dropJourney,
      dropJourneyState,
    ]
  );

  return <JourneyContext.Provider value={memoizedContextValue}>{children}</JourneyContext.Provider>;
}

export { JourneyProvider, useJourney };
