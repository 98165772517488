export default function Paper(theme) {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          // border: `1px solid ${theme.palette.primary.main}`,
          boxShadow: theme.shadows[4],
        },
      },
    },
  };
}
