import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router';

import menuItems from './menuItems';
import NavigationItems from './NavigationItems';

function HeaderContent({ onClose }) {
  const [selectedItem, selectItem] = useState();
  const location = useLocation();

  useEffect(() => {
    selectItem(
      menuItems.find((item) =>
        matchPath(
          {
            exact: true,
            path: item.url,
            strict: true,
          },
          location.pathname
        )
      )
    );
  }, [location]);

  return (
    <Stack alignItems="center" direction={{ md: 'row', xs: 'column' }} spacing={{ md: 1, xs: 2 }}>
      <NavigationItems onClose={onClose} selectedItem={selectedItem} />
    </Stack>
  );
}

export default HeaderContent;
