// third-party
import { presetDarkPalettes, presetPalettes } from '@ant-design/colors';
// material-ui
import { alpha, createTheme, darken, lighten } from '@mui/material/styles';

import { ThemeMode } from '../config';
// project import
import ThemeOption from './theme';

const LINKEDIN_COLOR_PRIMARY = '#0E76A8';

const LINKEDIN_COLORS = {
  dark: darken(LINKEDIN_COLOR_PRIMARY, 0.1),
  darker: darken(LINKEDIN_COLOR_PRIMARY, 0.3),
  light: lighten(LINKEDIN_COLOR_PRIMARY, 0.2),
  lighter: lighten(LINKEDIN_COLOR_PRIMARY, 0.4),
  main: LINKEDIN_COLOR_PRIMARY,
};

const Palette = (mode, presetColor) => {
  const colors = mode === ThemeMode.DARK ? presetDarkPalettes : presetPalettes;

  let greyPrimary = [
    '#ffffff',
    '#fafafa',
    '#f5f5f5',
    '#f0f0f0',
    '#d9d9d9',
    '#bfbfbf',
    '#8c8c8c',
    '#595959',
    '#262626',
    '#141414',
    '#000000',
  ];
  let greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
  let greyConstant = ['#fafafb', '#e6ebf1'];

  if (mode === ThemeMode.DARK) {
    greyPrimary = [
      '#000000',
      '#141414',
      '#1e1e1e',
      '#595959',
      '#8c8c8c',
      '#bfbfbf',
      '#d9d9d9',
      '#f0f0f0',
      '#f5f5f5',
      '#fafafa',
      '#ffffff',
    ];
    // greyPrimary.reverse();
    greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
    greyConstant = ['#121212', '#d3d8db'];
  }
  colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

  const paletteColor = ThemeOption(colors, presetColor, mode);

  return createTheme({
    palette: {
      common: {
        black: '#000',
        white: '#fff',
      },
      linkedin: LINKEDIN_COLORS,
      mode,
      ...paletteColor,
      action: {
        disabled: paletteColor.grey[500],
      },
      background: {
        default: paletteColor.grey.A50,
        paper: mode === ThemeMode.DARK ? paletteColor.grey[100] : paletteColor.grey[0],
      },
      divider:
        mode === ThemeMode.DARK ? alpha(paletteColor.grey[900], 0.05) : paletteColor.grey[300],
      text: {
        disabled:
          mode === ThemeMode.DARK ? alpha(paletteColor.grey[900], 0.1) : paletteColor.grey[500],
        primary:
          mode === ThemeMode.DARK ? alpha(paletteColor.grey[900], 0.87) : paletteColor.grey[800],
        secondary:
          mode === ThemeMode.DARK ? alpha(paletteColor.grey[900], 0.45) : paletteColor.grey[600],
      },
    },
  });
};

export default Palette;
