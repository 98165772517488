import { gql } from '@apollo/client';

const DELETE_ME = gql`
  mutation deleteMe {
    deleteMe
  }
`;

const CONTACT_US = gql`
  mutation contactUs($input: ContactUsInput!) {
    contactUs(input: $input)
  }
`;

const ATTACH_PUSH_TOKEN = gql`
  mutation attachPushToken($pushToken: String) {
    attachPushToken(pushToken: $pushToken)
  }
`;

export { DELETE_ME, CONTACT_US, ATTACH_PUSH_TOKEN };
