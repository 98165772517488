import { InfoOutlined } from '@mui/icons-material';
import { Button, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

import MobileSheet from './MobileSheet';
import PageWrapper from './PageWrapper';

function AbortTripOrJourneyConditions({ isConductor }) {
  const [sheetOpen, setSheetOpen] = useState(false);

  return (
    <>
      <Button
        color="secondary"
        onClick={() => setSheetOpen(true)}
        size="small"
        startIcon={<InfoOutlined />}
      >
        Consulter les politiques d&apos;annulation
      </Button>
      <MobileSheet
        onClose={() => setSheetOpen(false)}
        open={sheetOpen}
        sx={{ zIndex: (th) => th.zIndex.modal + 9999 }}
      >
        <PageWrapper
          subtitle="Toute annulation est soumise aux règles suivantes"
          title="Conditions d'annulation"
          withoutLogo
        >
          {isConductor && (
            <Stack spacing={2}>
              <Stack alignItems="flex-start" spacing={1}>
                <Typography variant="h5">Si tu annules ton trajet</Typography>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="A tout moment"
                      secondary="Tous tes passagers seront remboursés intégralement de leur participation aux frais"
                    />
                  </ListItem>
                </List>
              </Stack>
              <Stack alignItems="flex-start" spacing={1}>
                <Typography variant="h5">Si un passager annule sa réservation*</Typography>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Plus de 48h avant le départ"
                      secondary="Ton passager sera remboursé intégralement de sa participation aux frais"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Entre 1h et 48h avant le départ"
                      secondary="Ton passager sera remboursé de 70% de sa participation aux frais"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Moins de 1h avant le départ"
                      secondary="Ton passager ne sera pas remboursé"
                    />
                  </ListItem>
                </List>
              </Stack>
            </Stack>
          )}
          {!isConductor && (
            <Stack spacing={2}>
              <Stack alignItems="flex-start" spacing={1}>
                <Typography variant="h5">Si tu annules ta réservation*</Typography>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Plus de 48h avant le départ"
                      secondary="Tu seras remboursé intégralement de ta participation aux frais"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Entre 1h et 48h avant le départ"
                      secondary="Tu seras remboursé de 70% de ta participation aux frais"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Moins de 1h avant le départ"
                      secondary="Tu ne seras pas remboursé"
                    />
                  </ListItem>
                </List>
              </Stack>
              <Stack alignItems="flex-start" spacing={1}>
                <Typography variant="h5">Si le conducteur annule le trajet</Typography>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="A tout moment"
                      secondary="Tu seras remboursé intégralement de ta participation aux frais"
                    />
                  </ListItem>
                </List>
              </Stack>
            </Stack>
          )}
          <Typography
            color="text.secondary"
            sx={{ fontSize: 10, fontStyle: 'italic' }}
            variant="caption"
          >
            Réservation*: Au sens d&apos;une réservation validé par le conducteur.
          </Typography>
        </PageWrapper>
      </MobileSheet>
    </>
  );
}

export default AbortTripOrJourneyConditions;
