const APP_ROOT_PATH = '/app/';
const ADMIN_ROOT_PATH = '/admin/';
const LANDING_ROOT_PATH = '/';
const appPath = (...args) => `${APP_ROOT_PATH}${args.join('/')}`;
const adminPath = (...args) => `${ADMIN_ROOT_PATH}${args.join('/')}`;
const landingPath = (...args) => `${LANDING_ROOT_PATH}${args.join('/')}`;

const PATH_AUTH = {
  adminLogin: adminPath('login'),
  login: appPath('login'),
  register: appPath('register'),
};

const PATH_USER = {
  alerts: appPath('alerts'),
  home: APP_ROOT_PATH,
  me: {
    bonuses: appPath('me', 'bonuses'),
    profile: appPath('me', 'profile'),
    root: appPath('me'),
    sponsor: appPath('me', 'sponsor'),
    verify: appPath('me', 'verify'),
    wallet: appPath('me', 'wallet'),
  },
  trips: {
    conversation: (id, conversationId) => appPath('trips', id, 'conversations', conversationId),
    create: appPath('trips', 'create'),
    detail: (id) => appPath('trips', id),
    historic: appPath('trips', 'historic'),
    search: appPath('?search=true'),
    searchResults: appPath('search', 'results'),
  },
  welcome: appPath('welcome'),
};

const PATH_ADMIN = {
  home: ADMIN_ROOT_PATH,
  partners: adminPath('partners'),
  rewards: adminPath('rewards'),
  trips: {
    detail: (id) => adminPath('trips', id),
    list: adminPath('trips'),
  },
  users: {
    detail: (id) => adminPath('users', id),
    list: adminPath('users'),
  },
};

const PATH_LANDING = {
  about: landingPath('a-propos'),
  blog: {
    article: (slug) => landingPath('blog', slug),
    root: landingPath('blog'),
  },
  bonuses: landingPath('la-prime-coup-de-pouce'),
  cgu: landingPath('conditions-generales-d-utilisation'),
  contact: landingPath('nous-contacter'),
  downloadApp: landingPath('telecharger-coccinelle'),
  privacyPolicy: landingPath('politique-de-confidentialite'),
  root: LANDING_ROOT_PATH,
  sponsoring: landingPath('parrainage'),
};

export {
  PATH_AUTH,
  PATH_USER,
  APP_ROOT_PATH,
  PATH_LANDING,
  LANDING_ROOT_PATH,
  PATH_ADMIN,
  ADMIN_ROOT_PATH,
};
