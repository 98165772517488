import React from 'react';

import GenericDialog from './GenericDialog';
import MODAL_TYPES from './types';

function AlertDialog({ confirmLabel, message, onConfirm, open, title, ...props }) {
  return (
    <GenericDialog
      confirmLabel={confirmLabel}
      dialogType={MODAL_TYPES.ALERT}
      message={message}
      onConfirm={onConfirm}
      open={open}
      title={title}
      {...props}
    />
  );
}

export default AlertDialog;
