import {
  AccountBoxOutlined,
  BeenhereOutlined,
  BoltOutlined,
  HomeOutlined,
  UpcomingOutlined,
} from '@mui/icons-material';
import { PATH_USER } from 'src/routes/paths';

import USER_ROLES from '../constants/userRoles';

const icons = {
  AccountBoxOutlined,
  BeenhereOutlined,
  BoltOutlined,
  HomeOutlined,
  UpcomingOutlined,
};

const userMenu = {
  children: [
    {
      icon: icons.HomeOutlined,
      id: 'trips',
      title: 'Trajets',
      type: 'item',
      url: PATH_USER.home,
    },
    {
      icon: icons.BoltOutlined,
      id: 'alerts',
      title: 'Alertes',
      type: 'item',
      url: PATH_USER.alerts,
    },
    {
      icon: icons.BeenhereOutlined,
      id: 'settings',
      title: 'Historique',
      type: 'item',
      url: PATH_USER.trips.historic,
    },
    {
      icon: icons.AccountBoxOutlined,
      id: 'profile',
      title: 'Profil',
      type: 'item',
      url: PATH_USER.me.root,
    },
  ],
  id: 'user-basic-menu',
  roles: [USER_ROLES.BASIC, USER_ROLES.TESTER],
  title: 'Menu',
  type: 'group',
};

export default userMenu;
