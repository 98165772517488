export default function ListItemIcon(theme) {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(4),
          minWidth: 24,
        },
      },
    },
  };
}
