import LinearProgress from '@mui/material/LinearProgress';
import React, { styled } from '@mui/material/styles';

const LoaderWrapper = styled('div')(({ theme }) => ({
  '& > * + *': {
    marginTop: theme.spacing(2),
  },
  left: 0,
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 2001,
}));

function Loader() {
  return (
    <LoaderWrapper>
      <LinearProgress color="primary" sx={{ borderRadius: 0 }} />
    </LoaderWrapper>
  );
}

export default Loader;
