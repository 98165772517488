import { FormHelperText } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import useAuth from 'src/contexts/auth/useAuth';
import authExpectedErrors from 'src/graphql/auth/expectedErrors';
import safeCallback from 'src/utils/safeCallback';

import commonExpectedErrors from '../graphql/common/expectedErrors';
import useLocales from '../hooks/useLocales';
import getSafeArray from '../utils/getSafeArray';

function FormErrors({
  error: apolloError,
  expectedErrors,
  withChallenge,
  withScrollToTop,
  withSnackbar,
}) {
  const { isAuthenticated, logout } = useAuth();
  const { translate } = useLocales();
  const [lastError, setLastError] = useState(false);
  const [error, setError] = useState(null);
  const [errorHandled, setErrorHandled] = useState(false);
  const defaultErrorMessage = translate('commons.somethingWentWrong');
  const defaultNetworkErrorMessage =
    'Impossible de contacter le serveur, vérifie ta connexion internet';
  const [errorMessage, setErrorMessage] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!error) setLastError(null);
  }, [error]);

  useEffect(() => {
    if (apolloError) {
      setError(apolloError);
    } else {
      setError(null);
    }
  }, [apolloError]);

  useEffect(() => {
    if (error && !errorHandled) {
      const { graphQLErrors, networkError } = error;
      let localErrorHandled;
      if (networkError) {
        let networkErrorHandled;
        networkError.result?.errors?.forEach(({ extensions, message }) => {
          localErrorHandled = true;
          if (extensions?.code === 'UNAUTHENTICATED') {
            networkErrorHandled = true;
            if (isAuthenticated) {
              safeCallback(logout);
              enqueueSnackbar('Tu as été déconnecté', { variant: 'error' });
              window.location.reload();
            }
          }
          setErrorMessage(message);
        });

        if (networkError.statusCode > 299 && !networkErrorHandled) {
          // Log the error into datadog
        }

        /** the networkError.result?.errors?.forEach loop should be done here */
        // handle the critical "Request header too large" error even if silentError is true
        if (networkError.result?.error?.code === 494) {
          localErrorHandled = true;
          //
        }
        if (!localErrorHandled) {
          localErrorHandled = true;
          // extract errorMessage in custom NGINX responses if applicable
          setErrorMessage(networkError.result?.error?.message || defaultNetworkErrorMessage);
        }
      }
      if (graphQLErrors) {
        if (withChallenge) {
          const challengeError = graphQLErrors.find(
            ({ extensions }) => extensions?.code === commonExpectedErrors.CHALLENGE_REQUIRED.code
          );
          if (challengeError) {
            localErrorHandled = true;
            const challengeType = challengeError?.extensions?.challengeType;
            setErrorMessage(commonExpectedErrors.CHALLENGE_REQUIRED.message({ challengeType }));
            return;
          }
          const noChallengeAssociatedError = graphQLErrors.find(
            ({ extensions }) =>
              extensions?.code === commonExpectedErrors.NO_CHALLENGE_ASSOCIATED.code
          );
          if (noChallengeAssociatedError) {
            localErrorHandled = true;
            setErrorMessage(commonExpectedErrors.NO_CHALLENGE_ASSOCIATED.message);
            return;
          }
        }
        graphQLErrors.forEach(({ extensions }) => {
          if (extensions?.code === authExpectedErrors.INVALID_DEVICE.code) {
            enqueueSnackbar(authExpectedErrors.INVALID_DEVICE.message, { variant: 'error' });
            logout();
            return;
          }

          const safeExpectedErrors = getSafeArray(expectedErrors);
          const expectedError = safeExpectedErrors.find((e) => e.code === extensions?.code);
          localErrorHandled = true;
          setErrorMessage(expectedError?.message || defaultErrorMessage);
          if (withScrollToTop) window.scrollTo({ behavior: 'smooth', top: 0 });
          if (expectedError?.on && expectedError?.code !== lastError?.code) {
            setLastError(expectedError);
            expectedError.on();
          }
        });
      }
      setErrorHandled(localErrorHandled);
    }
  }, [
    expectedErrors,
    error,
    defaultErrorMessage,
    withChallenge,
    withScrollToTop,
    lastError,
    isAuthenticated,
    logout,
    enqueueSnackbar,
    errorHandled,
  ]);

  useEffect(() => {
    if (error && errorMessage && withSnackbar) {
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  }, [enqueueSnackbar, error, errorMessage, withSnackbar]);

  if (error && !withSnackbar) return <FormHelperText error>{errorMessage}</FormHelperText>;

  return null;
}

FormErrors.defaultProps = {
  expectedErrors: [],
};

export default FormErrors;
