import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
} from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// eslint-disable-next-line react/display-name
const CheckboxWithError = forwardRef(({ error, onError, ...props }, ref) => {
  useEffect(() => {
    onError(error);
  }, [error, onError]);

  return <Checkbox ref={ref} {...props} />;
});

function RHFCheckbox({ helperText, name, ...other }) {
  const { control } = useFormContext();
  const [formError, setFormError] = useState();

  return (
    <FormControl>
      <FormControlLabel
        control={
          <Controller
            control={control}
            name={name}
            render={({ field, fieldState: { error } }) => (
              <CheckboxWithError
                checked={field.value}
                error={error}
                onError={setFormError}
                {...field}
              />
            )}
          />
        }
        {...other}
      />
      <FormHelperText error={!!formError}>{formError?.message || helperText}</FormHelperText>
    </FormControl>
  );
}

function RHFMultiCheckbox({ name, options, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const onSelected = (option) =>
          field.value.includes(option)
            ? field.value.filter((value) => value !== option)
            : [...field.value, option];

        return (
          <FormGroup>
            <Grid container>
              {options.map((option) => (
                <Grid key={option.value} item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value.includes(option.value)}
                        onChange={() => field.onChange(onSelected(option.value))}
                      />
                    }
                    label={option.label}
                    {...other}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        );
      }}
    />
  );
}

export { RHFCheckbox, RHFMultiCheckbox };
