import { gql } from '@apollo/client';

const externalBankDetailsFragment = gql`
  fragment ExternalBankDetails on ExternalBankDetails {
    id
    userId
    fileId
    iban
    bic
    createdAt
    updatedAt
  }
`;

export default externalBankDetailsFragment;
