import { useEffect } from 'react';
import { useLocation } from 'react-router';

import bindExternalLinks from './utils/bindExternalLinks';

function ExternalLinksBinder() {
  const location = useLocation();

  useEffect(() => {
    const observer = new MutationObserver(bindExternalLinks);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    bindExternalLinks();

    return () => observer.disconnect();
  }, [location]);

  return null;
}

export default ExternalLinksBinder;
