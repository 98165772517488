import { Box, Divider, Link, List, Stack } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_LANDING } from 'src/routes/paths';

import FAQItem from './FAQItem';

const QUESTIONS_AND_ANSWERS = [
  {
    answer:
      'Pour vérifier que tu es éligible à la prime covoiturage, il te suffit de te connecter sur ta plateforme et d’y ajouter ton permis de conduire. Tu sauras immédiatement si tu es éligible à la prime. Il ne te reste plus qu’à proposer un trajet.',
    question: 'Suis-je éligible à la prime?',
  },
  {
    answer:
      'Ces déplacements sont éligibles à la prime mais il faut absolument réserver via la plateforme pour que tes trajets soient récompensés.',
    question: 'Covoiturer entre amis ou en famille permet-il de toucher la prime?',
  },
  {
    answer:
      'Il est possible que tu n’aies pas validé toutes les étapes durant ton trajet : confirmation de la prise en charge et de l’arrivée. Une irrégularité a aussi pu être détectée sur ton trajet, prends contact avec nous!',
    question: "Mon trajet n'a pas été validé?",
  },
  {
    answer:
      'Il te suffit de partager ton code de parrainage à ton filleul. Dès qu’il aura rentré ce code et validé son premier trajet éligible à la prime, tu seras crédité de ta prime parrainage.',
    question: 'Comment fonctionne le parrainage?',
  },
];

function FAQ() {
  return (
    <Stack alignItems="center" spacing={2}>
      <Stack>
        <List>
          {QUESTIONS_AND_ANSWERS.map(({ answer, question }, index) => (
            <Box key={question}>
              <FAQItem answer={answer} question={question} />
              {index < QUESTIONS_AND_ANSWERS.length - 1 && <Divider />}
            </Box>
          ))}
        </List>
        <Link align="center" component={RouterLink} to={PATH_LANDING.contact} variant="caption">
          T&apos;as une autre question?
          <br />
          Contacte-nous
        </Link>
      </Stack>
    </Stack>
  );
}

export default FAQ;
