import { gql } from '@apollo/client';

const userFragment = gql`
  fragment User on User {
    id
    firstname
    lastname
    email
    isUserPhoneVerified
    isUserEmailVerified
    birthdate
    phone
    role
    sponsorCode
    isOtpVerificationRequired
    lastLatitude
    lastLongitude
    lastIp
    lastIpChangedAt
    lastPositionUpdatedAt
    lastOtpCodeGeneratedAt
    lastEmailVerificationCodeGeneratedAt
    createdAt
    updatedAt
    avatar
    selfie
    kmDriven
    co2Saved
    availableBalance
    realBalance
    hasUploadedSelfie
    nationality
    pushToken
    hasBeenActiveAtLeastOnce
    hasUploadedDrivingLicense
  }
`;

export default userFragment;
