import messages from './messages';
import useMessages from './useMessages';
import useWebView from './useWebView';

function NativeFeatures() {
  useMessages(messages);
  useWebView();

  return null;
}

export default NativeFeatures;
