import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { getDistanceFromLatLonInMeter } from 'src/utils/getDistanceFromLatLngInKm';

function TripProgress({ trip }) {
  const [distanceValue, setDistanceValue] = useState(0);

  const handleRefreshPosition = useCallback(
    async ({ coords }) => {
      const distanceHereToEnd = getDistanceFromLatLonInMeter(
        { lat: coords.latitude, lng: coords.longitude },
        trip.endAddress
      );

      setDistanceValue(
        ((trip.estimatedDistanceInMeters - distanceHereToEnd) / trip.estimatedDistanceInMeters) *
          100
      );
    },
    [trip.endAddress, trip.estimatedDistanceInMeters]
  );

  useEffect(() => {
    let watcherId = null;
    if ('geolocation' in navigator) {
      watcherId = navigator.geolocation.watchPosition(handleRefreshPosition, () => null, {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: Infinity,
      });
    }

    return () => {
      if (watcherId) navigator.geolocation.clearWatch(watcherId);
    };
  }, [handleRefreshPosition]);

  return (
    <Box py={3}>
      <Stack spacing={4}>
        <Stack>
          <Stack direction="row" width="100%">
            <Box width={`${distanceValue}%`} />
            <Typography sx={{ mb: 1, transform: 'translateX(-50%) rotate(35deg)' }} variant="h3">
              🐞
            </Typography>
          </Stack>
          <LinearProgress value={distanceValue} variant="determinate" />
        </Stack>
      </Stack>
    </Box>
  );
}

export default TripProgress;
