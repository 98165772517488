import { gql } from '@apollo/client';

const messageFragment = gql`
  fragment Message on Message {
    id
    conversationId
    sentBy
    replyTo
    sentAt
    isRead
    readAt
    content
  }
`;

export default messageFragment;
