import React, { createContext, useContext, useMemo } from 'react';
import { GET_MY_TRIPS_WITH_UNREAD_CONVERSATIONS } from 'src/graphql/conversation/queries';
import { GET_MY_PENDING_JOURNEYS_AS_CONDUCTOR } from 'src/graphql/journey/queries';
import { useSafeLazyQuery } from 'src/services/apollo-client/wrappers';

const NotificationContext = createContext();

const useNotification = () => useContext(NotificationContext);

function NotificationProvider({ children }) {
  const [
    getMyPendingJourneysAsConductor,
    {
      data: myPendingJourneysAsConductor,
      refetch: refetchNotifications,
      ...getMyPendingJourneysAsConductorState
    },
  ] = useSafeLazyQuery(GET_MY_PENDING_JOURNEYS_AS_CONDUCTOR, {
    fetchPolicy: 'network-only',
  });

  const [
    getMyTripsWithUnreadConversations,
    { data: getMyTripsWithUnreadConversationsData, ...getMyTripsWithUnreadConversationsState },
  ] = useSafeLazyQuery(GET_MY_TRIPS_WITH_UNREAD_CONVERSATIONS, {
    fetchPolicy: 'network-only',
  });

  const memoizedContextValue = useMemo(
    () => ({
      getMyPendingJourneysAsConductor,
      getMyPendingJourneysAsConductorState,
      getMyTripsWithUnreadConversations,
      getMyTripsWithUnreadConversationsState,
      myPendingJourneysAsConductor: myPendingJourneysAsConductor?.getMyPendingJourneysAsConductor,
      refetchNotifications,
      tripsWithUnreadConversations:
        getMyTripsWithUnreadConversationsData?.getMyTripsWithUnreadConversations,
    }),
    [
      getMyPendingJourneysAsConductor,
      getMyPendingJourneysAsConductorState,
      getMyTripsWithUnreadConversations,
      getMyTripsWithUnreadConversationsData,
      getMyTripsWithUnreadConversationsState,
      myPendingJourneysAsConductor?.getMyPendingJourneysAsConductor,
      refetchNotifications,
    ]
  );

  return (
    <NotificationContext.Provider value={memoizedContextValue}>
      {children}
    </NotificationContext.Provider>
  );
}

export { NotificationProvider, useNotification };
