import { Button, Link, Stack } from '@mui/material';
import React, { useState } from 'react';
import CONTACT_SUBJECTS from 'src/constants/contactSubjects';
import useAuth from 'src/contexts/auth/useAuth';
import ContactForm from 'src/sections/landing/contact/ContactForm';

import MobileSheet from './MobileSheet';
import PageWrapper from './PageWrapper';

function HelpLink({ additionalInfos, defaultSubject, isButton, label }) {
  const [contactFormOpen, setContactFormOpen] = useState(false);
  const { user } = useAuth();

  const finalSubject = defaultSubject || CONTACT_SUBJECTS.PROBLEM_ENCOUNTERED;

  return (
    <>
      {isButton ? (
        <Button onClick={() => setContactFormOpen(true)} size="small" variant="outlined">
          {label}
        </Button>
      ) : (
        <Stack alignItems="center">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            align="center"
            component={Button}
            onClick={() => setContactFormOpen(true)}
            variant="caption"
          >
            {label}
          </Link>
        </Stack>
      )}

      <MobileSheet
        additionalInfos={additionalInfos}
        height="auto"
        onClose={() => setContactFormOpen(false)}
        open={contactFormOpen}
      >
        <PageWrapper
          goBackTo={() => setContactFormOpen(false)}
          subtitle="Tu rencontres un problèmes? Ou tu as simplement une question?"
          title="Envoie nous un message"
          withCloseIcon
          withoutLogo
        >
          <ContactForm
            additionalInfos={additionalInfos}
            defaultEmail={user.email}
            defaultFullName={`${user.firstname} ${user.lastname}`}
            defaultSubject={finalSubject}
            onSent={() => setContactFormOpen(false)}
          />
        </PageWrapper>
      </MobileSheet>
    </>
  );
}

HelpLink.defaultProps = {
  additionalInfos: null,
  isButton: false,
  label: "Besoin d'assistance? Contacte-nous",
};

export default HelpLink;
