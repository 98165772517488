import React, { lazy } from 'react';
import Loadable from 'src/components/Loadable';
import CommonLayout from 'src/layout/CommonLayout';
import GuestGuard from 'src/utils/route-guard/GuestGuard';

const AdminLogin = Loadable(lazy(() => import('src/pages/administration/Login')));

const AuthRoutes = {
  children: [
    {
      element: <AdminLogin />,
      path: 'admin',
    },
  ],
  element: (
    <GuestGuard>
      <CommonLayout />
    </GuestGuard>
  ),
  path: '',
};

export default AuthRoutes;
