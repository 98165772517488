import { gql } from '@apollo/client';

import { fieldPredictionFragment, fileFragment } from '../fragments';

const CREATE_FILE = gql`
  mutation createFile($input: CreateFileInput!) {
    createFile(input: $input) {
      ...File
    }
  }
  ${fileFragment}
`;

const UPLOAD_SELFIE = gql`
  mutation uploadSelfie($input: CreateSelfieInput!) {
    uploadSelfie(input: $input)
  }
`;

const UPLOAD_AVATAR = gql`
  mutation uploadAvatar($file: Upload!) {
    uploadAvatar(file: $file)
  }
`;

const UPLOAD_SWORN_STATEMENT = gql`
  mutation uploadSwornStatement($input: CreateSwornStatementInput!) {
    uploadSwornStatement(input: $input) {
      ...File
    }
  }
  ${fileFragment}
`;

const PREDICT_DRIVING_LICENSE = gql`
  mutation predictDrivingLicense($input: DrivingLicenceInput!) {
    predictDrivingLicense(input: $input) {
      fieldPredictions {
        ...FieldPrediction
      }
    }
  }
  ${fieldPredictionFragment}
`;

const ASSERT_DRIVING_LICENSE = gql`
  mutation assertDrivingLicense($input: AssertDrivingLicenceInput!) {
    assertDrivingLicense(input: $input)
  }
`;

const UPLOAD_KYC_DOCUMENT = gql`
  mutation uploadKycDocument($input: CreateKycDocumentInput!) {
    uploadKycDocument(input: $input) {
      ...File
    }
  }
  ${fileFragment}
`;

const PREDICT_BANK_STATEMENT = gql`
  mutation predictBankStatement($input: CreateFileInput!) {
    predictBankStatement(input: $input) {
      fieldPredictions {
        ...FieldPrediction
      }
    }
  }
  ${fieldPredictionFragment}
`;

export {
  CREATE_FILE,
  UPLOAD_SELFIE,
  PREDICT_DRIVING_LICENSE,
  ASSERT_DRIVING_LICENSE,
  UPLOAD_KYC_DOCUMENT,
  PREDICT_BANK_STATEMENT,
  UPLOAD_SWORN_STATEMENT,
  UPLOAD_AVATAR,
};
