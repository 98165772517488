import { Box, hexToRgb, useTheme } from '@mui/material';
import React from 'react';
import useResponsive from 'src/hooks/useResponsive';

const DEFAULT_HEIGHT = 200;

function MapFadedContainer({ children, height }) {
  const theme = useTheme();
  const notMobile = useResponsive('up', 'md');

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        height,
        left: 0,
        overflow: 'hidden',
        position: 'relative',
        transform: 'scale(1.2)',
        width: '100%',
        zIndex: -1,
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        {children}
      </Box>
      <Box
        sx={{
          backgroundImage: `linear-gradient(to top, rgba(255,255,255,0), ${hexToRgb(
            theme.palette.background.default
          )} 80%)`,
          height: height / 4,
          left: 0,
          position: 'absolute',
          top: -2,
          width: '100%',
        }}
      />
      <Box
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0), ${hexToRgb(
            theme.palette.background.default
          )} 80%)`,
          bottom: -2,
          height: height / 4,
          left: 0,
          position: 'absolute',
          width: '100%',
        }}
      />

      {notMobile && (
        <>
          <Box
            sx={{
              backgroundImage: `linear-gradient(to left, rgba(255,255,255,0), ${hexToRgb(
                theme.palette.background.default
              )} 80%)`,
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: height / 4,
            }}
          />
          <Box
            sx={{
              backgroundImage: `linear-gradient(to right, rgba(255,255,255,0), ${hexToRgb(
                theme.palette.background.default
              )} 80%)`,
              height: '100%',
              position: 'absolute',
              right: 0,
              top: 0,
              width: height / 4,
            }}
          />
        </>
      )}
    </Box>
  );
}

MapFadedContainer.defaultProps = {
  height: DEFAULT_HEIGHT,
};

export default MapFadedContainer;
