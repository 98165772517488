import { gql } from '@apollo/client';

const conductorFragment = gql`
  fragment Conductor on Conductor {
    id
    firstname
    phone
    avatar
  }
`;

export default conductorFragment;
