import { Autocomplete, Chip, TextField } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import safeCallback from '../../utils/safeCallback';

function RHFAutocomplete({
  formatValue,
  inputProps,
  name,
  options,
  placeholder,
  required,
  ...other
}) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => (
        <Autocomplete
          error={!!error}
          onChange={(e, v) => onChange(safeCallback(formatValue, v))}
          options={options}
          renderInput={(params) => (
            <TextField
              placeholder={placeholder}
              {...inputProps}
              error={error}
              helperText={error?.message || inputProps.helperText}
              {...params}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip key={option} label={option} variant="outlined" {...getTagProps({ index })} />
            ))
          }
          required={required}
          {...other}
          {...field}
        />
      )}
    />
  );
}

RHFAutocomplete.defaultProps = {
  formatValue: (v) => v,
  options: [],
};

export default RHFAutocomplete;
