import { ArrowForwardOutlined } from '@mui/icons-material';
import React from 'react';
import { PATH_LANDING } from 'src/routes/paths';

const menuItems = [
  {
    label: 'Accueil',
    name: 'root',
    url: PATH_LANDING.root,
  },
  {
    label: 'Parrainage',
    name: 'sponsoring',
    url: PATH_LANDING.sponsoring,
  },
  {
    label: 'Nous contacter',
    name: 'contact',
    url: PATH_LANDING.contact,
  },
  {
    label: 'À propos',
    name: 'about',
    url: PATH_LANDING.about,
  },
  {
    label: 'Blog',
    name: 'blog',
    noFooter: true,
    url: PATH_LANDING.blog.root,
  },
  {
    icon: <ArrowForwardOutlined sx={{ fontSize: 16, ml: 1 }} />,
    label: 'Télécharger maintenant',
    name: 'application',
    noFooter: true,
    type: 'button',
    url: PATH_LANDING.downloadApp,
  },
];

export default menuItems;
