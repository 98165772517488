import { gql } from '@apollo/client';

import { journeyFragment, tripFragment } from '../fragments';

const TRIP_UPDATED = gql`
  subscription tripUpdated {
    tripUpdated {
      trip {
        ...Trip

        journeys {
          ...Journey

          passenger {
            id
            avatar
            firstname
            phone
          }
        }
      }
      type
    }
  }
  ${journeyFragment}
  ${tripFragment}
`;

// eslint-disable-next-line import/prefer-default-export
export { TRIP_UPDATED };
