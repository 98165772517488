import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import useLocales from '../../../hooks/useLocales';
import useQueryErrors from './useQueryErrors';

// eslint-disable-next-line default-param-last
const useSafeMutation = (query, queryOptions = {}, errorsOptions) => {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const {
    displaySnackbar = true,
    onCompleted: customOnCompleted,
    ...restQueryOptions
  } = queryOptions;

  const onCompleted = (data) => {
    if (customOnCompleted) {
      customOnCompleted(data);
    }
    if (displaySnackbar) {
      enqueueSnackbar(translate('commons.snackbarActionsSuccess'));
    }
  };

  const [mutation, { error, ...queryResult }] = useMutation(query, {
    ...restQueryOptions,
    onCompleted,
  });

  useQueryErrors(error, { silentError: false, ...errorsOptions });

  return [mutation, { error, ...queryResult }];
};

export default useSafeMutation;
