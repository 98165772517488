const expectedErrors = {
  BIRTHDATE_INVALID: {
    code: 'BIRTHDATE_INVALID',
    message: "La date de naissance ne correspond pas à celle renseignée à l'inscription",
  },
  CANNOT_EXTRACT_DRIVING_LICENSE_NUMBER: {
    code: 'CANNOT_EXTRACT_DRIVING_LICENSE_NUMBER',
    message: "Impossible d'extraire le numéro de permis",
  },
  DRIVING_LICENSE_ALREADY_EXISTS: {
    code: 'DRIVING_LICENSE_ALREADY_EXISTS',
    message: 'Ce permis de conduire est déjà utilisé par un membre',
  },
  DRIVING_LICENSE_NUMBER_NOT_FOUND: {
    code: 'DRIVING_LICENSE_NUMBER_NOT_FOUND',
    message: 'Impossible de lire le numéro de permis',
  },
  EXPIRATION_DATE_INVALID: {
    code: 'EXPIRATION_DATE_INVALID',
    message: 'La validité du document a expirée',
  },
  FILE_NOT_FOUND: {
    code: 'FILE_NOT_FOUND',
    message: "Le fichier n'existe pas",
  },
  FILE_TOO_LARGE: {
    code: 'FILE_TOO_LARGE',
    message: 'Le fichier est trop volumineux',
  },
  IDENTIFIER_INVALID: {
    code: 'IDENTIFIER_INVALID',
    message: "Le nom/prénom ne correspond pas à celui renseigné à l'inscription",
  },
  INVALID_KYC_FILE: {
    code: 'INVALID_KYC_FILE',
    message: 'Le fichier est invalide',
  },
  INVALID_SELFIE: {
    code: 'INVALID_SELFIE',
    message: "Le selfie n'est pas valide, vous devez être seul sur la photo et clairement visible",
  },
  NO_TRIP_FOUND_FOR_THIS_RELATED_SWORN_STATEMENT_TYPE: {
    code: 'NO_TRIP_FOUND_FOR_THIS_RELATED_SWORN_STATEMENT_TYPE',
    message: 'Aucun trajet ne semble être éligible, et nécessiter une attestation.',
  },
  UNAUTHORIZED_ACCESS: {
    code: 'UNAUTHORIZED_ACCESS',
    message: "L'accès à ce fichier n'est pas autorisé",
  },
};

export default expectedErrors;
