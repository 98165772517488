import { Box, Typography } from '@mui/material';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router';
import useResponsive from 'src/hooks/useResponsive';

import { HORIZONTAL_MAX_ITEM, MenuOrientation } from '../../../../../config';
import { isGranted } from '../../../../../constants/userRoles';
import useAuth from '../../../../../contexts/auth/useAuth';
import useConfig from '../../../../../hooks/useConfig';
import menuItem from '../../../../../menu-items';
import { useDispatch } from '../../../../../store';
import { activeItem } from '../../../../../store/reducers/menu';
import NavGroup from './NavGroup';

function Navigation() {
  const { user } = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();

  const isMobile = useResponsive('down', 'md');

  const { menuOrientation } = useConfig();
  const [selectedItems, setSelectedItems] = useState('');
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [menuItems, setMenuItems] = useState({ items: [] });

  const { pathname } = location;

  useLayoutEffect(() => {
    setMenuItems(menuItem);
    // eslint-disable-next-line
  }, [menuItem]);

  useEffect(() => {
    if (!menuItems?.items) return;
    const flattenItems = menuItems.items.reduce((acc, group) => [...acc, ...group.children], []);

    const activeItems = flattenItems
      .filter((item) => {
        const match = matchPath(
          {
            exact: true,
            path: item.url,
            strict: true,
          },
          pathname
        );

        return Boolean(match);
      })
      .map((item) => item.id);

    dispatch(activeItem({ openItem: activeItems }));
  }, [dispatch, menuItems, pathname]);

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !isMobile;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = menuItems.items.length - 1;
  let remItems = [];
  let lastItemId;

  //  first it checks menu item is more than giving HORIZONTAL_MAX_ITEM after that get lastItemid by giving horizontal max
  // item and it sets horizontal menu by giving horizontal max item lastly slice menuItem from array and set into remItems

  if (lastItem && lastItem < menuItems.items.length) {
    lastItemId = menuItems.items[lastItem - 1].id;
    lastItemIndex = lastItem - 1;
    remItems = menuItems.items.slice(lastItem - 1, menuItems.items.length).map((item) => ({
      elements: item.children,
      icon: item.icon,
      title: item.title,
    }));
  }

  const navGroupsPrivate = menuItems.items.filter((item) => {
    const roles = item.role || item.roles;
    if (!roles) return true;
    return isGranted(user.role, roles);
  });

  const navGroups = navGroupsPrivate.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            key={item.id}
            item={item}
            lastItem={lastItem}
            lastItemId={lastItemId}
            remItems={remItems}
            selectedItems={selectedItems}
            selectedLevel={selectedLevel}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
          />
        );
      default:
        return (
          <Typography key={item.id} align="center" color="error" variant="h6">
            Fix - Navigation Group
          </Typography>
        );
    }
  });
  return (
    <Box
      sx={{
        '& > ul:first-of-type': { mt: 0 },
        display: isHorizontal ? { lg: 'flex', xs: 'block' } : 'block',
        // eslint-disable-next-line no-nested-ternary
        pt: isHorizontal ? 0 : 2,
      }}
    >
      {navGroups}
    </Box>
  );
}

export default Navigation;
