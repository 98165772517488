const COUNTRY = [
  // Europe
  { code: 'FR', image: '🇫🇷', indicator: '+33', label: 'France', phoneMask: '9 99 99 99 99' },
  { code: 'DE', image: '🇩🇪', indicator: '+49', label: 'Deutschland', phoneMask: '999 9999999' },
  { code: 'IT', image: '🇮🇹', indicator: '+39', label: 'Italia', phoneMask: '999 999 9999' },
  { code: 'ES', image: '🇪🇸', indicator: '+34', label: 'España', phoneMask: '999 99 99 99' },
  { code: 'GB', image: '🇬🇧', indicator: '+44', label: 'United Kingdom', phoneMask: '9999 999999' },
  { code: 'RU', image: '🇷🇺', indicator: '+7', label: 'Россия', phoneMask: '9 999 999-99-99' },
  { code: 'NL', image: '🇳🇱', indicator: '+31', label: 'Nederland', phoneMask: '99 9999999' },
  { code: 'BE', image: '🇧🇪', indicator: '+32', label: 'België', phoneMask: '999 999 999' },
  { code: 'PL', image: '🇵🇱', indicator: '+48', label: 'Polska', phoneMask: '999 999 999' },
  { code: 'SE', image: '🇸🇪', indicator: '+46', label: 'Sverige', phoneMask: '99-999 9999' },
  // ... autres pays européens ...

  // Amérique du Nord
  { code: 'US', image: '🇺🇸', indicator: '+1', label: 'United States', phoneMask: '999 999 9999' },
  { code: 'CA', image: '🇨🇦', indicator: '+1', label: 'Canada', phoneMask: '999 999 9999' },
  { code: 'MX', image: '🇲🇽', indicator: '+52', label: 'México', phoneMask: '99 9999 9999' },
  // ... autres pays d'Amérique du Nord ...

  // Amérique du Sud
  { code: 'BR', image: '🇧🇷', indicator: '+55', label: 'Brasil', phoneMask: '99 99999-9999' },
  { code: 'AR', image: '🇦🇷', indicator: '+54', label: 'Argentina', phoneMask: '9 99 9999-9999' },
  { code: 'CL', image: '🇨🇱', indicator: '+56', label: 'Chile', phoneMask: '9 9999 9999' },
  // ... autres pays d'Amérique du Sud ...

  // Asie
  { code: 'CN', image: '🇨🇳', indicator: '+86', label: '中国', phoneMask: '999 9999 9999' },
  { code: 'JP', image: '🇯🇵', indicator: '+81', label: '日本', phoneMask: '99-9999-9999' },
  { code: 'IN', image: '🇮🇳', indicator: '+91', label: 'भारत', phoneMask: '99999 99999' },
  // ... autres pays asiatiques ...

  // Moyen-Orient
  { code: 'SA', image: '🇸🇦', indicator: '+966', label: 'السعودية', phoneMask: '9 999 9999' },
  { code: 'AE', image: '🇦🇪', indicator: '+971', label: 'الإمارات', phoneMask: '9 999 9999' },
  // ... autres pays du Moyen-Orient ...

  // Afrique
  { code: 'ZA', image: '🇿🇦', indicator: '+27', label: 'South Africa', phoneMask: '99 999 9999' },
  { code: 'EG', image: '🇪🇬', indicator: '+20', label: 'مصر', phoneMask: '1 9999 9999' },
  // ... autres pays africains ...

  // Océanie
  { code: 'AU', image: '🇦🇺', indicator: '+61', label: 'Australia', phoneMask: '9 9999 9999' },
  { code: 'NZ', image: '🇳🇿', indicator: '+64', label: 'New Zealand', phoneMask: '9 999 9999' },
];

export default COUNTRY;
