import { Stack } from '@mui/material';
import React from 'react';
import HelpLink from 'src/components/HelpLink';
import GoogleMap from 'src/components/map/GoogleMap';
import MapFadedContainer from 'src/components/map/MapFadedContainer';
import MobileSheet from 'src/components/MobileSheet';
import PageWrapper from 'src/components/PageWrapper';
import { JourneyProvider } from 'src/contexts/JourneyContext';
import { TripProvider } from 'src/contexts/TripContext';

import TripProgress from '../TripProgress';
import EndTripAction from './EndTripAction';
import PassengerList from './PassengerList';

function ConductorMode({ trip }) {
  const isInTripMode = trip.hasDriverStarted && !trip.hasDriverFinished;

  return (
    <TripProvider>
      <JourneyProvider>
        <MobileSheet fullHeight notSwipeable open={isInTripMode}>
          <PageWrapper subtitle="C'est parti!" title="Mode trajet" withoutLogo>
            <Stack spacing={3}>
              <PassengerList journeys={trip.journeys} />
              <MapFadedContainer height={350}>
                <GoogleMap endAddress={trip.endAddress} startAddress={trip.startAddress} />
              </MapFadedContainer>
              <TripProgress trip={trip} />
              <EndTripAction trip={trip} />
              <HelpLink additionalInfos={{ tripId: trip.id }} />
            </Stack>
          </PageWrapper>
        </MobileSheet>
      </JourneyProvider>
    </TripProvider>
  );
}

export default ConductorMode;
