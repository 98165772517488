import { Box, Grid, InputBase, alpha, styled } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const InputField = styled(Box)(({ active, filled, theme }) => ({
  alignItems: 'center',
  backgroundColor: filled
    ? alpha(theme.palette.primary.light, 0.4)
    : alpha(theme.palette.divider, 0.4),
  borderColor: 'transparent',
  borderRadius: `${theme.shape.borderRadius}px`,
  borderStyle: 'solid',
  borderWidth: 2,
  display: 'flex',
  fontWeight: 'bold',
  height: 56,
  justifyContent: 'center',
  padding: theme.spacing(2),
  transition: 'all .125s ease',
  width: '100%',
  ...(active && {
    borderColor: theme.palette.primary.main,
  }),
}));

function OtpInput({ length, onChange }) {
  const inputRef = useRef();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [value, setValue] = useState('');

  const handleFocus = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setActiveIndex(inputRef.current.value.length);
    }
  }, []);

  const handleChange = useCallback(
    (e) => {
      e.target.value = e.target.value.substring(0, 6);
      const otpValue = e.target.value.substring(0, 6);
      setActiveIndex(otpValue.length);
      setValue(otpValue);
      onChange(otpValue);
    },
    [onChange]
  );

  const handleBlur = useCallback(() => {
    if (inputRef.current.value.length < length) {
      inputRef.current.focus();
    }
  }, [length]);

  const splittedValue = useMemo(() => value.split(''), [value]);

  useEffect(() => {
    setTimeout(handleFocus, 250);
  }, [handleFocus]);

  return (
    <Grid
      alignItems="center"
      container
      justifyContent="center"
      spacing={1}
      sx={{ position: 'relative' }}
    >
      <InputBase
        autoFocus
        inputProps={{ inputMode: 'numeric', style: { caretColor: 'transparent' } }}
        inputRef={inputRef}
        maxLength={6}
        onBlur={handleBlur}
        onChange={handleChange}
        size="large"
        sx={{
          height: 56,
          left: 0,
          opacity: 0.01,
          position: 'absolute',
          width: '100%',
        }}
        type="text"
      />
      {[...Array(length).keys()].map((key, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid key={key} item xs>
          <InputField active={index === activeIndex} filled={splittedValue?.[index]}>
            {splittedValue?.[index] || ''}
          </InputField>
        </Grid>
      ))}
    </Grid>
  );
}

OtpInput.defaultProps = {
  length: 6,
};

export default OtpInput;
