/**
 * Clean a French phone number by removing the country code (+33) and format it by adding spaces
 * for better readability.
 *
 * @param {string} phoneNumber The phone number to be cleaned and formatted.
 * @return {string} The cleaned and formatted phone number without the +33 country code,
 * with spaces added for better readability.
 */
function cleanPhoneNumber(phoneNumber) {
  if (!phoneNumber) return '';
  let cleanNumber = phoneNumber;

  // Check if the phoneNumber starts with +33 and replace it with 0
  if (phoneNumber.startsWith('+33')) {
    cleanNumber = `0${phoneNumber.substring(3)}`;
  }

  // Formatting the number with spaces between every two digits
  // Starting from index 1 because we don't want to separate the initial '0'
  return cleanNumber
    .match(/.{1,2}/g)
    .join(' ')
    .substring(1);
}

export default cleanPhoneNumber;
