import { CloseOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useRef } from 'react';
import StyledDialog from 'src/components/dialogs/StyledDialog.js';
import i18n from 'src/locales/i18n';

import MODAL_TYPES from './types';

function GenericDialog({
  children,
  confirmLabel,
  defaultPromptValue,
  dialogType,
  discardLabel,
  handleConfirm,
  handleDiscard,
  maxWidth = 'xs',
  message,
  noCloseAction,
  onConfirm,
  onDiscard,
  open,
  title,
  withoutActions = false,
}) {
  const theme = useTheme();
  const promptRef = useRef();

  const handleGenericConfirm = async () => {
    if (handleConfirm) {
      await handleConfirm();
    }
    if (promptRef.current?.value) {
      await onConfirm(promptRef.current?.value);
      return;
    }

    await onConfirm();
  };

  const handleGenericDiscard = async () => {
    if (handleDiscard) {
      await handleDiscard();
    }
    await onDiscard();
  };

  return (
    <StyledDialog fullWidth maxWidth={maxWidth} open={open}>
      <DialogTitle sx={{ p: 0 }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
          {title && (
            <Typography align="center" component="p" variant="h3">
              {title}
            </Typography>
          )}
        </Toolbar>
        {!noCloseAction && (
          <Box sx={{ position: 'absolute', right: theme.spacing(1), top: theme.spacing(1) }}>
            <IconButton onClick={handleGenericDiscard}>
              <CloseOutlined />
            </IconButton>
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        {message && (
          <Stack spacing={2}>
            <Typography align="center" color="text.secondary">
              {message}
            </Typography>
          </Stack>
        )}
        {dialogType === MODAL_TYPES.PROMPT && (
          <TextField
            autoFocus
            defaultValue={defaultPromptValue}
            fullWidth
            inputRef={promptRef}
            onFocus={(e) => e.target.select()}
          />
        )}
        {children}
      </DialogContent>
      {!withoutActions && (
        <DialogActions sx={{ flexDirection: 'row', justifyContent: 'center' }}>
          {dialogType !== MODAL_TYPES.ALERT && (
            <Button onClick={handleGenericDiscard} size="small" variant="outlined">
              {discardLabel}
            </Button>
          )}
          <Button onClick={handleGenericConfirm} size="small" variant="contained">
            {confirmLabel}
          </Button>
        </DialogActions>
      )}
    </StyledDialog>
  );
}

GenericDialog.defaultProps = {
  confirmLabel: i18n.t('components.dialogsPromptDialogConfirmText'),
  discardLabel: i18n.t('components.dialogsPromptDialogDiscardText'),
};

export default GenericDialog;
