import { Grid, Typography } from '@mui/material';
import React from 'react';

import AuthCodeVerification from '../../sections/auth/auth-forms/AuthCodeVerification';
import AuthWrapper from '../../sections/auth/AuthWrapper';

function CodeVerification() {
  return (
    <AuthWrapper pageTitle="Vérifie ton numéro" withoutLogo>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12}>
          <Typography align="center">On vient de t&apos;envoyer un code par SMS</Typography>
        </Grid>
        <Grid item xs={12}>
          <AuthCodeVerification />
        </Grid>
      </Grid>
    </AuthWrapper>
  );
}

export default CodeVerification;
