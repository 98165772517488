import { gql } from '@apollo/client';

const walletPayInDataFragment = gql`
  fragment WalletPayInData on WalletPayInData {
    Id
    CreationDate
    AuthorId
    CreditedUserId
    DebitedFunds {
      Currency
      Amount
    }
    CreditedFunds {
      Currency
      Amount
    }
    Fees {
      Currency
      Amount
    }
    CreditedWalletId
    CardId
    SecureModeReturnURL
    Status
    ResultCode
    ResultMessage
    ExecutionDate
    Type
    Nature
    PaymentType
    ExecutionType
  }
`;

export default walletPayInDataFragment;
