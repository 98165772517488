import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import useSEO from 'src/hooks/useSEO';
import { PATH_AUTH } from 'src/routes/paths';
import AuthLogin from 'src/sections/auth/auth-forms/AuthLogin';
import AuthWrapper from 'src/sections/auth/AuthWrapper';

function Login() {
  useSEO({
    description:
      "Connecte-toi à l'application de covoiturage qui te rapporte de l'argent dès ton premier trajet",
    title: 'Se connecter',
  });

  return (
    <AuthWrapper pageTitle="Connecte-toi">
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <AuthLogin />
        </Grid>
        <Grid item xs="auto">
          <Typography
            color="primary"
            component={Link}
            sx={{ textDecoration: 'none' }}
            to={PATH_AUTH.register}
            variant="body2"
          >
            Tu n&apos;as pas encore de compte?
          </Typography>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
}

export default Login;
