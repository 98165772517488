import { Container, Grid, Link, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormErrors from 'src/components/FormErrors';
import OtpInput from 'src/components/hook-form/OtpInput';
import useAuth from 'src/contexts/auth/useAuth';
import expectedErrors from 'src/graphql/auth/expectedErrors';
import useAntiSpammingAction from 'src/hooks/useAntiSpammingAction';

function AuthCodeVerification() {
  const {
    logout,
    passOtpVerification,
    passOtpVerificationState,
    resendVerificationCode,
    resendVerificationCodeState,
  } = useAuth();
  const inputRef = useRef();
  const [otp, setOtp] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handlePassOtpVerification = useCallback(async () => {
    try {
      await passOtpVerification({
        onError: () => {
          inputRef.current.blur();
        },
        variables: { input: { code: otp } },
      });
    } catch {
      // Nothing todo
    }
  }, [passOtpVerification, otp]);

  const handleResendOTP = useCallback(async () => {
    try {
      await resendVerificationCode();
      enqueueSnackbar("Un nouveau code t'as été envoyé!", { variant: 'success' });
    } catch {
      // Nothing todo
    }
  }, [enqueueSnackbar, resendVerificationCode]);

  const [handleResendOTPGuarded, { canPerformAction }] = useAntiSpammingAction(
    'resend-otp-action',
    handleResendOTP,
    {
      period: 1,
      periodUnit: 'minutes',
    }
  );

  const resendOTPTooltip = !canPerformAction ? 'Tu pourras redemander un code dans 1 minute' : '';

  useEffect(() => {
    if (otp.length === 6) handlePassOtpVerification();
  }, [otp, handlePassOtpVerification]);

  return (
    <Container disableGutters maxWidth="xs">
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <OtpInput onChange={setOtp} />
        </Grid>

        {/* <Grid item xs={12}>
          <LoadingButton
            disableElevation
            endIcon={<GppGoodOutlinedIcon fontSize="12px" />}
            fullWidth
            loading={passOtpVerificationState.loading}
            onClick={handlePassOtpVerification}
            size="large"
            variant="contained"
          >
            Vérifier
          </LoadingButton>
        </Grid> */}
        {passOtpVerificationState.error && (
          <Grid item xs={12}>
            <FormErrors
              error={passOtpVerificationState.error}
              expectedErrors={[
                expectedErrors.OTP_FAILED,
                expectedErrors.OTP_CODE_EXPIRED,
                expectedErrors.CANNOT_SEND_OTP,
              ]}
            />
          </Grid>
        )}
        <Grid alignSelf="center" item xs="auto">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link component="button" onClick={logout}>
            Déconnexion
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="body2">
            Tu n&apos;as pas reçu le code?
            <Tooltip arrow placement="top" title={resendOTPTooltip}>
              <Typography
                color={canPerformAction ? 'primary' : 'text.secondary'}
                component="span"
                onClick={handleResendOTPGuarded}
                sx={{ cursor: 'pointer', minWidth: 85, textDecoration: 'none' }}
                variant="inherit"
              >
                {' '}
                Renvoyer un code
              </Typography>
            </Tooltip>
          </Typography>
        </Grid>
        {resendVerificationCodeState.error && (
          <Grid item xs={12}>
            <FormErrors error={resendVerificationCodeState.error} withSnackbar />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default AuthCodeVerification;
