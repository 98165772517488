import { gql } from '@apollo/client';

const journeyFragment = gql`
  fragment Journey on Journey {
    id

    tripId
    passengerId

    hasConfirmedTakingCharge
    hasDeclinedTakingCharge
    hasConfirmedDeposit

    takingChargeGpsAddressId

    depositGpsAddressId

    takingChargeAt
    depositAt

    status
    refusalReason

    type
    isFree

    isCoherenceCheckValid
    coherenceAnomalyErrorDetail

    rpcId
    rpcStatus
    rpcAnomalyErrorDetail

    createdAt
    updatedAt
  }
`;

export default journeyFragment;
