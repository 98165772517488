import { CloseOutlined } from '@ant-design/icons';
// material-ui
import { Alert, Button, Fade, Grow, Slide } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
// assets
import React from 'react';

import { dispatch, useSelector } from '../../store';
import { closeSnackbar } from '../../store/reducers/snackbar';
// project-import
import IconButton from './IconButton';

// animation function
function TransitionSlideLeft(props) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}

// animation options
const animation = {
  Fade,
  Grow: GrowTransition,
  SlideDown: TransitionSlideDown,
  SlideLeft: TransitionSlideLeft,
  SlideRight: TransitionSlideRight,
  SlideUp: TransitionSlideUp,
};

// ==============================|| SNACKBAR ||============================== //

function Snackbar() {
  const snackbar = useSelector((state) => state.snackbar);
  const { actionButton, alert, anchorOrigin, close, message, open, transition, variant } = snackbar;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbar());
  };

  return (
    <>
      {/* default snackbar */}
      {variant === 'default' && (
        <MuiSnackbar
          action={
            <>
              <Button color="secondary" onClick={handleClose} size="small">
                UNDO
              </Button>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={handleClose}
                size="small"
                sx={{ mt: 0.25 }}
              >
                <CloseOutlined />
              </IconButton>
            </>
          }
          anchorOrigin={anchorOrigin}
          autoHideDuration={6000}
          message={message}
          onClose={handleClose}
          open={open}
          TransitionComponent={animation[transition]}
        />
      )}

      {/* alert snackbar */}
      {variant === 'alert' && (
        <MuiSnackbar
          anchorOrigin={anchorOrigin}
          autoHideDuration={6000}
          onClose={handleClose}
          open={open}
          TransitionComponent={animation[transition]}
        >
          <Alert
            action={
              <>
                {actionButton !== false && (
                  <Button color={alert.color} onClick={handleClose} size="small">
                    UNDO
                  </Button>
                )}
                {close !== false && (
                  <IconButton
                    aria-label="close"
                    color={alert.color}
                    onClick={handleClose}
                    size="small"
                    sx={{ mt: 0.25 }}
                    variant="contained"
                  >
                    <CloseOutlined />
                  </IconButton>
                )}
              </>
            }
            color={alert.color}
            sx={{
              ...(alert.variant === 'outlined' && {
                bgcolor: 'grey.0',
              }),
            }}
            variant={alert.variant}
          >
            {message}
          </Alert>
        </MuiSnackbar>
      )}
    </>
  );
}

export default Snackbar;
