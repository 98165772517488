import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'src/contexts/auth/useAuth';
import authExpectedErrors from 'src/graphql/auth/expectedErrors';
import { APP_ROOT_PATH } from 'src/routes/paths';

import useLocales from '../../../hooks/useLocales';

const bindError = (error, callback) => ({ ...error, on: callback });

const useQueryErrors = (
  error,
  { expectExpiredLink = false, expectedErrors, silentError = false } = {}
) => {
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const defaultNetworkErrorMessage =
    'Impossible de contacter le serveur, vérifie ta connexion internet';

  /**
   * The clean way to handle authentication errors would be to return a custom http
   * error code on server side (ex : 498)
   * This should be possible through plugins https://github.com/apollographql/apollo-server/issues/1709#issuecomment-495793375
   * Unfortunately it not because of https://github.com/apollographql/apollo-server/issues/3223
   * So in the meantime we use isAuthenticationError to avoid spamming errors
   * on simple authentication problem
   */
  useEffect(() => {
    if (error) {
      const { graphQLErrors, networkError } = error;
      const defaultErrorMessage = translate('commons.somethingWentWrong');

      let errorHandled;
      if (networkError) {
        let networkErrorHandled;
        networkError.result?.errors?.forEach(({ extensions, message }) => {
          errorHandled = true;
          // Catch expired token exception

          if (extensions?.code === 'UNAUTHENTICATED') {
            networkErrorHandled = true;

            if (expectExpiredLink) {
              enqueueSnackbar('Le lien a expiré', { variant: 'error' });
            } else {
              enqueueSnackbar('Tu es déconnecté', { variant: 'error' });
              logout();
            }
          } else if (!silentError) {
            enqueueSnackbar(message, { variant: 'error' });
          }
        });
        // send unexpected server errors (ie: code > 200 and not an authentication error) to datadog
        /** should opt out for 498 custom code */
        if (networkError.statusCode > 299 && !networkErrorHandled) {
          // logger.error(`[request-error] status code ${networkError.statusCode}`, { networkError });
        }
        /** the networkError.result?.errors?.forEach loop should be done here */
        // handle the critical "Request header too large" error even if silentError is true
        if (networkError.result?.error?.code === 494) {
          errorHandled = true;
          //
        }
        if (!errorHandled) {
          errorHandled = true;
          if (!silentError) {
            // extract errorMessage in custom NGINX responses if applicable
            enqueueSnackbar(networkError.result?.error?.message || defaultNetworkErrorMessage, {
              variant: 'error',
            });
          }
        }
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions }) => {
          if (extensions?.code === authExpectedErrors.INVALID_DEVICE.code) {
            enqueueSnackbar(authExpectedErrors.INVALID_DEVICE.message, { variant: 'error' });
            logout();
            return;
          }

          if (!silentError) {
            errorHandled = true;
            if (extensions?.code === 'ITEM_NOT_FOUND') {
              enqueueSnackbar('ITEM_NOT_FOUND', { variant: 'error' });
              navigate(APP_ROOT_PATH);
            } else {
              const expectedError = expectedErrors?.find((e) => e.code === extensions?.code);
              enqueueSnackbar(expectedError?.message || defaultErrorMessage, { variant: 'error' });
            }
          }
        });
      }
      // if the error was not handled display a default message
      // this should never happen
      if (!errorHandled) {
        // logger.error(`[request-error] request error unhandled`);
        if (!silentError) enqueueSnackbar(defaultErrorMessage, { variant: 'error' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, silentError, expectedErrors]);
};

export { bindError };
export default useQueryErrors;
