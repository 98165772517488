import { gql } from '@apollo/client';

const cardRegistrationDataFragment = gql`
  fragment CardRegistrationData on CardRegistrationData {
    Id
    CreationDate
    UserId
    AccessKey
    PreregistrationData
    CardRegistrationURL
    RegistrationData
    CardType
    Currency
    Status
    ResultCode
    ResultMessage
  }
`;

export default cardRegistrationDataFragment;
