import { CssBaseline, GlobalStyles, StyledEngineProvider } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useConfig from '../hooks/useConfig';
import componentsOverride from './overrides';
import Palette from './palette';
import CustomShadows from './shadows';
import Typography from './typography';

export default function ThemeCustomization({ children, forceThemeMode }) {
  const { fontFamily, mode: themeMode, presetColor, themeDirection } = useConfig();

  const mode = forceThemeMode || themeMode;

  const theme = useMemo(() => Palette(mode, presetColor), [mode, presetColor]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const themeTypography = useMemo(() => Typography(fontFamily), [fontFamily]);
  const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

  const themeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          lg: 1266,
          md: 1024,
          sm: 768,
          xl: 1440,
          xs: 0,
        },
      },
      customShadows: themeCustomShadows,
      direction: themeDirection,
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingBottom: 8,
          paddingTop: 8,
        },
      },
      palette: theme.palette,
      shape: {
        borderRadius: 12,
      },
      typography: themeTypography,
    }),
    [themeDirection, theme, themeTypography, themeCustomShadows]
  );

  const themes = createTheme(themeOptions);
  themes.components = componentsOverride(themes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeCustomization.propTypes = {
  children: PropTypes.node,
};
