import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router';

import Footer from './Footer';
import Header from './Header';

function LandingLayout() {
  return (
    <Box
      component="main"
      sx={{ display: 'flex', flexDirection: 'column', maxWidth: '100vw', minHeight: '100vh' }}
    >
      <Header />
      <Outlet />
      <Footer />
    </Box>
  );
}

export default LandingLayout;
