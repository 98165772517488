import { gql } from '@apollo/client';

import {
  addressFragment,
  adminUserFragment,
  feesFragment,
  mangoPayKycInformationsFragment,
  userFragment,
} from '../fragments';

const CURRENT_USER = gql`
  {
    me {
      ...User

      administrativeFullAddress {
        ...Address
      }

      initialRewardsInformations {
        short {
          primeAmount
          lastPrimeAmount
          remainingTripForLastPrime
          maxTripsPerPeriod
          rollEndsAt
          countDownInDays
          hasSignedSwornStatement
          needSwornStatement
        }
        long {
          primeAmount
          lastPrimeAmount
          remainingTripForLastPrime
          maxTripsPerPeriod
          rollEndsAt
          countDownInDays
        }
      }

      fees {
        ...Fees
      }

      mangoPayKycInformations {
        ...MangoPayKycInformations
      }
    }
  }
  ${addressFragment}
  ${userFragment}
  ${feesFragment}
  ${mangoPayKycInformationsFragment}
`;

const ME_AS_ADMIN = gql`
  {
    meAsAdmin {
      ...AdminUser
    }
  }
  ${adminUserFragment}
`;

export { CURRENT_USER, ME_AS_ADMIN };
