import { isGranted } from 'src/constants/userRoles';
import useAuth from 'src/contexts/auth/useAuth';

function WithRoles({ accessibleRoles, children, excludeHierarchy }) {
  const { user } = useAuth();

  if (!isGranted(user.role, accessibleRoles, excludeHierarchy)) return null;

  return children;
}

export default WithRoles;
