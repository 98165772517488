import React, { useCallback, useEffect, useState } from 'react';

import Handler from './handlers';

const MIN_DISTANCE = 30;
const TOTAL_DISTANCE = 110;

function PullToRefreshHandler({ target }) {
  const [isPulling, setIsPulling] = useState(false);
  const [startY, setStartY] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const [distance, setDistance] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const [indicatorVisible, setIndicatorVisible] = useState(0);
  const [lastScrollY, setLastScrollY] = useState(0);

  const refreshContent = useCallback(() => {
    setRefreshing(true);
    document.body.style.overflow = 'hidden';
    setTimeout(() => {
      window.location.reload();
    }, 1125);
  }, []);

  const handleScroll = useCallback(() => {
    if (window.scrollY < -10 && window.scrollY < lastScrollY) {
      setIndicatorVisible(true);
    } else {
      setIndicatorVisible(false);
    }
    setLastScrollY(window.scrollY);
  }, [lastScrollY]);

  const handleTouchStart = useCallback((e) => {
    setStartY(e.touches[0].pageY);
  }, []);

  const handleTouchMove = useCallback(
    (e) => {
      if (refreshing) return;

      setCurrentY(e.touches[0].pageY);
      const movedDistance = currentY - startY;

      setDistance(movedDistance);

      let pulling = isPulling;
      if (window.scrollY <= 0 && movedDistance > 0) {
        pulling = true;
        if (pulling !== isPulling) setIsPulling(pulling);
      }

      const THRESHOLD = TOTAL_DISTANCE - MIN_DISTANCE;
      const realMovedDistance = Math.min(movedDistance - MIN_DISTANCE, THRESHOLD);
      setOpacity(realMovedDistance / THRESHOLD);
    },
    [currentY, isPulling, startY, refreshing]
  );

  useEffect(() => {
    const handleTouchEnd = async () => {
      if (isPulling) {
        if (distance > TOTAL_DISTANCE) {
          await refreshContent();
        } else {
          setDistance(0);
          setStartY(0);
          setCurrentY(0);
          setOpacity(0);
          setIndicatorVisible(false);
        }
      }

      setIsPulling(false);
      setDistance(0);
    };

    target.addEventListener('touchstart', handleTouchStart);
    target.addEventListener('touchmove', handleTouchMove);
    target.addEventListener('touchend', handleTouchEnd);
    window.addEventListener('scroll', handleScroll);

    return () => {
      target.removeEventListener('touchstart', handleTouchStart);
      target.removeEventListener('touchmove', handleTouchMove);
      target.removeEventListener('touchend', handleTouchEnd);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [
    isPulling,
    startY,
    currentY,
    distance,
    refreshContent,
    target,
    lastScrollY,
    handleTouchStart,
    handleTouchMove,
    handleScroll,
  ]);

  const readyToRefresh = currentY - startY >= TOTAL_DISTANCE;

  return (
    <Handler
      indicatorVisible={indicatorVisible}
      opacity={opacity}
      readyToRefresh={readyToRefresh}
      refreshing={refreshing}
    />
  );
}

export default PullToRefreshHandler;
