// material-ui
import { Box, Collapse, Fade, Grow, Slide, Zoom } from '@mui/material';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

// ==============================|| TRANSITIONS ||============================== //

// eslint-disable-next-line react/display-name
const Transitions = forwardRef(
  ({ children, direction = 'up', position = 'top-left', type = 'grow', ...others }, ref) => {
    let positionSX = {
      transformOrigin: '0 0 0',
    };

    switch (position) {
      case 'top-right':
        positionSX = {
          transformOrigin: 'top right',
        };
        break;
      case 'top':
        positionSX = {
          transformOrigin: 'top',
        };
        break;
      case 'bottom-left':
        positionSX = {
          transformOrigin: 'bottom left',
        };
        break;
      case 'bottom-right':
        positionSX = {
          transformOrigin: 'bottom right',
        };
        break;
      case 'bottom':
        positionSX = {
          transformOrigin: 'bottom',
        };
        break;
      case 'top-left':
      default:
        positionSX = {
          transformOrigin: '0 0 0',
        };
        break;
    }

    return (
      <Box ref={ref}>
        {type === 'grow' && (
          <Grow
            {...others}
            timeout={{
              appear: 0,
              enter: 150,
              exit: 150,
            }}
          >
            <Box sx={positionSX}>{children}</Box>
          </Grow>
        )}

        {type === 'collapse' && (
          <Collapse {...others} sx={positionSX}>
            {children}
          </Collapse>
        )}

        {type === 'fade' && (
          <Fade
            {...others}
            timeout={{
              appear: 0,
              enter: 300,
              exit: 150,
            }}
          >
            <Box sx={positionSX}>{children}</Box>
          </Fade>
        )}

        {type === 'slide' && (
          <Slide
            {...others}
            direction={direction}
            timeout={{
              appear: 0,
              enter: 150,
              exit: 150,
            }}
          >
            <Box sx={positionSX}>{children}</Box>
          </Slide>
        )}

        {type === 'zoom' && (
          <Zoom {...others}>
            <Box sx={positionSX}>{children}</Box>
          </Zoom>
        )}
      </Box>
    );
  }
);

Transitions.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  position: PropTypes.oneOf([
    'top-left',
    'top-right',
    'top',
    'bottom-left',
    'bottom-right',
    'bottom',
  ]),
  type: PropTypes.oneOf(['grow', 'fade', 'collapse', 'slide', 'zoom']),
};

export default Transitions;

// eslint-disable-next-line react/display-name
export const PopupTransition = forwardRef((props, ref) => (
  <Zoom ref={ref} timeout={200} {...props} />
));
