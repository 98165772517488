import { Box, SwipeableDrawer } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { DRAWER_WIDTH } from 'src/config';
import useResponsive from 'src/hooks/useResponsive';
import { dispatch, useSelector } from 'src/store';
import { openDrawer } from 'src/store/reducers/menu';

import DrawerContent from './DrawerContent';
import DrawerHeader from './DrawerHeader';

function MainDrawer() {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'md');

  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(() => <DrawerHeader open={drawerOpen} />, [drawerOpen]);

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 } }}>
      <SwipeableDrawer
        disablePortal={isMobile}
        ModalProps={{ keepMounted: true }}
        onClose={isMobile ? () => dispatch(openDrawer(false)) : undefined}
        onOpen={() => null}
        open={drawerOpen}
        sx={{
          '& .MuiBackdrop-root': {
            zIndex: theme.zIndex.drawer,
          },
          '& .MuiDrawer-paper': {
            ...(!isMobile && {
              backdropFilter: 'blur(6px)',
            }),
            border: 'none',
            borderRight: `1px dashed ${theme.palette.divider}`,
            boxShadow: 'none',
            width: DRAWER_WIDTH,
          },
          display: { xs: 'block' },
        }}
        variant={isMobile ? 'temporary' : 'persistent'}
      >
        {drawerHeader}
        {drawerContent}
      </SwipeableDrawer>
    </Box>
  );
}

export default MainDrawer;
