import { gql } from '@apollo/client';

const adminUserFragment = gql`
  fragment AdminUser on AdminUser {
    id
    firstname
    lastname
    nationality
    email
    isUserPhoneVerified
    pushToken
    isUserEmailVerified
    birthdate
    phone
    phoneOtpCode
    emailVerificationCode
    role
    isOtpVerificationRequired
    isSuspended
    lastLatitude
    currentDeviceId
    lastLongitude
    lastIp
    lastIpChangedAt
    lastPositionUpdatedAt
    lastOtpCodeGeneratedAt
    lastEmailVerificationCodeGeneratedAt
    createdAt
    updatedAt
  }
`;

export default adminUserFragment;
