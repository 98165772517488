import React, { createContext, useContext, useMemo } from 'react';
import expectedErrors from 'src/graphql/trip/expectedErrors';
import { ABORT_TRIP, CREATE_TRIP, END_TRIP, START_TRIP } from 'src/graphql/trip/mutations';
import {
  GET_MY_COMING_TRIPS,
  GET_MY_PAST_TRIPS,
  GET_MY_TRIPS_AS_CONDUCTOR,
  GET_MY_TRIPS_AS_PASSENGER,
  GET_NEAREST_TRIPS_FROM_ME,
  GET_TRIPS,
  GET_TRIP_BY_ID,
} from 'src/graphql/trip/queries';
import { useSafeLazyQuery, useSafeMutation } from 'src/services/apollo-client/wrappers';

const TripContext = createContext();

const useTrip = () => useContext(TripContext);

function TripProvider({ children }) {
  const [createTrip, createTripState] = useSafeMutation(
    CREATE_TRIP,
    {
      displaySnackbar: false,
    },
    {
      expectedErrors: [
        expectedErrors.TRIP_ALREADY_CREATED_WITH_SAME_START_DATE_TIME,
        expectedErrors.DRIVING_LICENSE_REQUIRED,
      ],
    }
  );

  const [getTripById, { data: trip, ...getTripByIdState }] = useSafeLazyQuery(
    GET_TRIP_BY_ID,
    null,
    {
      silentError: true,
    }
  );

  const [getMyTripsAsConductor, { data: tripsAsConductor, ...getMyTripsAsConductorState }] =
    useSafeLazyQuery(
      GET_MY_TRIPS_AS_CONDUCTOR,
      {
        fetchPolicy: 'cache',
      },
      {
        silentError: true,
      }
    );

  const [getMyTripsAsPassenger, { data: tripsAsPassenger, ...getMyTripsAsPassengerState }] =
    useSafeLazyQuery(
      GET_MY_TRIPS_AS_PASSENGER,
      {
        fetchPolicy: 'cache',
      },
      {
        silentError: true,
      }
    );

  const [getMyComingTrips, { data: comingTrips, ...getMyComingTripsState }] = useSafeLazyQuery(
    GET_MY_COMING_TRIPS,
    {
      fetchPolicy: 'cache',
    },
    {
      silentError: true,
    }
  );

  const [getMyPastTrips, { data: pastTrips, ...getMyPastTripsState }] = useSafeLazyQuery(
    GET_MY_PAST_TRIPS,
    {
      fetchPolicy: 'cache',
    },
    {
      silentError: true,
    }
  );

  const [getNearestTripsFromMe, { data: nearestTripsFromMe, ...getNearestTripsFromMeState }] =
    useSafeLazyQuery(
      GET_NEAREST_TRIPS_FROM_ME,
      {
        fetchPolicy: 'cache',
      },
      {
        silentError: true,
      }
    );

  const [getTrips, { data: tripsResults, ...getTripsState }] = useSafeLazyQuery(
    GET_TRIPS,
    {
      fetchPolicy: 'cache',
    },
    {
      silentError: true,
    }
  );

  const [abortTrip, abortTripState] = useSafeMutation(
    ABORT_TRIP,
    {
      displaySnackbar: false,
      refetchQueries: [
        {
          fetchPolicy: 'network-only',
          query: GET_MY_COMING_TRIPS,
        },
        'getTripById',
      ],
    },
    {
      expectedErrors: [expectedErrors.TRIP_ALREADY_STARTED],
    }
  );

  const [startTrip, startTripState] = useSafeMutation(
    START_TRIP,
    {
      displaySnackbar: false,
      refetchQueries: [
        {
          fetchPolicy: 'network-only',
          query: GET_MY_COMING_TRIPS,
        },
      ],
    },
    {
      expectedErrors: [
        expectedErrors.TRIP_ALREADY_STARTED,
        expectedErrors.TRIP_OUTDATED,
        expectedErrors.TRIP_ALREADY_FINISHED,
      ],
    }
  );

  const [endTrip, endTripState] = useSafeMutation(
    END_TRIP,
    {
      displaySnackbar: false,
      refetchQueries: ['getTripById'],
    },
    {
      expectedErrors: [
        expectedErrors.TRIP_NOT_STARTED,
        expectedErrors.TRIP_ALREADY_FINISHED,
        expectedErrors.TRIP_OUTDATED,
        expectedErrors.TRIP_ALREADY_ENDED,
      ],
    }
  );

  const memoizedContextValue = useMemo(
    () => ({
      abortTrip,
      abortTripState,
      comingTrips: comingTrips?.getMyComingTrips,
      createTrip,
      createTripState,
      endTrip,
      endTripState,
      getMyComingTrips,
      getMyComingTripsState,
      getMyPastTrips,
      getMyPastTripsState,
      getMyTripsAsConductor,
      getMyTripsAsConductorState,
      getMyTripsAsPassenger,
      getMyTripsAsPassengerState,
      getNearestTripsFromMe,
      getNearestTripsFromMeState,
      getTripById,
      getTripByIdState,
      getTrips,
      getTripsState,
      nearestTripsFromMe: nearestTripsFromMe?.getNearestTripsFromMe,
      pastTrips,
      startTrip,
      startTripState,
      trip: trip?.getTripById,
      tripsAsConductor: tripsAsConductor?.getMyTripsAsConductor,
      tripsAsPassenger: tripsAsPassenger?.getMyTripsAsPassenger,
      tripsResults: tripsResults?.getTrips,
    }),
    [
      abortTrip,
      abortTripState,
      comingTrips?.getMyComingTrips,
      createTrip,
      createTripState,
      endTrip,
      endTripState,
      getMyComingTrips,
      getMyComingTripsState,
      getMyPastTrips,
      getMyPastTripsState,
      getMyTripsAsConductor,
      getMyTripsAsConductorState,
      getMyTripsAsPassenger,
      getMyTripsAsPassengerState,
      getNearestTripsFromMe,
      getNearestTripsFromMeState,
      getTripById,
      getTripByIdState,
      getTrips,
      getTripsState,
      nearestTripsFromMe?.getNearestTripsFromMe,
      pastTrips,
      startTrip,
      startTripState,
      trip?.getTripById,
      tripsAsConductor?.getMyTripsAsConductor,
      tripsAsPassenger?.getMyTripsAsPassenger,
      tripsResults?.getTrips,
    ]
  );

  return <TripContext.Provider value={memoizedContextValue}>{children}</TripContext.Provider>;
}

export { TripProvider, useTrip };
