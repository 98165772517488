import { ListItemButton, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { PATH_USER } from 'src/routes/paths';

function SwornStatementNotificationItem({ onClose }) {
  const navigate = useNavigate();

  const handleClickOnNotification = () => {
    navigate(PATH_USER.me.bonuses);
    onClose();
  };

  return (
    <ListItemButton onClick={() => handleClickOnNotification()}>
      <ListItemText
        primary={
          <Typography variant="inherit">
            Tu n&apos;as toujours pas signé ton attestation!
          </Typography>
        }
        secondary="Tu pourras bénéficier de ta prime aussitôt que ce sera fait"
      />
    </ListItemButton>
  );
}

export default SwornStatementNotificationItem;
