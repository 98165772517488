import { Container, useTheme } from '@mui/material';
import React from 'react';

// eslint-disable-next-line react/display-name
const Section = React.forwardRef(({ centeredContent, children, id, sticky, ...props }, ref) => {
  const theme = useTheme();

  const { style, sx } = props;

  return (
    <Container
      ref={ref}
      component="section"
      id={id}
      maxWidth="lg"
      style={style}
      sx={{
        ...(centeredContent && {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }),
        ...(sticky && {
          position: 'sticky',
          top: 0,
        }),
        backgroundColor: 'transparent',
        minHeight: '100vh',
        overflow: 'hidden',
        padding: `${theme.spacing(12)} ${theme.spacing(2)}}`,
        width: '100vw',
        ...(sx && { ...sx }),
      }}
      {...props}
    >
      {children}
    </Container>
  );
});

export default Section;
