import { AddRoadOutlined, YardOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import useAuth from 'src/contexts/auth/useAuth';

function UserKPIs() {
  const { user } = useAuth();

  if (user.kmDriven <= 0) return null;

  return (
    <Stack alignItems="center" direction="row" justifyContent="center" p={2} spacing={5}>
      <Stack alignItems="center" spacing={1}>
        <AddRoadOutlined sx={{ color: 'success.darker' }} />
        <Stack alignItems="center">
          <Typography variant="caption">
            <strong>{user.kmDriven}</strong> km
          </Typography>
          <Typography color="text.secondary" sx={{ fontSize: 10 }} variant="caption">
            parcourus
          </Typography>
        </Stack>
      </Stack>
      <Stack alignItems="center" spacing={1}>
        <YardOutlined sx={{ color: 'success.darker' }} />
        <Stack alignItems="center">
          <Typography variant="caption">
            <strong>{user.co2Saved}</strong> kg de <strong>co2</strong>
          </Typography>
          <Typography color="text.secondary" sx={{ fontSize: 10 }} variant="caption">
            économisé{user.co2Saved >= 2 ? 's' : ''}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default UserKPIs;
