import { yupResolver } from '@hookform/resolvers/yup';
import { EmailOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Container, Grid, InputLabel, Stack } from '@mui/material';
import { snakeCase, toUpper } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import CONTACT_SUBJECTS from 'src/constants/contactSubjects';
import useAuth from 'src/contexts/auth/useAuth';
import safeCallback from 'src/utils/safeCallback';
import * as Yup from 'yup';

const CONTACT_SUBJECTS_OPTIONS = [
  {
    label: "J'ai besoin d'informations",
    value: CONTACT_SUBJECTS.NEED_INFORMATION,
  },
  {
    label: "J'ai rencontré un problème",
    value: CONTACT_SUBJECTS.PROBLEM_ENCOUNTERED,
  },
  {
    label: 'Je souhaite devenir partenaire',
    value: CONTACT_SUBJECTS.BECOME_PARTNER,
  },
];

const CONTACT_SUBJECTS_AUTHENTICATED_OPTIONS = [
  {
    label: 'Mes informations de profil sont incomplètes',
    value: CONTACT_SUBJECTS.MISSING_OR_WRONG_INFORMATION,
  },
];

function ContactForm({ additionalInfos, defaultEmail, defaultFullName, defaultSubject, onSent }) {
  const { contactUs, contactUsState, isAuthenticated } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const subjectsOptions = useMemo(() => {
    let options = CONTACT_SUBJECTS_OPTIONS;

    if (isAuthenticated) options = [...CONTACT_SUBJECTS_AUTHENTICATED_OPTIONS, ...options];

    return options;
  }, [isAuthenticated]);

  const [params] = useSearchParams();
  const defaultReason = params.get('subject')
    ? toUpper(snakeCase(params.get('subject')))
    : undefined;

  const ContactSchema = Yup.object().shape({
    email: Yup.string().email().required('Ton email est requis'),
    fullName: Yup.string().required('Ton nom est requis'),
    message: Yup.string()
      .min(25, "C'est un peu court comme message!")
      .max(500, 'Le message ne peut pas excéder 500 caractères')
      .required('Ton message est requis'),
    subject: Yup.string().required('La raison est requise'),
  });

  const methods = useForm({
    defaultValues: {
      email: defaultEmail || '',
      fullName: defaultFullName || '',
      message: '',
      subject: defaultReason || defaultSubject || CONTACT_SUBJECTS.NEED_INFORMATION,
    },
    resolver: yupResolver(ContactSchema),
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async (input) => {
    try {
      await contactUs({
        variables: {
          input: {
            ...input,
            ...(additionalInfos && {
              additionalInfos,
            }),
          },
        },
      });
      safeCallback(onSent);
      reset();
      enqueueSnackbar('Ton message est parti! Nous traitons ta demande au plus vite');
    } catch {
      enqueueSnackbar("Oups, ton message n'est pas parti... Essaie à nouveau!", {
        variant: 'error',
      });
    }
  };

  return (
    <FormProvider methods={methods} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Container disableGutters maxWidth="xs">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel>Qu&apos;est-ce qui t&apos;amène ici?*</InputLabel>
              <RHFSelect
                autoFocus
                disabled={Boolean(defaultSubject)}
                fullWidth
                name="subject"
                options={subjectsOptions}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel>Ton nom complet*</InputLabel>
              <RHFTextField fullWidth name="fullName" placeholder="John Doe" />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel>Ton adresse email*</InputLabel>
              <RHFTextField fullWidth name="email" placeholder="john.doe@domain.com" />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel>Ton message*</InputLabel>
              <RHFTextField
                fullWidth
                helperText="500 caractères max."
                minRows={3}
                multiline
                name="message"
                placeholder="Bonjour, ..."
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              color="primary"
              disableElevation
              endIcon={<EmailOutlined />}
              fullWidth
              loading={contactUsState.loading}
              size="large"
              type="submit"
              variant="contained"
            >
              Envoyer
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
    </FormProvider>
  );
}

export default ContactForm;
