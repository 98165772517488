import { Button, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AnimateButton from 'src/components/@extended/AnimateButton';
import UnderlinedText from 'src/components/UnderlinedText';

import menuItems from './menuItems';

function NavigationItems({ onClose, selectedItem }) {
  return menuItems
    .filter(({ hidden }) => !hidden)
    .map((item) => {
      const isActive = selectedItem?.name === item.name;

      const LinkContainerComponent = isActive ? UnderlinedText : Typography;

      if (item.type === 'button') {
        return (
          <AnimateButton key={item.name} type="slide">
            <Button
              endIcon={item.icon}
              LinkComponent={RouterLink}
              onClick={onClose}
              size="medium"
              to={item.url}
              variant="outlined"
            >
              {item.label}
            </Button>
          </AnimateButton>
        );
      }

      return (
        <Link
          key={item.name}
          component={RouterLink}
          onClick={onClose}
          sx={{
            '&:hover': {
              color: 'primary.main',
              textDecoration: 'none',
            },
            color: 'text.primary',
            px: 1,
            transition: 'all 0.25s ease',
            ...(isActive && {
              color: 'primary.main',
            }),
            marginLeft: 0,
          }}
          to={item.url}
        >
          <LinkContainerComponent
            alignItems="center"
            component={Stack}
            direction="row"
            px={0.5}
            {...(isActive && { animated: true })}
          >
            <Typography variant>{item.label}</Typography>
            {item.icon && item.icon}
          </LinkContainerComponent>
        </Link>
      );
    });
}

export default NavigationItems;
