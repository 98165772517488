import { ArrowForwardOutlined, CheckOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import React from 'react';

function OnboardingActions({
  isFirstStep,
  isLastStep,
  loading,
  nextDisabled,
  notSkipable,
  onEnd,
  onNextStep,
  onPreviousStep,
  // onReset,
}) {
  return (
    <Stack justifyContent="center" px={3} spacing={1}>
      {!isLastStep && (
        <>
          <LoadingButton
            disabled={nextDisabled}
            endIcon={<ArrowForwardOutlined />}
            loading={loading}
            onClick={onNextStep}
            variant="outlined"
          >
            {isFirstStep ? 'Commencer' : 'Suivant'}
          </LoadingButton>
          {isFirstStep && (
            <>
              {notSkipable && (
                <Button color="inherit" size="small" sx={{ color: 'background.default' }}>
                  Fake Button
                </Button>
              )}
              {!notSkipable && (
                <Button disabled={loading} onClick={onEnd} size="small">
                  Passer le tutoriel
                </Button>
              )}
            </>
          )}
          {!isFirstStep && (
            <Button disabled={loading} onClick={onPreviousStep} size="small">
              Précédent
            </Button>
          )}
        </>
      )}
      {isLastStep && (
        <>
          <LoadingButton
            disabled={nextDisabled}
            endIcon={<CheckOutlined />}
            loading={loading}
            onClick={onEnd}
            variant="outlined"
          >
            Terminé
          </LoadingButton>
          <Button color="inherit" size="small" sx={{ color: 'background.default' }}>
            Fake Button
          </Button>
        </>
      )}
    </Stack>
  );
}

export default OnboardingActions;
