import React, { lazy } from 'react';
import Loadable from 'src/components/Loadable';
import CommonLayout from 'src/layout/CommonLayout';
import GuestGuard from 'src/utils/route-guard/GuestGuard';

const AuthLogin = Loadable(lazy(() => import('src/pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('src/pages/auth/register')));

const AuthRoutes = {
  children: [
    {
      element: <AuthLogin />,
      path: 'login',
    },
    {
      element: <AuthRegister />,
      path: 'register',
    },
  ],
  element: (
    <GuestGuard>
      <CommonLayout />
    </GuestGuard>
  ),
  path: '',
};

export default AuthRoutes;
