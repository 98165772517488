import { gql } from '@apollo/client';

const feesFragment = gql`
  fragment Fees on Fees {
    cashIn
    cashOut
    tripBookingFee
  }
`;

export default feesFragment;
