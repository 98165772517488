import FingerprintJS from '@fingerprintjs/fingerprintjs';

import isDevelopmentMode from './isDevelopmentMode';

const getDeviceID = async (user) => {
  let visitorId = null;

  const generateFingerPrint = async () => {
    const fp = await FingerprintJS.load();

    const result = await fp.get();
    visitorId = result.visitorId;

    return visitorId;
  };

  try {
    if (!isDevelopmentMode()) {
      visitorId = await generateFingerPrint();
    } else {
      visitorId = `development_device_id_${user?.id}`;
    }
  } catch (e) {
    visitorId = await generateFingerPrint();
  }

  const storedDeviceId = localStorage.getItem('deviceId');
  if (!storedDeviceId || storedDeviceId !== visitorId) {
    localStorage.setItem('deviceId', visitorId);
  }

  return visitorId;
};

const getDeviceIdAsync = (user) =>
  new Promise((resolve) => {
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(async () => {
        const deviceId = await getDeviceID(user);
        resolve(deviceId);
      });
    } else {
      setTimeout(async () => {
        const deviceId = await getDeviceID(user);
        resolve(deviceId);
      }, 1);
    }
  });

export default getDeviceIdAsync;
