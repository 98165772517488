import { enqueueSnackbar } from 'notistack';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { fromLatLng, setDefaults } from 'react-geocode';
import config from 'src/config/index';
import LOCATION_TYPE from 'src/constants/locationTypes';
import useDialogs from 'src/hooks/useDialogs';
import useLocalStorage from 'src/hooks/useLocalStorage';

const { geocodeApiKey } = config.google;

const LocationContext = createContext();

setDefaults({
  key: geocodeApiKey,
  language: 'fr',
  region: 'fr',
});

const PERMISSION_STATUS = {
  DENIED: 'denied',
  GRANTED: 'granted',
  PROMPT: 'prompt',
  UNDETERMINED: 'undetermined',
};

const formatAddress = (fullAddress, placeId) => {
  if (!fullAddress)
    return {
      fullAddress: '',
      locationType: LOCATION_TYPE.GPS_LOCATION,
      mainText: '',
      placeId: '',
      secondaryText: '',
    };
  const splittedAddress = fullAddress.split(',');
  const mainText = splittedAddress.shift();
  const secondaryText = splittedAddress.join(',');
  return {
    fullAddress,
    locationType: LOCATION_TYPE.GPS_LOCATION,
    mainText,
    placeId,
    secondaryText,
  };
};

const useLocation = () => useContext(LocationContext);

function LocationProvider({ children }) {
  const [loadingLocation, setLoadingLocation] = useState(false);
  const { alert, confirm } = useDialogs();
  const [status, setStatus] = useState(PERMISSION_STATUS.PROMPT);
  const [lastLocation, setLastLocation] = useLocalStorage('last-position', {});

  useEffect(() => {
    navigator.geolocation.getPermissions().then((result) => {
      setStatus(result.state);
    });
  }, []);

  const requestPermissions = useCallback(async () => {
    const { state } = await navigator.geolocation.getPermissions();

    // Prompt use for activate geolocation
    if (state === PERMISSION_STATUS.PROMPT || state === PERMISSION_STATUS.UNDETERMINED) {
      await alert({
        confirmLabel: 'Ok',
        message:
          'Nous avons besoin de ta position pour continuer! Ta position est nécessaire et nous permettra de valider plus facilement tes trajets par la suite',
        noCloseAction: true,
        title: 'Active la géolocalisation',
      });
    }

    // Prompt use for activate geolocation
    if (state === PERMISSION_STATUS.DENIED) {
      const dialog = window.openSettings ? confirm : alert;
      dialog({
        confirmLabel: "J'ai compris",
        message: "La géolocalisation est désactivée. Rends-toi dans tes paramètres pour l'activer",
        noCloseAction: true,
        title: 'Active la géolocalisation',
        ...(window.openSettings && {
          confirmLabel: 'Paramètres',
          discardLabel: 'Plus tard',
          handleConfirm: window.openSettings,
        }),
      });
      setLoadingLocation(false);
      throw new Error('Permission denied.');
    }

    if (state !== PERMISSION_STATUS.GRANTED) {
      const { state: newState } = await navigator.geolocation.requestPermissions();
      setStatus(newState);
    }
  }, [alert, confirm]);

  const hasPermission = useMemo(() => status === PERMISSION_STATUS.GRANTED, [status]);

  const getCurrentLocation = useCallback(async () => {
    setLoadingLocation(true);

    await requestPermissions();

    return new Promise((res, rej) => {
      navigator.geolocation
        .getPosition()
        .then((position) => {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setStatus(PERMISSION_STATUS.GRANTED);
          fromLatLng(location.lat, location.lng)
            .then(({ results }) => {
              setLoadingLocation(false);
              const currentPosition = {
                ...location,
                ...formatAddress(results[0]?.formatted_address, results[0]?.place_id),
              };

              setLastLocation({
                date: new Date(),
                position: currentPosition,
              });
              res(currentPosition);
            })
            .catch(() => {
              setLoadingLocation(false);
              enqueueSnackbar(
                "Nous n'avons pas réussi à récupérer ta localisation. Essaye à nouveau.",
                { variant: 'error' }
              );
              throw new Error('Something went wrong');
            });
        })
        .catch(() => {
          setStatus(PERMISSION_STATUS.DENIED);
          setLoadingLocation(false);
          rej(new Error('Permission denied.'));
        });
    });
  }, [requestPermissions, setLastLocation]);

  const couldLocationBeRefreshed = useCallback(() => {
    const time1 = new Date().getTime();

    if (!lastLocation.date) return true;

    const date2 = new Date(lastLocation?.date);
    const time2 = date2.getTime();

    const differenceInMilliseconds = Math.abs(time1 - time2);
    const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

    return differenceInMinutes > 15;
  }, [lastLocation]);

  const memoizedContextValue = useMemo(
    () => ({
      getCurrentLocation,
      hasPermission,
      lastLocation: {
        couldLocationBeRefreshed,
        ...lastLocation,
      },
      loadingLocation,
      requestPermissions,
      status,
    }),
    [
      getCurrentLocation,
      hasPermission,
      couldLocationBeRefreshed,
      lastLocation,
      loadingLocation,
      requestPermissions,
      status,
    ]
  );

  return (
    <LocationContext.Provider value={memoizedContextValue}>{children}</LocationContext.Provider>
  );
}

export { LocationProvider, useLocation };
