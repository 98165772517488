import { gql } from '@apollo/client';

const addressFragment = gql`
  fragment Address on Address {
    id
    mainText
    secondaryText
    lat
    lng
    placeId
    fullAddress
    locationType
    createdAt
    updatedAt
  }
`;

export default addressFragment;
