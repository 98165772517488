// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme) {
  const commonCell = {
    '&:not(:last-of-type)': {
      '&:after': {
        backgroundColor: theme.palette.divider,
        content: '""',
        height: 'calc(100% - 30px)',
        position: 'absolute',
        right: 0,
        top: 16,
        width: 1,
      },
      position: 'relative',
    },
  };

  return {
    MuiTableCell: {
      styleOverrides: {
        footer: {
          fontSize: '0.75rem',
          textTransform: 'uppercase',
          ...commonCell,
        },
        head: {
          fontSize: '0.75rem',
          fontWeight: 700,
          textTransform: 'uppercase',
          ...commonCell,
        },
        root: {
          borderColor: theme.palette.divider,
          fontSize: '0.875rem',
          padding: 12,
        },
        sizeSmall: {
          padding: 8,
        },
      },
    },
  };
}
