import { EVENT_TYPES } from './messages/messageTypes';

const postMessage = ({ args, type }) =>
  new Promise((resolve, reject) => {
    const listener = (eventMessage) => {
      try {
        const { error, event, result, type: resultType } = JSON.parse(eventMessage.data);

        if (event === EVENT_TYPES.DONE && type === resultType) {
          window.removeEventListener('message', listener);
          resolve(result);
        } else if (event === EVENT_TYPES.ERROR && type === resultType) {
          window.removeEventListener('message', listener);
          reject(error.message);
        }
      } catch (error) {
        // Nothing to do
      }
    };

    window.addEventListener('message', listener, true);

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({ args, event: EVENT_TYPES.START, type })
    );
  });

export default postMessage;
