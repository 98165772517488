const PHONE_REGEX = /^\+(?:[0-9] ?){10}[0-9]$/;

const WEBSITE_URL_REGEX =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/.*)?$/;

const LINKED_IN_URL_REGEX = /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9-]+\/$/;

const FILE_EXTENSION_REGEX = /\/[^/?]+\.[^/]+$/;

const LOCAL_IP_REGEX = /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/;

const AT_LEAST_ONE_UPPER_CASE_REGEX = /(?=.*?[A-Z])/;
const AT_LEAST_ONE_LOWER_CASE_REGEX = /(?=.*?[a-z])/;
const ONLY_LETTERS = /(?=.*?[A-Za-z])/;
const AT_LEAST_ONE_DIGIT_REGEX = /(?=.*?[0-9])/;

const CARD_NUMBER = /^(?:\d{4} ){3}\d{4}$/;
const CARD_EXPIRATION = /^(0[1-9]|1[0-2])\/\d{2}$/;

export {
  PHONE_REGEX,
  ONLY_LETTERS,
  LINKED_IN_URL_REGEX,
  WEBSITE_URL_REGEX,
  FILE_EXTENSION_REGEX,
  LOCAL_IP_REGEX,
  AT_LEAST_ONE_UPPER_CASE_REGEX,
  AT_LEAST_ONE_LOWER_CASE_REGEX,
  AT_LEAST_ONE_DIGIT_REGEX,
  CARD_NUMBER,
  CARD_EXPIRATION,
};
