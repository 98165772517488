import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Grid, InputLabel, Stack } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import FormErrors from 'src/components/FormErrors';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import useAuth from 'src/contexts/auth/useAuth';
import expectedErrors from 'src/graphql/auth/expectedErrors';
import getDeviceIdAsync from 'src/utils/getDeviceIdAsync';
import * as Yup from 'yup';

function AdminLogin() {
  const { login, loginState } = useAuth();
  const LoginSchema = Yup.object().shape({
    identifier: Yup.string().required('Ce champ est requis'),
  });

  const methods = useForm({
    defaultValues: {
      identifier: '',
    },
    resolver: yupResolver(LoginSchema),
  });

  const onSubmit = async ({ identifier }) => {
    try {
      const device = await getDeviceIdAsync();

      await login({
        variables: { input: { device, identifier } },
      });
    } catch {
      //
    }
  };

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = methods;

  return (
    <FormProvider methods={methods} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <InputLabel>Email</InputLabel>
            <RHFTextField
              autoFocus
              fullWidth
              name="identifier"
              placeholder="admin@partner.com"
              type="email"
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            loading={isSubmitting || loginState.loading}
            type="submit"
            variant="contained"
          >
            Connexion
          </LoadingButton>
        </Grid>
      </Grid>
      <FormErrors
        error={loginState.error}
        expectedErrors={[expectedErrors.USER_NOT_FOUND, expectedErrors.ACCOUNT_SUSPENDED]}
        withSnackbar
      />
    </FormProvider>
  );
}

export default AdminLogin;
