import { gql } from '@apollo/client';

const alertFragment = gql`
  fragment Alert on Alert {
    id
    userId
    isActive
    startAddressId
    endAddressId
    createdAt
    updatedAt
  }
`;

export default alertFragment;
