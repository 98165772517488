const expectedErrors = {
  ACCOUNT_SUSPENDED: {
    code: 'ACCOUNT_SUSPENDED',
    message: 'Ton compte est suspendu, contacte le support',
  },

  CANNOT_SEND_OTP: {
    code: 'CANNOT_SEND_OTP',
    message: "Tu dois attendre 10 minutes avant l'envoi d'un nouveau code",
  },

  INVALID_CREDENTIALS: {
    code: 'INVALID_CREDENTIALS',
    message: 'Les identifiants sont invalides',
  },

  // Login
  INVALID_DEVICE: {
    code: 'INVALID_DEVICE',
    message: "Un autre appareil est connecté. Cette session s'est terminée.",
  },

  // OTP validation
  OTP_CODE_EXPIRED: {
    code: 'OTP_CODE_EXPIRED',
    message: 'This code has expired.',
  },

  OTP_FAILED: {
    code: 'OTP_FAILED',
    message: "Le code n'est pas valide",
  },

  SPONSOR_NOT_FOUND: {
    code: 'SPONSOR_NOT_FOUND',
    message: 'Ton code parrain est invalide',
  },

  // Register
  USER_ALREADY_EXISTS: {
    code: 'USER_ALREADY_EXISTS',
    message: 'Un compte avec cet email ou ce numéro de téléphone existe déjà',
  },

  // Login
  USER_NOT_FOUND: {
    code: 'USER_NOT_FOUND',
    message: "On dirait que tu n'as pas encore de compte chez nous...",
  },
};

export default expectedErrors;
