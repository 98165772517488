import { Collapse, Container } from '@mui/material';
import React from 'react';

import HeaderContent from './HeaderContent';

function MobileMenu({ onClose, open }) {
  return (
    <Collapse in={open}>
      <Container sx={{ py: 2 }}>
        <HeaderContent onClose={onClose} />
      </Container>
    </Collapse>
  );
}

export default MobileMenu;
