import React, { createContext, useContext, useMemo } from 'react';
import expectedErrors from 'src/graphql/file/expectedErrors';
import {
  ASSERT_DRIVING_LICENSE,
  CREATE_FILE,
  PREDICT_BANK_STATEMENT,
  PREDICT_DRIVING_LICENSE,
  UPLOAD_AVATAR,
  UPLOAD_KYC_DOCUMENT,
  UPLOAD_SELFIE,
  UPLOAD_SWORN_STATEMENT,
} from 'src/graphql/file/mutations';
import { useSafeMutation } from 'src/services/apollo-client/wrappers';

const FileContext = createContext();

const useFile = () => useContext(FileContext);

function FileProvider({ children }) {
  const [predictBankStatement, { data: bankStatementPrediction, ...predictBankStatementState }] =
    useSafeMutation(
      PREDICT_BANK_STATEMENT,
      {
        displaySnackbar: false,
        refetchQueries: ['me'],
      },
      {
        silentError: true,
      }
    );

  const [predictDrivingLicense, { data: prediction, ...predictDrivingLicenseState }] =
    useSafeMutation(
      PREDICT_DRIVING_LICENSE,
      {
        displaySnackbar: false,
        refetchQueries: ['me'],
      },
      {
        silentError: true,
      }
    );

  const [assertDrivingLicense, { ...assertDrivingLicenseState }] = useSafeMutation(
    ASSERT_DRIVING_LICENSE,
    {
      displaySnackbar: false,
      refetchQueries: ['me'],
    },
    {
      expectedErrors: [expectedErrors.DRIVING_LICENSE_ALREADY_EXISTS],
      silentError: false,
    }
  );

  const [uploadKYCDocument, { ...uploadKYCDocumentState }] = useSafeMutation(
    UPLOAD_KYC_DOCUMENT,
    {
      displaySnackbar: false,
      refetchQueries: ['me'],
    },
    {
      silentError: true,
    }
  );

  const [createFile, { data: file, ...createFileState }] = useSafeMutation(
    CREATE_FILE,
    {
      displaySnackbar: false,
      refetchQueries: ['me'],
    },
    {
      silentError: true,
    }
  );

  const [uploadSelfie, uploadSelfieState] = useSafeMutation(
    UPLOAD_SELFIE,
    {
      displaySnackbar: false,
      refetchQueries: ['me'],
    },
    {
      expectedErrors: [expectedErrors.INVALID_SELFIE],
      silentError: false,
    }
  );

  const [uploadAvatar, uploadAvatarState] = useSafeMutation(
    UPLOAD_AVATAR,
    {
      displaySnackbar: false,
      refetchQueries: ['me'],
    },
    {
      silentError: true,
    }
  );

  const [uploadSwornStatement, uploadSwornStatementState] = useSafeMutation(
    UPLOAD_SWORN_STATEMENT,
    {
      displaySnackbar: false,
      refetchQueries: ['me'],
    },
    {
      expectedErrors: [expectedErrors.INVALID_SELFIE],
      silentError: false,
    }
  );

  const memoizedContextValue = useMemo(
    () => ({
      assertDrivingLicense,
      assertDrivingLicenseState,
      bankStatementPrediction: bankStatementPrediction?.predictBankStatement,
      createFile,
      createFileState,
      file,
      predictBankStatement,
      predictBankStatementState,
      predictDrivingLicense,
      predictDrivingLicenseState,
      prediction: prediction?.predictDrivingLicense,
      uploadAvatar,
      uploadAvatarState,
      uploadKYCDocument,
      uploadKYCDocumentState,
      uploadSelfie,
      uploadSelfieState,
      uploadSwornStatement,
      uploadSwornStatementState,
    }),
    [
      assertDrivingLicense,
      assertDrivingLicenseState,
      bankStatementPrediction?.predictBankStatement,
      createFile,
      createFileState,
      file,
      predictBankStatement,
      predictBankStatementState,
      predictDrivingLicense,
      predictDrivingLicenseState,
      prediction?.predictDrivingLicense,
      uploadAvatar,
      uploadAvatarState,
      uploadKYCDocument,
      uploadKYCDocumentState,
      uploadSelfie,
      uploadSelfieState,
      uploadSwornStatement,
      uploadSwornStatementState,
    ]
  );

  return <FileContext.Provider value={memoizedContextValue}>{children}</FileContext.Provider>;
}

export { FileProvider, useFile };
