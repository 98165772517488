export default function IconButton(theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
        sizeLarge: {
          fontSize: '1.25rem',
          height: theme.spacing(5.5),
          width: theme.spacing(5.5),
        },
        sizeMedium: {
          fontSize: '1rem',
          height: theme.spacing(4.5),
          width: theme.spacing(4.5),
        },
        sizeSmall: {
          fontSize: '0.75rem',
          height: theme.spacing(3.75),
          width: theme.spacing(3.75),
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            '&:focus': {
              background: theme.palette.common.black,
              color: theme.palette.primary.contrastText,
            },
            '&:hover': {
              background: theme.palette.common.black,
              color: theme.palette.primary.contrastText,
            },
            background: theme.palette.common.black,
            borderRadius: theme.shape.borderRadius * 2,
            color: theme.palette.primary.contrastText,
          },
        },
      ],
    },
  };
}
