// ==============================|| OVERRIDES - DIALOG CONTENT TEXT ||============================== //

export default function Popover(theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.z1,
        },
        root: {
          '&.MuiMenu-root .MuiPaper-root': {
            marginTop: 8,
          },
        },
      },
    },
  };
}
