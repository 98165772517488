import { PATH_LANDING } from 'src/routes/paths';

const resourceItems = [
  {
    label: "Conditions Générales d'Utilisation",
    name: 'cgu',
    url: PATH_LANDING.cgu,
  },
  {
    label: 'Politique de Confidentialité',
    name: 'privacy-policy',
    url: PATH_LANDING.privacyPolicy,
  },
];

export default resourceItems;
