/* eslint-disable no-param-reassign */
// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

// initial state
const initialState = {
  componentDrawerOpen: true,
  drawerOpen: false,
  error: null,
  menu: {},
  openComponent: 'buttons',
  openItem: ['dashboard'],
  selectedID: null,
};

export const fetchMenu = createAsyncThunk('', async () => {
  const response = await axios.get('/api/menu/dashboard');
  return response.data;
});

const menu = createSlice({
  extraReducers(builder) {
    builder.addCase(fetchMenu.fulfilled, (state, action) => {
      state.menu = action.payload.dashboard;
    });
  },
  initialState,
  name: 'menu',

  reducers: {
    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    activeID(state, action) {
      state.selectedID = action.payload;
    },

    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    hasError(state, action) {
      state.error = action.payload;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload;
    },
  },
});

export default menu.reducer;

export const { activeComponent, activeID, activeItem, openComponentDrawer, openDrawer } =
  menu.actions;
