import { Box } from '@mui/material';
// material-ui
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

// ==============================|| DRAWER HEADER - STYLED ||============================== //

const DrawerHeaderStyled = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ open, theme }) => ({
    ...theme.mixins.toolbar,
    alignItems: 'center',
    display: 'flex',
    justifyContent: open ? 'flex-start' : 'center',
    paddingLeft: theme.spacing(open ? 3 : 0),
  })
);

DrawerHeaderStyled.propTypes = {
  open: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object,
};

export default DrawerHeaderStyled;
