import { ExpandMore } from '@mui/icons-material';
import { Collapse, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

function FAQItem({ answer, question }) {
  const [itemOpen, setItemOpen] = useState(false);

  return (
    <>
      <ListItemButton onClick={() => setItemOpen(!itemOpen)} selected={itemOpen}>
        <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={1}>
          <ListItemText primary={question} />

          <ExpandMore sx={{ color: 'text.secondary', fontSize: 12 }} />
        </Stack>
      </ListItemButton>
      <Collapse in={itemOpen}>
        <ListItem>
          <Typography color="text.secondary" variant="caption">
            {answer}
          </Typography>
        </ListItem>
      </Collapse>
    </>
  );
}

export default FAQItem;
