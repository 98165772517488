import { gql } from '@apollo/client';

const carFragment = gql`
  fragment Car on Car {
    id
    brandName
    modelName
    registrationNumber
  }
`;

export default carFragment;
