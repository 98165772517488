// assets
import { CheckSquareFilled, MinusSquareFilled } from '@ant-design/icons';
// material-ui
import { Box } from '@mui/material';
import React from 'react';

// project import
import getColors from '../../utils/getColors';

// ==============================|| RADIO - COLORS ||============================== //

function getColorStyle({ color, theme }) {
  const colors = getColors(theme, color);
  const { dark, lighter, main } = colors;

  return {
    '&.Mui-focusVisible': {
      outline: `2px solid ${dark}`,
      outlineOffset: -4,
    },
    '&:hover': {
      '& .icon': {
        borderColor: main,
      },
      backgroundColor: lighter,
    },
  };
}

// ==============================|| CHECKBOX - SIZE STYLE ||============================== //

function getSizeStyle(size) {
  switch (size) {
    case 'small':
      return { fontSize: 1, position: 1, size: 16 };
    case 'large':
      return { fontSize: 1.6, position: 2, size: 24 };
    case 'medium':
    default:
      return { fontSize: 1.35, position: 2, size: 20 };
  }
}

// ==============================|| CHECKBOX - STYLE ||============================== //

function checkboxStyle(size) {
  const sizes = getSizeStyle(size);

  return {
    '& .icon': {
      '& .filled': {
        fontSize: `${sizes.fontSize}rem`,
        left: -sizes.position,
        top: -sizes.position,
      },
      height: sizes.size,
      width: sizes.size,
    },
  };
}

// ==============================|| OVERRIDES - CHECKBOX ||============================== //

export default function Checkbox(theme) {
  const { palette } = theme;

  return {
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: (
          <Box
            className="icon"
            sx={{
              border: '1px solid',
              borderColor: 'inherit',
              borderRadius: 0.25,
              height: 16,
              position: 'relative',
              width: 16,
            }}
          >
            <CheckSquareFilled className="filled" style={{ position: 'absolute' }} />
          </Box>
        ),
        className: 'size-small',
        icon: (
          <Box
            className="icon"
            sx={{
              border: '1px solid',
              borderColor: 'inherit',
              borderRadius: 0.25,
              height: 16,
              width: 16,
            }}
          />
        ),
        indeterminateIcon: (
          <Box
            className="icon"
            sx={{
              border: '1px solid',
              borderColor: 'inherit',
              borderRadius: 0.25,
              height: 16,
              position: 'relative',
              width: 16,
            }}
          >
            <MinusSquareFilled className="filled" style={{ position: 'absolute' }} />
          </Box>
        ),
      },
      styleOverrides: {
        colorError: getColorStyle({ color: 'error', theme }),
        colorInfo: getColorStyle({ color: 'info', theme }),
        colorPrimary: getColorStyle({ color: 'primary', theme }),
        colorSecondary: getColorStyle({ color: 'secondary', theme }),
        colorSuccess: getColorStyle({ color: 'success', theme }),
        colorWarning: getColorStyle({ color: 'warning', theme }),
        root: {
          '&.size-large': {
            ...checkboxStyle('large'),
          },
          '&.size-medium': {
            ...checkboxStyle('medium'),
          },
          '&.size-small': {
            ...checkboxStyle('small'),
          },
          borderRadius: 0,
          color: palette.secondary[300],
        },
      },
    },
  };
}
