import React from 'react';
import PLATFORM, { PLATFORMS } from 'src/utils/platforms';

import AndroidHandler from './AndroidHandler';
import ClassicHandler from './ClassicHandler';

function Handler(props) {
  const isAndroidDevice = PLATFORM.OS === PLATFORMS.ANDROID;

  if (!isAndroidDevice) return <ClassicHandler {...props} />;

  return <AndroidHandler {...props} />;
}

export default Handler;
