import React, { createContext, useContext, useMemo, useState } from 'react';

const PullToRefreshContext = createContext();

const usePullToRefresh = () => useContext(PullToRefreshContext);

function PullToRefreshProvider({ children }) {
  const [active, setActive] = useState(true);

  const memoizedContextValue = useMemo(
    () => ({
      active,
      setActive,
    }),
    [active, setActive]
  );

  return (
    <PullToRefreshContext.Provider value={memoizedContextValue}>
      {children}
    </PullToRefreshContext.Provider>
  );
}

export { PullToRefreshProvider, usePullToRefresh };
