import { Stack, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import React from 'react';
import geoLocationAnimationData from 'src/assets/animations/geolocation.json';

function Step5Content() {
  return (
    <Stack alignItems="center" spacing={3}>
      <Lottie animationData={geoLocationAnimationData} loop />
      <Typography variant>
        Assure toi d&apos;avoir bien activé la géolocalisation! Ça serait dommage de passer à côté
        de ta prime pour si peu...
      </Typography>
    </Stack>
  );
}

export default Step5Content;
