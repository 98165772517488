import {
  CheckCircleOutlined as CheckOutlinedIcon,
  ErrorOutlineOutlined as ErrorOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
  WarningAmberOutlined as WarningOutlinedIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';

import { useSelector } from '../../store';

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  '&.notistack-MuiContent': {
    padding: theme.spacing(1, 1.5),
    zIndex: theme.zIndex.drawer + 9,
  },
  '&.notistack-MuiContent-default': {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.background.default,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.background.default,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.background.default,
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.background.default,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.background.default,
  },
}));

function Notistack({ children }) {
  const snackbar = useSelector((state) => state.snackbar);
  const iconSX = { fontSize: '1.15rem', marginRight: 1 };

  return (
    <StyledSnackbarProvider
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      dense={snackbar.dense}
      hideIconVariant={snackbar.iconVariant === 'hide'}
      iconVariant={{
        error: <ErrorOutlinedIcon color="error" sx={iconSX} />,
        info: <InfoOutlinedIcon color="info" sx={iconSX} />,
        success: <CheckOutlinedIcon color="success" sx={iconSX} />,
        warning: <WarningOutlinedIcon color="warning" sx={iconSX} />,
      }}
      maxSnack={1}
      variant="success"
    >
      {children}
    </StyledSnackbarProvider>
  );
}

Notistack.propTypes = {
  children: PropTypes.node,
};

export default Notistack;
