import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import Lottie from 'lottie-react';
import React from 'react';
import mapAnimationData from 'src/assets/animations/map.json';
import { useConnectivity } from 'src/contexts/ConnectivityContext';

import MobileSheet from './MobileSheet';
import PageWrapper from './PageWrapper';

function NoInternetHandler() {
  const { handleRefresh, isOnline, isRefreshing } = useConnectivity();

  return (
    <MobileSheet
      height="auto"
      notSwipeable
      onClose={() => null}
      open={!isOnline}
      sx={{ overflowY: 'hidden' }}
    >
      <PageWrapper
        subtitle="Vérifie ta connexion internet"
        title={"Tu n'es plus connecté"}
        withoutLogo
      >
        <Stack alignItems="center" zIndex={1}>
          <LoadingButton
            loading={isRefreshing}
            onClick={handleRefresh}
            size="small"
            variant="outlined"
          >
            Rafraîchir
          </LoadingButton>
        </Stack>
        <Box
          height={200}
          sx={{ display: 'flex', justifyContent: 'center', zIndex: 0 }}
          width="100%"
        >
          <Box height={400} sx={{ bottom: -168, position: 'absolute' }} width="100%">
            <Lottie
              animationData={mapAnimationData}
              autoPlay
              loop
              style={{ height: '100%', width: '100%' }}
            />
          </Box>
        </Box>
      </PageWrapper>
    </MobileSheet>
  );
}

export default NoInternetHandler;
