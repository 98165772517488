import { gql } from '@apollo/client';

import { addressFragment, carFragment, conductorFragment, tripFragment } from '../fragments';

const CREATE_TRIP = gql`
  mutation createTrip($input: CreateTripInput!) {
    createTrip(input: $input) {
      ...Trip

      conductor {
        ...Conductor
      }

      car {
        ...Car
      }

      startAddress {
        ...Address
      }

      endAddress {
        ...Address
      }
    }
  }
  ${tripFragment}
  ${conductorFragment}
  ${carFragment}
  ${addressFragment}
`;

const ABORT_TRIP = gql`
  mutation abortTrip($id: ID!) {
    abortTrip(id: $id) {
      ...Trip

      conductor {
        ...Conductor
      }

      car {
        ...Car
      }

      startAddress {
        ...Address
      }

      endAddress {
        ...Address
      }
    }
  }
  ${tripFragment}
  ${conductorFragment}
  ${carFragment}
  ${addressFragment}
`;

const START_TRIP = gql`
  mutation startTrip($input: StartTripInput!) {
    startTrip(input: $input) {
      ...Trip

      conductor {
        ...Conductor
      }

      car {
        ...Car
      }

      startAddress {
        ...Address
      }

      endAddress {
        ...Address
      }
    }
  }
  ${tripFragment}
  ${conductorFragment}
  ${carFragment}
  ${addressFragment}
`;

const END_TRIP = gql`
  mutation endTrip($input: EndTripInput!) {
    endTrip(input: $input) {
      ...Trip

      conductor {
        ...Conductor
      }

      car {
        ...Car
      }

      startAddress {
        ...Address
      }

      endAddress {
        ...Address
      }
    }
  }
  ${tripFragment}
  ${conductorFragment}
  ${carFragment}
  ${addressFragment}
`;

export { CREATE_TRIP, ABORT_TRIP, START_TRIP, END_TRIP };
