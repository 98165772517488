import { useEffect } from 'react';
import { useSEOContext } from 'src/contexts/SEOContext';

const useSEO = ({ description, keywords, title, withoutBaseTitle = false }) => {
  const { setDescription, setKeywords, setTitle } = useSEOContext();

  useEffect(() => {
    setTitle(title, withoutBaseTitle);
    setDescription(description);
    setKeywords(keywords);
  }, [description, keywords, setDescription, setKeywords, setTitle, title, withoutBaseTitle]);
};

export default useSEO;
