import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Logo from 'src/components/logo/Logo';
import { MenuOrientation } from 'src/config';
import useConfig from 'src/hooks/useConfig';
import useResponsive from 'src/hooks/useResponsive';

import DrawerHeaderStyled from './DrawerHeaderStyled';

function DrawerHeader({ open }) {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'md');

  const { menuOrientation } = useConfig();
  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !isMobile;

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <DrawerHeaderStyled
        open={open}
        sx={{
          minHeight: isHorizontal ? 'unset' : '60px',
          overflow: 'hidden',
          paddingBottom: isHorizontal ? { lg: '0', xs: '18px' } : '8px',
          // eslint-disable-next-line no-nested-ternary
          paddingLeft: isHorizontal ? { lg: '0', xs: '24px' } : open ? '24px' : 0,
          paddingTop: isHorizontal ? { lg: '0', xs: '10px' } : '8px',
          width: isHorizontal ? { lg: '424px', xs: '100%' } : 'inherit',
        }}
        theme={theme}
      >
        <Logo withBrandName />
      </DrawerHeaderStyled>
    </Box>
  );
}

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
