// eslint-disable-next-line import/no-extraneous-dependencies
import { datadogLogs } from '@datadog/browser-logs';
import React, { createContext, useContext, useMemo } from 'react';
import config from 'src/config/index';
import isDevelopmentMode from 'src/utils/isDevelopmentMode';

const { clientToken, env, forwardErrorsToLogs, sampleRate, site } = config.datadog;

// Init datadog logger
datadogLogs.init({
  clientToken,
  forwardErrorsToLogs,
  sampleRate,
  site,
});

// Define environment
datadogLogs.setGlobalContext({ env });

const LoggerContext = createContext();

const useLogger = () => useContext(LoggerContext);

function LoggerProvider({ children }) {
  const loggerTransport = isDevelopmentMode() ? console : datadogLogs.logger;

  const memoizedContextValue = useMemo(
    () => ({
      logger: loggerTransport,
      setGlobalContext: (context) =>
        datadogLogs.setGlobalContext({
          ...datadogLogs.getGlobalContext(),
          ...context,
        }),
    }),
    [loggerTransport]
  );

  return <LoggerContext.Provider value={memoizedContextValue}>{children}</LoggerContext.Provider>;
}

export { LoggerProvider, useLogger };
