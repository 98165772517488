import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useLocation } from 'react-router';
import useResponsive from 'src/hooks/useResponsive';

const variants = {
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.9 },
  initial: { opacity: 0, scale: 0.9 },
};

function PageTransition({ children }) {
  const isMobile = useResponsive('down', 'md');
  const location = useLocation();

  if (!isMobile) return children;

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        animate="animate"
        exit="exit"
        initial="initial"
        style={{ minHeight: 'calc(100vh - 112px)' }}
        transition={{ duration: 0.2 }}
        variants={variants}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}

export default PageTransition;
