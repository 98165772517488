/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  action: false,
  actionButton: false,
  alert: {
    color: 'primary',
    variant: 'filled',
  },
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
  close: true,
  dense: false,
  iconVariant: 'usedefault',
  maxStack: 3,
  message: 'Note archived',
  open: false,
  transition: 'Fade',
  variant: 'default',
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const snackbar = createSlice({
  initialState,
  name: 'snackbar',
  reducers: {
    closeSnackbar(state) {
      state.open = false;
    },

    handlerDense(state, action) {
      const { dense } = action.payload;
      state.dense = dense;
    },
    handlerIconVariants(state, action) {
      const { iconVariant } = action.payload;
      state.iconVariant = iconVariant;
    },
    handlerIncrease(state, action) {
      const { maxStack } = action.payload;
      state.maxStack = maxStack;
    },
    openSnackbar(state, action) {
      const { actionButton, alert, anchorOrigin, close, message, open, transition, variant } =
        action.payload;

      state.action = !state.action;
      state.open = open || initialState.open;
      state.message = message || initialState.message;
      state.anchorOrigin = anchorOrigin || initialState.anchorOrigin;
      state.variant = variant || initialState.variant;
      state.alert = {
        color: alert?.color || initialState.alert.color,
        variant: alert?.variant || initialState.alert.variant,
      };
      state.transition = transition || initialState.transition;
      state.close = close === false ? close : initialState.close;
      state.actionButton = actionButton || initialState.actionButton;
    },
  },
});

export default snackbar.reducer;

export const { closeSnackbar, handlerDense, handlerIconVariants, handlerIncrease, openSnackbar } =
  snackbar.actions;
