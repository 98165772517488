import { gql } from '@apollo/client';

const GET_MY_PRE_ELIGIBILITY = gql`
  query getMyPreEligibility {
    getMyPreEligibility {
      isRpcPreCheckValidForShortDistance
      isRpcPreCheckValidForLongDistance
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_MY_PRE_ELIGIBILITY };
