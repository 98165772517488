// material-ui
import { Box } from '@mui/material';
import React from 'react';

import Profile from './Profile';

function HeaderContent() {
  return (
    <>
      <Box width="100%" />

      <Profile />
    </>
  );
}

export default HeaderContent;
