import React from 'react';
import MobileSheet from 'src/components/MobileSheet';
import { JourneyProvider } from 'src/contexts/JourneyContext';

import TakenInChargeMode from './TakenInChargeMode';
import WaitingForDecision from './WaitingForDecision';

function PassengerMode({ journey, trip }) {
  const isInTripMode =
    trip.hasDriverStarted && !(journey.hasDeclinedTakingCharge || journey.hasConfirmedDeposit);

  return (
    <JourneyProvider>
      <MobileSheet fullHeight notSwipeable open={isInTripMode}>
        {!journey.hasConfirmedTakingCharge && <WaitingForDecision journey={journey} trip={trip} />}
        {journey.hasConfirmedTakingCharge && <TakenInChargeMode journey={journey} trip={trip} />}
      </MobileSheet>
    </JourneyProvider>
  );
}

export default PassengerMode;
