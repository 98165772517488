import { useEffect } from 'react';
import config from 'src/config/index';

const { apiKey } = config.google;

const SCRIPT_ID = 'google-autocomplete-script';

const GoogleAutocompleteScript = () => {
  useEffect(() => {
    const existingScript = document.getElementById(SCRIPT_ID);

    if (existingScript) return;

    const script = document.createElement('script');
    script.setAttribute('id', SCRIPT_ID);
    script.setAttribute(
      'src',
      `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
    );

    document.head.append(script);
  }, []);
};

export default GoogleAutocompleteScript;
