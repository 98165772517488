import { ArrowBackOutlined, CloseOutlined } from '@mui/icons-material';
import { Box, Container, IconButton, Stack, Typography } from '@mui/material';
import { trimEnd } from 'lodash';
import React, { useMemo } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { APP_ROOT_PATH, LANDING_ROOT_PATH } from 'src/routes/paths';

import Logo from './logo/Logo';

function PageWrapper({
  action,
  children,
  disableGutters,
  goBackTo,
  maxWidth,
  subtitle,
  title,
  withCloseIcon,
  withoutLogo,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const isGoBackAFunction = typeof goBackTo === 'function';
  const shouldDisplayGoBackButton = Boolean(goBackTo) && !withCloseIcon;
  const shouldDisplayCloseButton = Boolean(goBackTo) && withCloseIcon;

  const goBackLocation = useMemo(() => {
    if (isGoBackAFunction) return goBackTo;
    if (typeof goBackTo === 'string') return goBackTo;

    // eslint-disable-next-line no-restricted-globals
    if (history.length > 2) return goBackTo;

    const rootPathWithoutTrailingSlash = trimEnd(APP_ROOT_PATH, '/');
    const isOnApp = location.pathname.indexOf(rootPathWithoutTrailingSlash) > -1;

    return isOnApp ? APP_ROOT_PATH : LANDING_ROOT_PATH;
  }, [goBackTo, isGoBackAFunction, location.pathname]);

  const handleGoBack = () => {
    if (isGoBackAFunction) {
      goBackLocation();
      return;
    }

    navigate(goBackLocation);
  };

  return (
    <Container disableGutters={disableGutters} maxWidth={maxWidth} position="relative">
      <Stack alignItems="center" spacing={6} width="100%">
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack direction="row" flex={1} justifyContent="flex-start">
            {shouldDisplayGoBackButton && (
              <IconButton LinkComponent={RouterLink} onClick={handleGoBack}>
                <ArrowBackOutlined />
              </IconButton>
            )}
            {!shouldDisplayGoBackButton && <IconButton disabled />}
          </Stack>
          <Stack alignItems="center" spacing={2}>
            {!withoutLogo && <Logo size={92} />}
            {title && (
              <Typography align="center" variant="h3">
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography align="center" variant="caption">
                {subtitle}
              </Typography>
            )}
          </Stack>
          <Stack direction="row" flex={1} justifyContent="flex-end">
            {action && action}
            {shouldDisplayCloseButton && (
              <IconButton LinkComponent={RouterLink} onClick={handleGoBack}>
                <CloseOutlined />
              </IconButton>
            )}
            {!shouldDisplayCloseButton && !action && <IconButton disabled />}
          </Stack>
        </Stack>
      </Stack>
      <Box mt={5}>{children}</Box>
    </Container>
  );
}

PageWrapper.defaultProps = {
  maxWidth: 'xs',
};

export default PageWrapper;
