import React, { Suspense } from 'react';

import AnimatedContainer from './AnimatedContainer';
import Section from './landing/Section';
// project import
import Loader from './Loader';

const Loadable = (Component, animate = true) =>
  // eslint-disable-next-line react/display-name
  function (props) {
    return (
      <Suspense
        fallback={
          <Section centeredContent>
            <Loader />
          </Section>
        }
      >
        <AnimatedContainer animate={animate}>
          <Component {...props} />
        </AnimatedContainer>
      </Suspense>
    );
  };

export default Loadable;
