import { Close as CloseIcon } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  MenuItem,
  Popper,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FadedBorder from 'src/components/FadedBorder';
import MobileSheet from 'src/components/MobileSheet';
import useResponsive from 'src/hooks/useResponsive';
import safeCallback from 'src/utils/safeCallback';

function AutoCompleteField({
  PopperComponent: PopperCompleteField,
  defaultValue,
  handleClose,
  onChange,
  options,
  popperWidth,
  renderOption,
  selectedOption,
  ...props
}) {
  return (
    <Autocomplete
      autoHighlight
      blurOnSelect
      clearOnBlur
      defaultValue={defaultValue}
      fullWidth
      getOptionLabel={(option) => option?.label || ''}
      noOptionsText="Aucun résultat"
      onChange={(event, newValue, reason) => {
        if (reason === 'clear') {
          return;
        }
        safeCallback(onChange, newValue?.value);
      }}
      onClose={handleClose}
      open
      options={options}
      PopperComponent={PopperCompleteField}
      {...props}
      renderInput={(params) => (
        <Box p={1} sx={{ display: 'block', width: popperWidth }}>
          <TextField
            {...params}
            fullWidth
            inputProps={{
              ...params.inputProps,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              ...params.InputProps,
              endAdornment: null,
            }}
            placeholder="Rechercher"
          />
        </Box>
      )}
      renderOption={(optionProps, option) => (
        <MenuItem {...optionProps}>{renderOption(option) || option.label}</MenuItem>
      )}
      renderTags={() => null}
      selectOnFocus
      value={selectedOption || ''}
    />
  );
}

function PopperComponent(props) {
  // eslint-disable-next-line no-unused-vars
  const { anchorEl, disablePortal, open, ...other } = props;
  return <div {...other} />;
}

function MobilePopperComponent(props) {
  // eslint-disable-next-line no-unused-vars
  const { anchorEl, children, disablePortal, open, ...other } = props;
  return (
    <Box
      {...other}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'background.default',
          boxShadow: 'none',
          height: 336,
        },
        ml: 1,
        position: 'relative',
      }}
    >
      {children}
      <FadedBorder height="100%" left={0} to="left" top={0} width={10} />
      <FadedBorder height="100%" right={0} to="right" top={0} width={10} />
      <FadedBorder height={25} left={0} to="top" top={0} width="100%" />
      <FadedBorder bottom={0} height={25} left={0} to="bottom" width="100%" />
    </Box>
  );
}

function RHFAutocomplete({
  defaultValue,
  disablePortal,
  error,
  helperText,
  name,
  onChange,
  options,
  placeholder,
  renderInputValue,
  renderOption,
  required,
  ...props
}) {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'md');
  const inputRef = useRef();
  const selectRef = useRef();
  const { watch } = useFormContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperWidth, setPopperWidth] = useState(250);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getOption = (optionValue) => options.find((option) => option.value === optionValue);
  const selectedOption = getOption(watch(name));

  useEffect(() => {
    if (selectRef.current) selectRef.current.blur();
  }, [selectedOption]);

  useEffect(() => {
    if (inputRef.current) {
      const inputRefRect = inputRef.current.getBoundingClientRect();
      setPopperWidth(inputRefRect.width);
    }
  }, []);

  return (
    <>
      <TextField
        ref={inputRef}
        error={error}
        fullWidth
        helperText={helperText}
        InputProps={{
          endAdornment: selectedOption && (
            <InputAdornment position="end">
              <IconButton
                onClick={(event) => {
                  // Trigger open when clear
                  if (!required) event.stopPropagation();
                  safeCallback(onChange, null);
                }}
                size="small"
                tabIndex="-1"
              >
                <CloseIcon sx={{ fontSize: '14px' }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onClick={handleClick}
        placeholder={placeholder}
        value={(selectedOption && renderInputValue(selectedOption)) || ''}
        variant="outlined"
      />

      {!isMobile && (
        <Popper
          anchorEl={anchorEl}
          disablePortal
          open={open}
          placement="bottom-start"
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: 'background.default',
              backgroundImage: 'none',
              boxShadow: 'none',
              maxHeight: 300,
            },
            backgroundColor: 'background.paper',
            borderRadius: `${theme.shape.borderRadius}px`,
            boxShadow: theme.shadows[6],
            transform: 'translateY(16px)',
            zIndex: 9,
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Stack alignItems="center" width="100%">
              <AutoCompleteField
                defaultValue={defaultValue}
                handleClose={handleClose}
                onChange={onChange}
                options={options}
                PopperComponent={PopperComponent}
                popperWidth={popperWidth}
                renderOption={renderOption}
                selectedOption={selectedOption}
                {...props}
              />
            </Stack>
          </ClickAwayListener>
        </Popper>
      )}
      {isMobile && (
        <MobileSheet disablePortal={disablePortal} height="auto" onClose={handleClose} open={open}>
          <AutoCompleteField
            defaultValue={defaultValue}
            handleClose={handleClose}
            onChange={onChange}
            options={options}
            PopperComponent={MobilePopperComponent}
            popperWidth="100%"
            renderOption={renderOption}
            selectedOption={selectedOption}
            {...props}
          />
        </MobileSheet>
      )}
    </>
  );
}

function RHFSelectSearchable({ disablePortal, helperText, name, searchable, ...props }) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <RHFAutocomplete
          disablePortal={disablePortal}
          error={!!error}
          helperText={error?.message || helperText}
          name={name}
          searchable={searchable}
          {...field}
          {...props}
        />
      )}
    />
  );
}

RHFSelectSearchable.defaultProps = {
  disablePortal: false,
  options: [],
  renderInputValue: ({ label: optionLabel }) => optionLabel,
  renderOption: ({ label: optionLabel }) => optionLabel,
};

export default RHFSelectSearchable;
