import { Container, Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import SocialIcons from 'src/components/landing/SocialIcons';
import UnderlinedText from 'src/components/UnderlinedText';

import discoverItems from '../discoverItems';
import menuItems from '../Header/menuItems';
import resourceItems from '../resourceItems';
import FakePhoneFAQ from './FakePhoneFAQ';

function LandingLink({ children, to }) {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      component={RouterLink}
      sx={{
        '&:hover': {
          color: 'primary.main',
          textDecoration: 'none',
        },
        color: 'text.primary',
        marginLeft: 0,
        px: 1,
        textAlign: { sm: 'left', xs: 'center' },
        transition: 'all 0.25s ease',
      }}
      to={to}
    >
      {children}
    </Link>
  );
}

function Footer() {
  if (localStorage.getItem('load-in-webview') === 'true') return null;

  return (
    <Container component="footer">
      <Grid container justifyContent={{ md: 'flex-start', xs: 'space-around' }}>
        <Grid item pb={12} pt={{ sm: 12, xs: 0 }} sm={3} xs={12}>
          <Stack alignItems={{ sm: 'flex-start', xs: 'center' }} spacing={4}>
            <Typography>
              <UnderlinedText>Découvrir</UnderlinedText>
            </Typography>
            <Stack spacing={1.5}>
              <Stack spacing={2}>
                {discoverItems.map(({ label, name, url }) => (
                  <LandingLink key={name} to={url}>
                    {label}
                  </LandingLink>
                ))}
              </Stack>

              <SocialIcons />
            </Stack>
          </Stack>
        </Grid>
        <Grid item pb={12} pt={{ sm: 12, xs: 0 }} sm={3} xs={12}>
          <Stack alignItems={{ sm: 'flex-start', xs: 'center' }} spacing={4}>
            <Typography>
              <UnderlinedText>Parcourir</UnderlinedText>
            </Typography>
            <Stack spacing={2}>
              {menuItems
                .filter(({ noFooter }) => !noFooter)
                .map(({ label, name, url }) => (
                  <LandingLink key={name} to={url}>
                    {label}
                  </LandingLink>
                ))}
            </Stack>
          </Stack>
        </Grid>
        <Grid item pb={12} pt={{ sm: 12, xs: 0 }} sm={3} xs={12}>
          <Stack alignItems={{ sm: 'flex-start', xs: 'center' }} spacing={4}>
            <Typography>
              <UnderlinedText>Ressources</UnderlinedText>
            </Typography>
            <Stack spacing={1.5}>
              <Stack spacing={2}>
                {resourceItems.map(({ label, name, url }) => (
                  <LandingLink key={name} to={url}>
                    {label}
                  </LandingLink>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          pt={{ sm: 12, xs: 28 }}
          sm={3}
          sx={{
            height: { sm: 'auto', xs: 350 },
            overflow: 'hidden',
            position: 'relative',
          }}
          xs={12}
        >
          <FakePhoneFAQ />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Footer;
