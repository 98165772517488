import { enUS, frFR } from '@mui/material/locale';
import { useTranslation } from 'react-i18next';

const LANGS = [
  {
    flagUrl: '/static/countries/fr.svg',
    hasTranslations: true,
    label: 'Français',
    systemValue: frFR,
    value: 'fr',
  },
  {
    flagUrl: '/static/countries/us.svg',
    label: 'English',
    systemValue: enUS,
    value: 'en',
  },
];

const useLocales = () => {
  const { i18n, t: translate } = useTranslation();
  const currentLang = LANGS[0];

  const handleChangeLanguage = (newLang) => {
    i18n.changeLanguage(newLang);
  };

  return {
    allLang: LANGS,
    currentLang,
    onChangeLang: handleChangeLanguage,
    translate,
  };
};

export default useLocales;
