import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { DRAWER_WIDTH } from 'src/config';

const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ isLanding, open, theme }) => ({
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
    zIndex: theme.zIndex.drawer + 1,
    ...(!open && {
      width: !isLanding ? `calc(100% - ${theme.spacing(7.5)})` : 0,
    }),
    ...(open && {
      marginLeft: !isLanding ? DRAWER_WIDTH : 0,
      transition: theme.transitions.create(['width', 'margin'], {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
      }),
    }),
  })
);

AppBarStyled.propTypes = {
  open: PropTypes.bool,
};

export default AppBarStyled;
