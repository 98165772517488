import { enqueueSnackbar } from 'notistack';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import config from 'src/config/index';

const { apiUrlHttp } = config;

const checkOnlineStatus = async () => {
  try {
    const response = await fetch(apiUrlHttp, {
      cache: 'no-store',
      method: 'HEAD',
    });

    // Ping API, expect a 405 Not Allowed
    return response.status === 405;
  } catch (error) {
    return false;
  }
};

const ConnectivityContext = createContext();

const useConnectivity = () => useContext(ConnectivityContext);

function ConnectivityProvider({ children }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isRefreshing, setRefreshing] = useState(false);

  const handleRefresh = useCallback(async () => {
    setRefreshing(true);
    const status = await checkOnlineStatus();
    if (status) {
      setRefreshing(false);
    } else {
      enqueueSnackbar('Aïe aïe aïe, y a rien à faire pour le moment...', { variant: 'error' });
      setRefreshing(false);
    }
  }, []);

  useEffect(() => {
    const updateOnlineStatus = () => {
      const online = navigator.onLine;
      if (online) enqueueSnackbar('Ta connexion est revenue!', { variant: 'success' });

      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  const memoizedContextValue = useMemo(
    () => ({
      handleRefresh,
      isOnline,
      isRefreshing,
    }),
    [handleRefresh, isOnline, isRefreshing]
  );

  return (
    <ConnectivityContext.Provider value={memoizedContextValue}>
      {children}
    </ConnectivityContext.Provider>
  );
}

export { ConnectivityProvider, useConnectivity };
