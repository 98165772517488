import { Facebook, Instagram, Twitter } from '@mui/icons-material';
import { IconButton, Link, Stack } from '@mui/material';
import React from 'react';

function SocialIcons() {
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent={{ sm: 'flex-start', xs: 'center' }}
      spacing={1}
    >
      <IconButton
        color="primary"
        href="https://x.com/Coccinelle_cov"
        LinkComponent={Link}
        size="small"
        target="_blank"
      >
        <Twitter sx={{ fontSize: 20 }} />
      </IconButton>
      <IconButton
        color="primary"
        href="https://www.facebook.com/profile.php?id=61559179743972"
        LinkComponent={Link}
        size="small"
        target="_blank"
      >
        <Facebook sx={{ fontSize: 20 }} />
      </IconButton>
      <IconButton
        color="primary"
        href="https://www.instagram.com/coccinelle_covoiturages/?hl=en"
        LinkComponent={Link}
        size="small"
        target="_blank"
      >
        <Instagram sx={{ fontSize: 20 }} />
      </IconButton>
    </Stack>
  );
}

export default SocialIcons;
