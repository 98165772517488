import { Stack, Toolbar, Typography, useScrollTrigger } from '@mui/material';
import React, { useRef } from 'react';
import UnderlinedText from 'src/components/UnderlinedText';
import FakePhone from 'src/sections/onboardings/onboarding/FakePhone';

import FAQ from './FAQ';

function FakePhoneFAQ() {
  const fakeScreenRef = useRef();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    target: fakeScreenRef.current,
    threshold: 12,
  });

  return (
    <Stack
      height="100%"
      position="absolute"
      sx={{
        left: { sm: 0, xs: '50%' },
        transform: { sm: 'none', xs: 'translateX(-50%)' },
      }}
      width={260}
    >
      <Stack
        pl={{ sm: 5, xs: 0 }}
        sx={{
          '&:hover': {
            transform: { sm: 'translateY(-92px)' },
          },
          transform: { sm: 'translateY(-24px)', xs: 'translateY(-55%)' },
          transition: 'all 0.25s ease',
          width: '100%',
        }}
      >
        <FakePhone noScale>
          <Stack
            ref={fakeScreenRef}
            mb={6}
            sx={{
              height: '100%',
              overflowY: 'scroll',
            }}
          >
            <Typography
              sx={{
                backgroundColor: 'background.default',
                boxShadow: (th) => th.shadows[trigger ? 6 : 0],
                padding: 2,
                position: 'sticky',
                textAlign: { sm: 'left', xs: 'center' },
                top: 0,
                transition: 'all 0.25s ease',
                zIndex: 1,
              }}
            >
              <UnderlinedText>Notre FAQ</UnderlinedText>
            </Typography>
            <Stack spacing={2}>
              <FAQ />
            </Stack>
            <Toolbar />
          </Stack>
        </FakePhone>
      </Stack>
    </Stack>
  );
}

export default FakePhoneFAQ;
