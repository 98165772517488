import { Box } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

// project import
import getColors from '../../utils/getColors';

function Dot({ color, size, sx, variant }) {
  const theme = useTheme();
  const colors = getColors(theme, color || 'primary');
  const { main } = colors;

  return (
    <Box
      component="span"
      sx={{
        bgcolor: variant === 'outlined' ? '' : main,
        borderRadius: '50%',
        height: size || 8,
        width: size || 8,
        ...(variant === 'outlined' && {
          border: `1px solid ${main}`,
        }),
        ...sx,
      }}
    />
  );
}

Dot.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  variant: PropTypes.string,
};

export default Dot;
