import PropTypes from 'prop-types';
import React from 'react';
import useAuth from 'src/contexts/auth/useAuth';
import Login from 'src/pages/administration/Login';
import PassOTPChallenge from 'src/pages/PassOTPChallenge';

function AdminGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) return <Login />;

  if (user.isOtpVerificationRequired) {
    return <PassOTPChallenge />;
  }

  return children;
}
AdminGuard.propTypes = {
  children: PropTypes.node,
};

export default AdminGuard;
