export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.palette.background.paper,
          zIndex: theme.zIndex.modal - 1,
        },
      },
    },
  };
}
