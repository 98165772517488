export const PLATFORMS = {
  ANDROID: 'android',
  IOS: 'ios',
  UNKNOWN: 'unknown',
};

const detectOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return PLATFORMS.ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return PLATFORMS.IOS;
  }

  return 'unknown';
};

const PLATFORM = {
  OS: detectOS(),
};

export default PLATFORM;
