// ==============================|| SWITCH - SIZE STYLE ||============================== //

function getSizeStyle(size) {
  switch (size) {
    case 'small':
      return { base: 12, height: 16, thumb: 10, trackRadius: 8, width: 28 };
    case 'large':
      return { base: 32, height: 28, thumb: 22, trackRadius: 24, width: 60 };
    case 'medium':
    default:
      return { base: 22, height: 22, thumb: 16, trackRadius: 16, width: 44 };
  }
}

function switchStyle(theme, size) {
  const sizes = getSizeStyle(size);

  return {
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        transform: `translateX(${sizes.base}px)`,
      },
      padding: 3,
    },
    '& .MuiSwitch-thumb': {
      height: sizes.thumb,
      width: sizes.thumb,
    },
    '& .MuiSwitch-track': {
      borderRadius: sizes.trackRadius,
    },
    height: sizes.height,
    width: sizes.width,
  };
}

// ==============================|| OVERRIDES - TAB ||============================== //

export default function Switch(theme) {
  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& ~ .MuiFormControlLabel-label': {
            margin: 6,
          },
          color: theme.palette.text.primary,
          display: 'flex',
          margin: 8,
          padding: 0,
          ...switchStyle(theme, 'medium'),
        },
        sizeLarge: { ...switchStyle(theme, 'large') },
        sizeSmall: {
          ...switchStyle(theme, 'small'),
        },
        switchBase: {
          '&.Mui-checked': {
            '& + .MuiSwitch-track': {
              opacity: 1,
            },
            '&.Mui-disabled': {
              color: theme.palette.background.paper,
            },
            color: '#fff',
          },
          '&.Mui-disabled': {
            '+.MuiSwitch-track': {
              opacity: 0.3,
            },
            color: theme.palette.background.paper,
          },
        },
        thumb: {
          borderRadius: '50%',
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        track: {
          backgroundColor: theme.palette.secondary[400],
          boxSizing: 'border-box',
          opacity: 1,
        },
      },
    },
  };
}
