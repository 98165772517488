import React, { lazy } from 'react';

import Loadable from '../../components/Loadable';

const DeepLinkPage = Loadable(lazy(() => import('../../pages/app/DeepLink')));

const AppRoutes = {
  children: [
    {
      element: <DeepLinkPage />,
      path: '',
    },
    {
      element: <DeepLinkPage />,
      path: '*',
    },
  ],
  path: '',
};

const AppPageRoutes = [AppRoutes];

export default AppPageRoutes;
