import { gql } from '@apollo/client';

import {
  addressFragment,
  alertFragment,
  carFragment,
  conductorFragment,
  journeyFragment,
  tripFragment,
} from '../fragments';

const GET_TRIP_BY_ID = gql`
  query getTripById($id: ID!) {
    getTripById(id: $id) {
      ...Trip

      freeTripToken

      car {
        ...Car
      }

      startAddress {
        ...Address
      }

      endAddress {
        ...Address
      }

      startingGpsAddress {
        ...Address
      }

      endingGpsAddress {
        ...Address
      }

      conductor {
        ...Conductor
      }

      journeys {
        ...Journey

        passenger {
          id
          avatar
          firstname
          phone
        }
      }
    }
  }
  ${carFragment}
  ${conductorFragment}
  ${journeyFragment}
  ${tripFragment}
  ${addressFragment}
`;

const GET_MY_TRIPS_AS_CONDUCTOR = gql`
  query getMyTripsAsConductor {
    getMyTripsAsConductor {
      ...Trip

      startAddress {
        ...Address
      }

      endAddress {
        ...Address
      }
    }
  }
  ${tripFragment}
  ${addressFragment}
`;

const GET_MY_TRIPS_AS_PASSENGER = gql`
  query getMyTripsAsPassenger {
    getMyTripsAsPassenger {
      ...Trip

      startAddress {
        ...Address
      }

      endAddress {
        ...Address
      }
    }
  }
  ${tripFragment}
  ${addressFragment}
`;

const GET_MY_COMING_TRIPS = gql`
  query getMyComingTrips {
    getMyComingTrips {
      asConductor {
        ...Trip

        conductor {
          ...Conductor
        }

        startAddress {
          ...Address
        }

        endAddress {
          ...Address
        }

        journeys {
          ...Journey
          passenger {
            id
            avatar
            firstname
          }
        }
      }
      asPassenger {
        ...Trip

        conductor {
          ...Conductor
        }

        startAddress {
          ...Address
        }

        endAddress {
          ...Address
        }

        journeys {
          ...Journey
          passenger {
            id
            avatar
            firstname
          }
        }
      }
    }
  }
  ${tripFragment}
  ${conductorFragment}
  ${journeyFragment}
  ${addressFragment}
`;
const GET_MY_PAST_TRIPS = gql`
  query getMyPastTrips {
    getMyPastTrips {
      asConductor {
        ...Trip

        conductor {
          ...Conductor
        }

        startAddress {
          ...Address
        }

        endAddress {
          ...Address
        }

        journeys {
          ...Journey
          passenger {
            id
            avatar
            firstname
          }
        }
      }
      asPassenger {
        ...Trip

        conductor {
          ...Conductor
        }

        startAddress {
          ...Address
        }

        endAddress {
          ...Address
        }

        journeys {
          ...Journey
          passenger {
            id
            avatar
            firstname
          }
        }
      }
    }
  }
  ${tripFragment}
  ${conductorFragment}
  ${journeyFragment}
  ${addressFragment}
`;

const GET_NEAREST_TRIPS_FROM_ME = gql`
  query getNearestTripsFromMe($input: GetNearestTripsInput, $pagination: PaginationInput!) {
    getNearestTripsFromMe(input: $input, pagination: $pagination) {
      trips {
        ...Trip

        conductor {
          ...Conductor
        }

        startAddress {
          ...Address
        }

        endAddress {
          ...Address
        }
      }
      paginationOutput {
        total
        hasMore
      }
    }
  }
  ${addressFragment}
  ${conductorFragment}
  ${tripFragment}
`;

const GET_TRIPS = gql`
  query getTrips($input: GetTripsInput!, $pagination: PaginationInput!) {
    getTrips(input: $input, pagination: $pagination) {
      trips {
        ...Trip

        departureDurationInMinutes
        arrivalDurationInMinutes

        conductor {
          ...Conductor
        }

        startAddress {
          ...Address
        }

        endAddress {
          ...Address
        }
      }

      relatedAlerts {
        ...Alert
      }

      paginationOutput {
        hasMore
        total
      }
    }
  }
  ${addressFragment}
  ${alertFragment}
  ${conductorFragment}
  ${tripFragment}
`;

const GET_MY_CURRENT_TRIP = gql`
  query getMyCurrentTrip {
    getMyCurrentTrip {
      ...Trip

      conductor {
        ...Conductor
      }

      car {
        ...Car
      }

      startAddress {
        ...Address
      }

      endAddress {
        ...Address
      }

      journeys {
        ...Journey

        passenger {
          id
          avatar
          firstname
          phone
        }
      }
    }
  }
  ${addressFragment}
  ${carFragment}
  ${journeyFragment}
  ${conductorFragment}
  ${tripFragment}
`;

export {
  GET_TRIP_BY_ID,
  GET_MY_TRIPS_AS_CONDUCTOR,
  GET_MY_TRIPS_AS_PASSENGER,
  GET_MY_COMING_TRIPS,
  GET_NEAREST_TRIPS_FROM_ME,
  GET_TRIPS,
  GET_MY_CURRENT_TRIP,
  GET_MY_PAST_TRIPS,
};
