import React, { createContext, useContext, useMemo } from 'react';
import { CREATE_ALERT, DELETE_ALERT, UPDATE_ALERT } from 'src/graphql/alert/mutations';
import { GET_MY_ALERTS } from 'src/graphql/alert/queries';
import { useSafeLazyQuery, useSafeMutation } from 'src/services/apollo-client/wrappers';

const AlertContext = createContext();

const useAlert = () => useContext(AlertContext);

function AlertProvider({ children }) {
  const [createAlert, createAlertState] = useSafeMutation(CREATE_ALERT, {
    displaySnackbar: false,
    refetchQueries: ['getMyAlerts'],
  });

  const [updateAlert, updateAlertState] = useSafeMutation(UPDATE_ALERT, {
    displaySnackbar: false,
  });

  const [deleteAlert, deleteAlertState] = useSafeMutation(DELETE_ALERT, {
    displaySnackbar: false,
    refetchQueries: ['getMyAlerts'],
  });

  const [getMyAlerts, { data: myAlerts, ...getMyAlertsState }] = useSafeLazyQuery(GET_MY_ALERTS);

  const memoizedContextValue = useMemo(
    () => ({
      createAlert,
      createAlertState,
      deleteAlert,
      deleteAlertState,
      getMyAlerts,
      getMyAlertsState,
      myAlerts: myAlerts?.getMyAlerts,
      updateAlert,
      updateAlertState,
    }),
    [
      createAlert,
      createAlertState,
      deleteAlert,
      deleteAlertState,
      getMyAlerts,
      getMyAlertsState,
      myAlerts?.getMyAlerts,
      updateAlert,
      updateAlertState,
    ]
  );

  return <AlertContext.Provider value={memoizedContextValue}>{children}</AlertContext.Provider>;
}

export { AlertProvider, useAlert };
