import { useEffect } from 'react';

import { EVENT_TYPES } from './messages/messageTypes';

const useMessages = (messages) => {
  useEffect(() => {
    if (!window.ReactNativeWebView) return () => null;

    const handleMessage = async (messageEvent) => {
      try {
        const parsedMessage = JSON.parse(messageEvent.data);
        const { args, event, type } = parsedMessage;

        const { handler } = messages.find(({ type: messageType }) => type === messageType);

        if (!handler) throw new Error(`Cannot find relative action to type ${type}`);

        if (event === EVENT_TYPES.START) {
          try {
            const operationResult = await handler(args);
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({ event: EVENT_TYPES.DONE, result: operationResult })
            );
          } catch (errorThrown) {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({ error: errorThrown, event: EVENT_TYPES.ERROR })
            );
          }
        }
      } catch {
        // Nothing to do
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [messages]);
};

export default useMessages;
