import i18n from '../../locales/i18n';

const expectedErrors = {
  EXPIRED_LINK: {
    code: 'EXPIRED_LINK',
    message: i18n.t('commons.expiredLink'),
  },
  RESOURCE_NOT_FOUND: {
    code: 'RESOURCE_NOT_FOUND',
    message: "La ressource que tu cherches n'existe pas",
  },
  UNAUTHENTICATED: {
    code: 'UNAUTHENTICATED',
    message: i18n.t('commons.userDisconnected'),
  },
};

export default expectedErrors;
