import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';

const BASE_TITLE = 'Coccinelle | ';
const DEFAULT_TITLE = 'Le covoiturage qui rapporte';
const DEFAULT_DESCRIPTION = 'Coccinelle, le covoiturage qui rapporte.';
const DEFAULT_KEYWORDS = [
  'Coccinelle',
  'Covoiturage',
  "Gagner de l'argent",
  'Prime covoiturage',
  'CEE',
  'C2E',
];

const SEOContext = createContext();

const useSEOContext = () => useContext(SEOContext);

function SEOProvider({ children }) {
  const DEFAULT_BASE_TITLE = `${BASE_TITLE}${DEFAULT_TITLE}`;

  const [title, setTitle] = useState(DEFAULT_BASE_TITLE);
  const [description, setDescription] = useState(DEFAULT_DESCRIPTION);
  const [keywords, setKeywords] = useState(DEFAULT_KEYWORDS);

  const setCustomTitle = useCallback(
    (customTitle, withoutBaseTitle) =>
      setTitle(withoutBaseTitle ? customTitle : `${BASE_TITLE}${customTitle || DEFAULT_TITLE}`),
    []
  );

  const memoizedContextValue = useMemo(
    () => ({
      setDescription,
      setKeywords,
      setTitle: setCustomTitle,
    }),
    [setCustomTitle]
  );

  return (
    <SEOContext.Provider value={memoizedContextValue}>
      <Helmet>
        <title>{title || DEFAULT_BASE_TITLE}</title>
        <meta content={description || DEFAULT_DESCRIPTION} name="description" />
        <meta content={(keywords || DEFAULT_KEYWORDS).join(',')} name="keywords" />
      </Helmet>
      {children}
    </SEOContext.Provider>
  );
}

export { SEOProvider, useSEOContext };
