import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

const LoaderWrapper = styled('div')(({ theme }) => ({
  '& > * + *': {
    marginTop: theme.spacing(2),
  },
  left: 0,
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 2001,
}));

function Loader() {
  return (
    <LoaderWrapper>
      <LinearProgress color="primary" />
    </LoaderWrapper>
  );
}

function CommonLayout() {
  return (
    <Suspense fallback={<Loader />}>
      <Outlet />
    </Suspense>
  );
}

export default CommonLayout;
