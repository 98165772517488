import { ConstructionOutlined } from '@mui/icons-material';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import logoIcon from 'src/assets/logos/logo.png';

const DEFAULT_SIZE = 32;

function Logo({ isAdmin, size, withBrandName }) {
  const theme = useTheme();

  return (
    <Stack alignItems="center" direction="row" position="relative" spacing={1}>
      <Box
        alt="Coccinelle"
        component="img"
        src={logoIcon}
        sx={{
          height: size,
          p: size * 0.01,
          width: size,
        }}
      />
      {withBrandName && <Typography variant="h5">Coccinelle</Typography>}
      {isAdmin && (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            bgcolor: 'background.default',
            border: `1px dashed ${theme.palette.primary.main}`,
            borderRadius: '100%',
            bottom: theme.spacing(-0.5),
            p: 0.5,
            position: 'absolute',
            right: theme.spacing(-0.5),
          }}
        >
          <ConstructionOutlined sx={{ fontSize: 14 }} />
        </Stack>
      )}
    </Stack>
  );
}

Logo.defaultProps = {
  size: DEFAULT_SIZE,
};

export default Logo;
