export default function Select() {
  return {
    MuiTextField: {
      styleOverrides: {
        paper: {
          marginBottom: 8,
          marginTop: 8,
        },
      },
    },
  };
}
