import { alpha } from '@mui/material/styles';

export default function Dialog(theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(6px)',
            backgroundColor: alpha(theme.palette.background.default, 0.03),
          },
        },
      },
    },
  };
}
