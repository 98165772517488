import { gql } from '@apollo/client';

import { conversationFragment, messageFragment, tripFragment, userFragment } from '../fragments';

const GET_CONVERSATIONS_BY_TRIP_ID = gql`
  query getConversationsByTripId($tripId: ID!) {
    getConversationsByTripId(tripId: $tripId) {
      ...Conversation
      conductor {
        ...User
      }
      passenger {
        ...User
      }
      lastUnreadMessage {
        ...Message
      }
    }
  }
  ${conversationFragment}
  ${messageFragment}
  ${userFragment}
`;

const GET_MESSAGES_BY_CONVERSATION_ID = gql`
  query getMessagesByConversationId($conversationId: ID!) {
    getMessagesByConversationId(conversationId: $conversationId) {
      ...Message
    }
  }
  ${messageFragment}
`;

const GET_MY_TRIPS_WITH_UNREAD_CONVERSATIONS = gql`
  query getMyTripsWithUnreadConversations {
    getMyTripsWithUnreadConversations {
      ...Trip
      unreadConversations {
        ...Conversation
        passenger {
          ...User
        }
        conductor {
          ...User
        }
      }
    }
  }
  ${userFragment}
  ${tripFragment}
  ${conversationFragment}
`;

export {
  GET_CONVERSATIONS_BY_TRIP_ID,
  GET_MESSAGES_BY_CONVERSATION_ID,
  GET_MY_TRIPS_WITH_UNREAD_CONVERSATIONS,
};
